import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function Zoho() {
    console.log("INSIDE ZOHO FUNCTION")
    const history = useHistory();

    // let domain = (new URL(window.location)).searchParams;
    // let code = domain.get("code");
    // let access_token_url = `${localStorage.getItem("pathName")}?code=${code}&drawer=true`;
    // console.log("ZOHO access_token_url");
    // console.log(access_token_url);
    
    useEffect(() => {
        // console.log(window.document.referrer);

        let domain = (new URL(window.location)).searchParams;
        let code = domain.get("code");
        let error = domain.get("error");

        code && history.push(`${localStorage.getItem("pathName")}?code=${code}&drawer=true`)
        error === "access_denied" && history.push(`${localStorage.getItem("pathName")}`)
    }, [])

    return (
        <div>
            <a href=' "https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ&client_id=" + localStorage.getItem("Client_ID") + "&state=testing&response_type=code&redirect_uri=" + localStorage.getItem("urlOrigin") + "/zohoTesting&access_type=offline"'>
                Click
            </a>
        </div>
    )
}

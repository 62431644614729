import React, { useState, useEffect, useRef } from "react";
import classes from "../BusinessEntityDetails.module.css";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../components/ui/inputField";
import SelectField from "../../../components/ui/select";
import axios from "../../../axios-order";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BoxWrapper from "../../../components/ui/boxWrapper";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RightDrawer from "../../../components/ui/RightDrawer";

const ConnectionDetails = (props) => {
  const formRef = useRef("form");
  const [connectionDetailTableData, setConnectionDetailTableData] = useState(
    []
  );
  const [editData, setEditData] = useState({});
  const [ediDataForIars, setEdiDataForIars] = useState({});
  const [addData, setAddData] = useState({});
  const [openAddConnectionDrawer, setOpenAddConnectionDrawer] = useState(false);
  const [openEditConnectionDrawer, setOpenEditConnectionDrawer] =
    useState(false);
  const [addSourceSystem, setAddSourceSystem] = useState("");
  const [iarsFormToShowInAddConnection, setIarsFormToShowInAddConnection] =
    useState(false);
  const [iarsFormToShowInEditConnection, setIarsFormToShowInEditConnection] =
    useState(false);
  const [zohoFormToShowInAddConnection, setZohoFormToShowInAddConnection] =
    useState(false);
  const [zohoFormToShowInEditConnection, setZohoFormToShowInEditConnection] =
    useState(false);
  const [editDataForZoho, setEditDataForZoho] = useState({});
  const [trueOrFalseForZoho, setTrueOrFalseForZoho] = useState(false);
  const [trueOrFalse, setTrueOrFalse] = useState(false);

  const getConnectionDetails = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        setConnectionDetailTableData(res.data.record);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getConnectionDetails();
  }, []);

  // connection table
  const connectionDetailTableBody = () => {
    const editConnectionData = (
      Port,
      access_Token,
      company_code,
      Organisation_code,
      source_system,
      Hostname_IP,
      Username,
      Password,
      Company,
      License_Key,
      client_id,
      client_secret,
      organisation_id
    ) => {
      if (source_system === "IARS") {
        setIarsFormToShowInEditConnection(true);
        setZohoFormToShowInEditConnection(false);
        setEdiDataForIars({
          ...ediDataForIars,
          source_system: source_system,
          Username: Username,
          Password: Password,
          Company: Company,
          License_Key: License_Key,
          Hostname_IP: Hostname_IP,
        });
      } else if (source_system === "ZOHO") {
        setZohoFormToShowInEditConnection(true);
        setIarsFormToShowInEditConnection(false);
        setEditDataForZoho({
          ...editDataForZoho,
          source_system: source_system,
          client_id: client_id,
          client_secret: client_secret,
          organisation_id: organisation_id,
        });
      } else {
        setIarsFormToShowInEditConnection(false);
        setZohoFormToShowInEditConnection(false);
        setEditData({
          ...editData,
          Port: Port,
          access_Token: access_Token,
          company_code: company_code,
          Organisation_code: Organisation_code,
          source_system: source_system,
          Hostname_IP: Hostname_IP,
        });
      }
      setOpenEditConnectionDrawer(true);
    };

    if (connectionDetailTableData.length >= 1) {
      return connectionDetailTableData.map((tableData, index) => {
        const {
          Port,
          access_Token,
          company_code,
          Organisation_code,
          Hostname_IP,
          Username,
          Password,
          Company,
          License_Key,
          client_id,
          client_secret,
          organisation_id,
        } = tableData.connection_info;

        return (
          <tr key={index}>
            <td>{tableData.source_system}</td>
            <td>
              {tableData.source_system === "ZOHO" ? (
                <div title="Client_ID">{client_id}</div>
              ) : (
                <>{Hostname_IP}</>
              )}
            </td>
            <td>
              {tableData.source_system === "IARS" ? (
                <>{Username}</>
              ) : tableData.source_system === "ZOHO" ? (
                <div title="Client_Secret">{client_secret}</div>
              ) : (
                <>{Port}</>
              )}
            </td>
            <td>
              {tableData.source_system === "IARS" ? (
                <>{Company}</>
              ) : (
                <>{company_code}</>
              )}
            </td>
            <td>
              {tableData.source_system === "IARS" ? (
                <>{Password}</>
              ) : tableData.source_system === "ZOHO" ? (
                <div title="Organisation_ID">{organisation_id}</div>
              ) : (
                <>{Organisation_code}</>
              )}
            </td>
            <td>
              {tableData.source_system === "IARS" ? (
                <>{License_Key}</>
              ) : (
                <>{access_Token}</>
              )}
            </td>

            <td>
              <Button
                style={{ minWidth: "0px" }}
                size="small"
                onClick={() =>
                  editConnectionData(
                    Port,
                    access_Token,
                    company_code,
                    Organisation_code,
                    tableData.source_system,
                    Hostname_IP,
                    Username,
                    Password,
                    Company,
                    License_Key,
                    client_id,
                    client_secret,
                    organisation_id
                  )
                }
              >
                <EditOutlinedIcon className="editIcon" />
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7} style={{ color: "red", textAlign: "center" }}>
            No Data found!
          </td>
        </tr>
      );
    }
  };

  // add connection section
  const openAddDrawer = () => {
    setOpenAddConnectionDrawer(true);
  };

  const addConnectionChangeHandler = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    if (value === "IARS") {
      setIarsFormToShowInAddConnection(true);
    } else if (value === "ZOHO") {
      setIarsFormToShowInAddConnection(false);
      setZohoFormToShowInAddConnection(true);
    } else {
      setIarsFormToShowInAddConnection(false);
      setZohoFormToShowInAddConnection(false);
    }
    if (name === "source_system") {
      setAddSourceSystem(value);
    }
  };

  const addConnectionChangeHandlerForIars = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddData({
      ...addData,
      [name]: String(value),
    });
  };

  const addConnectionSubmitHandler = () => {
    axios
      .post("/connectioninfo_post_api", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        source_system: addSourceSystem,
        connection_info: addData,
      })
      .then((res) => {
        getConnectionDetails();
        setAddData({});
        setAddSourceSystem("");
        setOpenAddConnectionDrawer(false);
        toast.success("Added Successfully", {
          autoClose: 3000,
          progress: 0,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        setOpenAddConnectionDrawer(false);
        setAddData({});
      });
  };

  const closeAddDrawer = () => {
    setOpenAddConnectionDrawer(false);
    setAddData({});
    setAddSourceSystem("");
    setIarsFormToShowInAddConnection(false);
  };

  const editConnectionChangeHandleForIars = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setTrueOrFalse(true);
    setTrueOrFalseForZoho(false);
    setEdiDataForIars({
      ...ediDataForIars,
      [name]: String(value),
    });
  };

  const editConnectionChangeHandleForZoho = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setTrueOrFalseForZoho(true);
    setTrueOrFalse(false);
    setEditDataForZoho({
      ...editDataForZoho,
      [name]: String(value),
    });
  };

  const editConnectionChangeHandle = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setTrueOrFalseForZoho(false);
    setTrueOrFalse(false);
    setEditData({
      ...editData,
      [name]: String(value),
    });
  };

  const editConnectionSubmitHandler = () => {
    let json;
    if (trueOrFalse === true) {
      json = ediDataForIars;
    } else if (trueOrFalseForZoho === true) {
      json = editDataForZoho;
    } else {
      json = editData;
    }
    axios
      .put("/connectioninfo_put_api", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        source_system: json.source_system,
        // connection_info: editData,
        connection_info: json,
      })
      .then((res) => {
        getConnectionDetails();
        setEditData({});
        setTrueOrFalse(false);
        setTrueOrFalseForZoho(false);
        setOpenEditConnectionDrawer(false);
        toast.success("Updated Successfully", {
          autoClose: 3000,
          progress: 0,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          progress: 0,
        });
        setTrueOrFalse(false);
        setTrueOrFalseForZoho(false);
        setOpenEditConnectionDrawer(false);
        setEditData({});
      });
  };

  const closeEditDrawer = () => {
    setOpenEditConnectionDrawer(false);
  };

  return (
    <>
      <BoxWrapper
        boxTitle="Connection Details"
        enableAddButton={true}
        addButtonLabel={"ADD Connection Details"}
        addButtonHandlerFn={() => openAddDrawer()}
      >
        <div className="responsive-table">
          <table className="custome-table">
            <tbody>
              <tr>
                <th>Source System</th>
                <th>Hostname/IP Client_ID</th>
                <th>Port/User Name , Client_Secret</th>
                <th>Company Code/Company Name</th>
                <th>Organisation Code/Password Organisation_ID </th>
                <th>Access Token / License Key</th>
                <th>Action</th>
              </tr>
              {connectionDetailTableBody()}
            </tbody>
          </table>
        </div>
      </BoxWrapper>

      {/* add Connection Details drawer */}
      <RightDrawer
        drawerName="Add Connection Details"
        open={openAddConnectionDrawer}
        onClose={closeAddDrawer}
      >
        <ValidatorForm
          ref={formRef}
          onSubmit={addConnectionSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          <SelectField
            SelectLabel="Source System"
            choice_id="source_system"
            choice_name="source_system"
            name="source_system"
            requiredField="*"
            validator={["required"]}
            errorMsg={["This field is required"]}
            value={addSourceSystem ?? ""}
            choices={[
              { id: "Effitrac", source_system: "Effitrac" },
              { id: "Tally", source_system: "Tally" },
              { id: "IARS", source_system: "IARS" },
              { id: "ZOHO", source_system: "ZOHO" },
            ]}
            textOnchangeFunction={addConnectionChangeHandler}
          />

          {/*  <InputField
                inputType="text"
                textLabel="Hostname"
                name="Hostname_IP"
                value={addData.Hostname_IP ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
            /> */}
          {iarsFormToShowInAddConnection ? (
            <div>
              <InputField
                inputType="text"
                textLabel="Hostname"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                name="Hostname_IP"
                value={addData.Hostname_IP ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />
              <InputField
                inputType="text"
                textLabel="User Name"
                name="Username"
                value={addData.Username ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />
              <InputField
                inputType="text"
                textLabel="Password"
                name="Password"
                value={addData.Password ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />
              <InputField
                inputType="text"
                textLabel="Company"
                name="Company"
                value={addData.Company ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />
              <InputField
                inputType="text"
                textLabel="License Key"
                name="License_Key"
                value={addData.License_Key ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />
            </div>
          ) : zohoFormToShowInAddConnection ? (
            <div>
              <InputField
                inputType="text"
                textLabel="Client_ID"
                name="client_id"
                value={addData.client_id ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
              />
              <InputField
                inputType="text"
                textLabel="Client Secret"
                name="client_secret"
                value={addData.client_secret ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
              />

              <InputField
                inputType="text"
                textLabel="Organization ID"
                name="orgnisation_id"
                value={addData.orgnisation_id ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
              />
            </div>
          ) : (
            <div>
              <InputField
                inputType="text"
                textLabel="Hostname"
                name="Hostname_IP"
                value={addData.Hostname_IP ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />
              <InputField
                inputType="text"
                textLabel="port"
                name="Port"
                value={addData.Port ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />

              <InputField
                inputType="text"
                textLabel="Company Code"
                name="company_code"
                value={addData.company_code ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />

              <InputField
                inputType="text"
                textLabel="Organisation Code"
                name="Organisation_code"
                value={addData.Organisation_code ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />

              <InputField
                inputType="text"
                textLabel="Access Token"
                name="access_Token"
                value={addData.access_Token ?? ""}
                textOnchangeFunction={addConnectionChangeHandlerForIars}
              />
            </div>
          )}

          <div className="mt-20">
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              type="button"
              className="button-outlined-secondary"
              onClick={closeAddDrawer}
            >
              cancel
            </button>
          </div>
        </ValidatorForm>
      </RightDrawer>

      {/* edit Connection Details drawer */}
      <RightDrawer
        drawerName="Edit Connection Details"
        open={openEditConnectionDrawer}
        onClose={closeEditDrawer}
      >
        <ValidatorForm
          ref={formRef}
          onSubmit={editConnectionSubmitHandler}
          onError={(errors) => console.log(errors)}
        >
          {/*     <SelectField
                SelectLabel="Source System"
                choice_id="id"
                choice_name="source_system"
                name="source_system"
                // value={editData.source_system === "IARS" ? handleIars() : handleOtherThing() }
                value={editData.source_system ?? "" }
                inputProps={{
                  readOnly: true,
                }}
                choices={[
                  { id: "Effitrac", source_system: "Effitrac" },
                  { id: "Tally", source_system: "Tally" },
                  { id:"IARS" , source_system: "IARS"},
                ]}
                textOnchangeFunction={editConnectionChangeHandler}
              />

             <InputField
                inputType="text"
                textLabel="Hostname"
                name="Hostname_IP"
                value={editData.Hostname_IP ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForIars}
           /> */}
          {iarsFormToShowInEditConnection ? (
            <div>
              <SelectField
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                SelectLabel="Source System"
                choice_id="id"
                choice_name="source_system"
                name="source_system"
                // value={editData.source_system === "IARS" ? handleIars() : handleOtherThing() }
                value={ediDataForIars.source_system ?? ""}
                inputProps={{
                  readOnly: true,
                }}
                choices={[
                  { id: "Effitrac", source_system: "Effitrac" },
                  { id: "Tally", source_system: "Tally" },
                  { id: "IARS", source_system: "IARS" },
                ]}
                // textOnchangeFunction={editConnectionChangeHandler}
              />

              <InputField
                inputType="text"
                textLabel="Hostname"
                name="Hostname_IP"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={ediDataForIars.Hostname_IP ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForIars}
              />
              <InputField
                inputType="text"
                textLabel="User Name"
                name="Username"
                value={ediDataForIars.Username ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForIars}
              />
              <InputField
                inputType="text"
                textLabel="Password"
                name="Password"
                value={ediDataForIars.Password ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForIars}
              />
              <InputField
                inputType="text"
                textLabel="Company"
                name="Company"
                value={ediDataForIars.Company ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForIars}
              />
              <InputField
                inputType="text"
                textLabel="License Key"
                name="License_Key"
                value={ediDataForIars.License_Key ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForIars}
              />
            </div>
          ) : zohoFormToShowInEditConnection ? (
            <div>
              <SelectField
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                SelectLabel="Source System"
                choice_id="id"
                choice_name="source_system"
                name="source_system"
                value={editDataForZoho.source_system ?? ""}
                inputProps={{
                  readOnly: true,
                }}
                choices={[
                  { id: "Effitrac", source_system: "Effitrac" },
                  { id: "Tally", source_system: "Tally" },
                  { id: "IARS", source_system: "IARS" },
                  { id: "ZOHO", source_system: "ZOHO" },
                ]}
                // textOnchangeFunction={editConnectionChangeHandleForZoho}
              />

              <InputField
                inputType="text"
                textLabel="Client ID"
                name="client_id"
                value={editDataForZoho.client_id ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForZoho}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
              />
              <InputField
                inputType="text"
                textLabel="Client Secret"
                name="client_secret"
                value={editDataForZoho.client_secret ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForZoho}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
              />

              <InputField
                inputType="text"
                textLabel="Organisation ID"
                name="organisation_id"
                value={editDataForZoho.organisation_id ?? ""}
                textOnchangeFunction={editConnectionChangeHandleForZoho}
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
              />
            </div>
          ) : (
            <div>
              <SelectField
                SelectLabel="Source System"
                choice_id="id"
                choice_name="source_system"
                name="source_system"
                // value={editData.source_system === "IARS" ? handleIars() : handleOtherThing() }
                value={editData.source_system ?? ""}
                inputProps={{
                  readOnly: true,
                }}
                choices={[
                  { id: "Effitrac", source_system: "Effitrac" },
                  { id: "Tally", source_system: "Tally" },
                  { id: "IARS", source_system: "IARS" },
                ]}
                // textOnchangeFunction={editConnectionChangeHandler}
              />
              <InputField
                inputType="text"
                textLabel="Hostname"
                name="Hostname_IP"
                value={editData.Hostname_IP ?? ""}
                textOnchangeFunction={editConnectionChangeHandle}
              />
              <InputField
                inputType="text"
                textLabel="port"
                name="Port"
                value={editData.Port ?? ""}
                textOnchangeFunction={editConnectionChangeHandle}
              />

              <InputField
                inputType="text"
                textLabel="Company Code"
                name="company_code"
                value={editData.company_code ?? ""}
                textOnchangeFunction={editConnectionChangeHandle}
              />

              <InputField
                inputType="text"
                textLabel="Organisation Code"
                name="Organisation_code"
                value={editData.Organisation_code ?? ""}
                textOnchangeFunction={editConnectionChangeHandle}
              />

              <InputField
                inputType="text"
                textLabel="Access Token"
                name="access_Token"
                value={editData.access_Token ?? ""}
                textOnchangeFunction={editConnectionChangeHandle}
              />
            </div>
          )}

          <div className="mt-20">
            <button className="button-primary" type="submit">
              Submit
            </button>
            <button
              type="button"
              className="button-outlined-secondary"
              onClick={closeEditDrawer}
            >
              cancel
            </button>
          </div>
        </ValidatorForm>
      </RightDrawer>
    </>
  );
};

export default ConnectionDetails;

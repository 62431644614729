import React, { Component } from "react";
import classes from "./ARCCustomer.module.css";
import axios from "../../../../axios-order";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "../../../../components/ui/Cards";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import DataTables from "material-ui-datatables";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SellerCustomer from "../../../../asserts/templates/seller_customer.xlsx";
import TextField from "@material-ui/core/TextField";
// import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/ui/inputField";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Drawer from "@material-ui/core/Drawer";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import SelectField from "../../../../components/ui/select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomTable from "../../../../components/ui/Table/Table";
import { TablePagination } from "@material-ui/core";

import BoxWrapper from "../../../../components/ui/boxWrapper";
import RefreshIcon from "@mui/icons-material/Refresh";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../components/ui/RightDrawer";

import ZohoModel from "../../../../components/ZohoModel/ZohoModel";
import BasicModal from "../../../../components/ui/basicModal";
import { flexbox } from "@mui/system";

let flagForZoho = 1;
let variableForTimerInSalesCustomer = null;

const indianFormat = (date) => {
  let dateArr = date.split("-");
  return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
};

class BussinessCustomerMang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      effitracCol: [
        {
          key: "customerCode",
          render: (index, row) => {
            return row["erp_cust_code"];
          },
        },
        {
          key: "legalName",
          render: (index, row) => {
            return row["cust_legal_name"];
          },
        },
        {
          key: "gstin",
          render: (index, row) => {
            return row["cust_gstin"];
          },
        },
        {
          key: "pan",
          render: (index, row) => {
            return row["cust_pan"];
          },
        },

        {
          key: "tan",
          render: (index, row) => {
            return row["cust_tan"];
          },
        },
        {
          key: "tdsPercentage",
          render: (index, row) => {
            return row["tds_percentage"];
          },
        },
      ],
      effitracData: null,
      oldEffitracData: null,
      effitracHeader: [
        { label: "Customer Code" },
        { label: "Legal Name" },
        { label: "GSTIN" },
        { label: "PAN" },
        { label: "TAN" },
        { label: "TDS %" },
      ],
      actionHeaderForZoho: [
        { id: "cust_contact_name", name: "Contact Name" },
        { id: "cust_contact_email", name: "Contact Email" },
        { id: "cust_contact_no", name: "Contact No" },
        { id: "cust_address", name: "Address" },
        { id: "cust_state", name: "State" },
        { id: "cust_country", name: "Country" },
        { id: "cust_acctmgr_name", name: "Account Manager Name" },
        { id: "cust_acctmgr_email", name: "Account Manager Email" },
        { id: "cust_credit_limit", name: "Credit Limit" },
        { id: "cust_credit_days", name: "Credit Days" },
        { id: "cust_registration_type", name: "Registration Type" },
        { id: "TDS %", name: "tds_percentage" },
      ],
      read_only: "",
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        // { key: 'seller_code', label: 'Seller Code', },
        {
          label: "GSTIN",
          render: (name, all) => {
            return all["record"]["GSTIN"];
          },
        },
        {
          label: "Invoice Number",
          render: (name, all) => {
            return all["record"]["InvoiceNo"];
          },
        },
        { key: "row", label: "Row #" },
        {
          label: "Errors",
          render: (name, all) => {
            return Object.keys(all["errors_in"]).map((i, v) => {
              return (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: "red" }}>
                    {all["errors_in"][i].join(",")}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,

      allArcCustomerData: [],
      OriginalDataAllArcCustomerData: [],
      showDrawer: false,
      showData: {},
      showUploadData: false,
      loading: true,

      add_DrawerOpen: false,
      addCustomerData: {},

      edit_DrawerOpen: false,
      editCustomerData: {},

      //..............Edited by Harsh............
      openZohoDrawer: false,
      messageForConnection: "Establish Connection",
      buttonColor: "red",
      grantToken: "",
      url: "",
      zohoDate: {},

      openImportEffitracDrawer: false,
      importEffitracFetchData: {},
      effitracFetchedRecords: {},
      fetrac: "",
      actions: [
        {
          label: "Show",
          onclickEvent: (index, row) => this.openModal(index),
        },
      ],
      effitracInvShow: false,
      targetId: null,

      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: "",

      // upload history table section
      uploadHistoryTableData: [],
      showUploadHistroyTable: false,

      flagForZohoInSalesCustomer: false,
      timerForZohoInSalesCustomer: 60,
      // variableForTimerInSalesCustomer: null,

      isOpenUpdateTanModal: false,
      updateTanGetApiData: {},
      isLoaderforupdateTanButton: false,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.getSellercustomerDataApi(this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.getSellercustomerDataApi(this.state.searchInput);
    });
  };
  // table serach
  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.getSellercustomerDataApi(this.state.searchInput);
      }
    );
  };

  openModal = (index) => {
    this.setState(
      {
        targetId: index,
      },
      () => {
        this.setState({
          effitracInvShow: true,
        });
      }
    );
  };

  closeEffitracInvShowHandler = () => {
    this.setState({
      effitracInvShow: false,
      targetId: null,
    });
  };

  getSellercustomerDataApi = (serachIn) => {
    axios
      .post(`/arc/sellerCustomer`, {
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page,
        search_txt: serachIn,
        seller_code: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          allArcCustomerData: res.data.records,
          OriginalDataAllArcCustomerData: res.data.records,
          rowCount: res.data.row_count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSellercustomerLastUploadDate = () => {
    const customer = "Seller Customer";
    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/${customer}`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = indianFormat(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDate: Date,
          lastUploadTime: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApiForClientID = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        res?.data?.record
          .filter((v) => v.source_system === "ZOHO")
          .map((value) =>
            localStorage.setItem("Client_ID", value.connection_info.client_id)
          );
        res?.data?.record.filter((v) => {
          if (v.source_system === "Effitrac" || v.source_system === "Tally") {
            localStorage.setItem("Trigger_Effitrac/Tally_Button", "true");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${1}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    console.log("INSIDE componentDidMount of BUSINESS CUSTOMER MANAGEMENT")
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && this.setState({ grantToken: code });

    let drawer = domain.get("drawer");
    drawer &&
      this.setState({
        openZohoDrawer: true,
        messageForConnection: "Connection Established",
        buttonColor: "green",
      });

    let url = window.location;
    this.setState({ url: url.origin });
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);
    this.getApiForClientID();
    this.getSellercustomerDataApi();
    this.getSellercustomerLastUploadDate();
    this.getHistoryTableUploadData();
    // this.getZohoLogs()
  }

  // getZohoLogs = () => {

  // }

  componentDidUpdate() {
    if (
      this.state.buttonColor === "green" &&
      !this.state.flagForZohoInSalesCustomer
    ) {
      this.setState({ flagForZohoInSalesCustomer: true });
      variableForTimerInSalesCustomer = setInterval(() => {
        this.setState((prevstate) => ({
          timerForZohoInSalesCustomer:
            prevstate.timerForZohoInSalesCustomer - 1,
        }));
      }, 1000);
    }

    if (this.state.timerForZohoInSalesCustomer === 0) {
      clearInterval(variableForTimerInSalesCustomer);
      this.setState({
        flagForZohoInSalesCustomer: false,
        timerForZohoInSalesCustomer: 60,
        buttonColor: "red",
      });
    }
  }

  //Search field---
  handleSearchChangeForZohoEffitrack = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;
    let searchAdminData = this.state.oldEffitracData.filter((value) => {
      const query = searchInput.trim().toLowerCase();
      return (
        String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_legal_name).toLowerCase().indexOf(query) >= 0 ||
        String(value.erp_cust_code).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_pan).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_tan).toLowerCase().indexOf(query) >= 0 ||
        String(value.tds_percentage).toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ effitracData: searchAdminData });
  };
  // End---

  //file upload handler---
  fileUploader = (event) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    let dis = this;
    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`arc/excelupload/sellercustomer`, {
          file: file_value,
          business_entity_id: parseInt(
            localStorage.getItem("business_entity_id")
          ),
        })
        .then((data) => {
          // fn recall for upload history table
          dis.getHistoryTableUploadData();
          dis.setState({ loading: false });
          axios
            .post(
              `/arc/sellerCustomer`,{
              number_of_rows_per_page: this.state.rowsPerPage,
              page_number: this.state.page,
              search_txt: "",
              seller_code: localStorage.getItem("business_entity_id"),
              }
            )
            .then((res) => {
              dis.setState({
                allArcCustomerData: res.data.records,
                OriginalDataAllArcCustomerData: res.data.records,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          dis.setState({
            error: error?.response?.data?.message,
            total_records: 0,
          });
        });
    };
  };

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.getSellercustomerDataApi();
  };

  // file upload button---
  CollectionListActions = () => (
    <div>
      <a
        href={SellerCustomer}
        download={"seller_customer.xlsx"}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: "20px" }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: "20px" }} />
        UPLOAD TEMPLATE{" "}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>

      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: "right" }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: "20px" }} />
        Refresh
      </Button>
    </div>
  );
  // file upload button end---

  // -------------------------------------arc customer table ---------------------------
  // arc customer table header---
  arc_Customer_TableHeader() {
    const tableHeader = [
      "ERP Customer Code",
      "Legal Name",
      "gstin",
      "pan",
      "tan",
      "TDS %",
      // 'action',
      "Show",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  showToggleDrawer = (
    CustRegistrationType,
    CustAcctmgrName,
    CustAcctmgrNo,
    CustAcctmgrEmail,
    CustCreditLimit,
    Status,
    CustAddress,
    CustContactName,
    CustContactEmail,
    CustContactNo,
    CustState,
    seller_code,
    CustCreditDays,
    CustCountry,
    created_date,
    modified_date,
    tds_percentage
  ) => {
    this.setState({
      showData: {
        CustRegistrationType: CustRegistrationType,
        CustAcctmgrName: CustAcctmgrName,
        CustAcctmgrNo: CustAcctmgrNo,
        CustAcctmgrEmail: CustAcctmgrEmail,
        CustCreditLimit: CustCreditLimit,
        Status: Status,
        CustAddress: CustAddress,
        CustContactName: CustContactName,
        CustContactEmail: CustContactEmail,
        CustContactNo: CustContactNo,
        CustState: CustState,
        seller_code: seller_code,
        CustCreditDays: CustCreditDays,
        CustCountry: CustCountry,
        created_date: created_date,
        modified_date: modified_date,
        tds_percentage: tds_percentage,
      },
      showDrawer: true,
    });
  };
  // arc Customer table body
  arc_Customer__data() {
    // edit ----
    const editARCCustomer = (
      cust_code,
      cust_legal_name,
      cust_gstin,
      cust_pan,
      cust_tan,
      CustRegistrationType,
      CustAcctmgrName,
      CustAcctmgrNo,
      CustAcctmgrEmail,
      CustCreditLimit,
      CustAddress,
      CustContactName,
      CustContactEmail,
      CustContactNo,
      CustState,
      CustCreditDays,
      CustCountry,
      tds_percentage
    ) => {
      this.setState({
        editCustomerData: {
          customerCode: cust_code,
          legalName: cust_legal_name,
          gstin: cust_gstin,
          pan: cust_pan,
          tan: cust_tan,
          acctmgrName: CustAcctmgrName,
          acctmgrNo: CustAcctmgrNo,
          acctmgrEmail: CustAcctmgrEmail,
          creditLimit: CustCreditLimit,
          address: CustAddress,
          contactName: CustContactName,
          email: CustContactEmail,
          contactNumber: CustContactNo,
          state: CustState,
          creditDays: CustCreditDays,
          country: CustCountry,
          gstrRegistrationType: CustRegistrationType,
          tdsPercentage: tds_percentage,
        },
        edit_DrawerOpen: true,
      });
    };

    if (this.state.allArcCustomerData.length >= 1) {
      return this.state.allArcCustomerData.map((tableData, index) => {
        const {
          created_date,
          cust_acctmgr_email,
          cust_acctmgr_name,
          cust_acctmgr_no,
          cust_address,
          cust_code,
          erp_cust_code,
          cust_contact_email,
          cust_contact_name,
          cust_contact_no,
          cust_country,
          cust_credit_days,
          cust_credit_limit,
          cust_gstin,
          cust_legal_name,
          cust_pan,
          cust_registration_type,
          cust_state,
          cust_tan,
          modified_date,
          seller_code,
          status,
          tds_percentage,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{erp_cust_code}</td>
            <td> {cust_legal_name}</td>
            <td>{cust_gstin}</td>
            <td>{cust_pan}</td>
            <td>{cust_tan}</td>
            <td>{tds_percentage}</td>
            {/* <td>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  editARCCustomer(
                    cust_code,
                    cust_legal_name,
                    cust_gstin,
                    cust_pan,
                    cust_tan,
                    cust_registration_type,
                    cust_acctmgr_name,
                    cust_acctmgr_no,
                    cust_acctmgr_email,
                    cust_credit_limit,
                    cust_address,
                    cust_contact_name,
                    cust_contact_email,
                    cust_contact_no,
                    cust_state,
                    cust_credit_days,
                    cust_country,
                    tds_percentage
                  )
                }
              >
                edit
              </Button>
            </td> */}
            <td>
              <RemoveRedEyeOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() =>
                  this.showToggleDrawer(
                    cust_registration_type,
                    cust_acctmgr_name,
                    cust_acctmgr_no,
                    cust_acctmgr_email,
                    cust_credit_limit,
                    status,
                    cust_address,
                    cust_contact_name,
                    cust_contact_email,
                    cust_contact_no,
                    cust_state,
                    seller_code,
                    cust_credit_days,
                    cust_country,
                    created_date,
                    modified_date,
                    tds_percentage
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={8}
          >
            Loading...Please Wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  // -------------------------------------arc customer table end---------------------------

  // show drawer close handler---
  showToggleDrawerClose = (event) => {
    this.setState({ showDrawer: false, showUploadHistroyTable: false });
  };

  // file upload response data handler---
  closeUploadData = () => {
    this.setState({ showUploadData: false });
    this.getSellercustomerDataApi();
  };
  // ------------------------add customer-----------------------
  // add customer[add button] drawer open handeler---
  // addCustomer_drawerOpen = () => {
  //   this.setState({ add_DrawerOpen: true });
  // };

  // add customer inputfield handler---
  addCustomer_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    // if (name === 'tdsPercentage') {
    //   if (value) {
    //     if (value > 100) {
    //       // alert(`TDS Percentage ${value} is invalid`)
    //       this.setState({ buttonDisabled: true });
    //     } else {
    //       this.setState({ buttonDisabled: false });
    //     }
    //   } else {
    //     this.setState((prevState) => ({
    //       addCustomerData: {
    //         ...prevState.addCustomerData,
    //         [name]: null,
    //       },
    //     }));
    //     return true;
    //   }
    // }

    this.setState((prevState) => ({
      addCustomerData: {
        ...prevState.addCustomerData,
        [name]: String(value),
      },
    }));
  };

  // add customer input field handler[number format]
  addCustomer_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      addCustomerData: {
        ...prevState.addCustomerData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add customer add submit handler
  addCustomer_submitHamdler = () => {
    this.setState({ buttonDisabled: true });
    toast.configure();
    axios
      .post(`/arc/ui/sellercustomer`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.addCustomerData],
      })
      .then((res) => {
        if (res.data.status === false) {
          this.setState({ buttonDisabled: false });
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.getSellercustomerDataApi();
          this.setState({
            add_DrawerOpen: false,
            addCustomerData: {},
            buttonDisabled: false,
          });
          toast.success("Customer added successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        this.setState({ buttonDisabled: false });
        toast.error("Server Error, Unable to add Customer", {
          autoClose: 2000,
        });
      });
  };

  // add customer drawer close handler---
  add_DrawerClose = () => {
    this.setState({
      add_DrawerOpen: false,
      addCustomerData: {},
      buttonDisabled: false,
    });
  };
  // ------------------------add customer end-----------------------

  // ------------------------edit customer-----------------------
  // edit customer[edit button] drawer open handeler---
  editCustomer_drawerOpen = () => {
    this.setState({ edit_DrawerOpen: true });
  };

  // edit customer inputfield handler---
  editCustomer_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    // if (name === 'tdsPercentage') {
    //   if (value) {
    //     if (value > 100) {
    //       // alert(`TDS Percentage ${value} is invalid`)
    //       this.setState({ buttonDisabled: true });
    //     } else {
    //       this.setState({ buttonDisabled: false });
    //     }
    //   } else {
    //     this.setState((prevState) => ({
    //       editCustomerData: {
    //         ...prevState.editCustomerData,
    //         [name]: null,
    //       },
    //     }));
    //     return true;
    //   }
    // }

    this.setState((prevState) => ({
      editCustomerData: {
        ...prevState.editCustomerData,
        [name]: String(value),
      },
    }));
  };

  // edit customer input field handler[number format]
  editCustomer_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      editCustomerData: {
        ...prevState.editCustomerData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit customer edit submit handler
  editCustomer_submitHamdler = () => {
    this.setState({ buttonDisabled: true });
    toast.configure();
    axios
      .post(`/arc/ui/sellercustomer`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.editCustomerData],
      })
      .then((res) => {
        if (res.data.status === false) {
          this.setState({ buttonDisabled: false });
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.getSellercustomerDataApi();
          this.setState({ edit_DrawerOpen: false, buttonDisabled: false });
          toast.success("Customer updated successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        toast.error("Server Error, Unable to edit Customer", {
          autoClose: 2000,
        });
        this.setState({ buttonDisabled: false });
        this.setState({ edit_DrawerOpen: false });
      });
  };

  // edit customer drawer close handler---
  edit_DrawerClose = () => {
    this.setState({ edit_DrawerOpen: false, buttonDisabled: false });
  };
  // ------------------------add customer end-----------------------

  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = (dataFromFunction) => {
    // export to excel for both
    let data = dataFromFunction;
    // if (this.state.isEffitrac) {
    //   data = this.state.effitracData;
    // } else {
    //   data = this.state.allArcCustomerData;
    // }

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Customers.xlsx`;

    axios
      .post(`/seller_customer_extract/download`, {
        data: data,
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //...............Edited By Harsh Handle Drawer.........
  handleZohoDrawer = () => {
    flagForZoho = 1;
    this.setState({ openZohoDrawer: true });
  };

  closeZohoDrawer = () => {
    clearInterval(variableForTimerInSalesCustomer);
    this.setState({
      zohoDate: {},
      openZohoDrawer: false,
      effitracImportFormloader: false,
      buttonColor: "red",
      messageForConnection: "Establish Connection",
      grantToken: "",
    });
  };

  // import effitrac button onclick[from handler]
  import_Effitrac_tally = () => {
    this.setState({ openImportEffitracDrawer: true });
  };

  importEffitracCreatedFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        createdFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importEffitracCreatedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        createdToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importEffitracFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importEffitracModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  fetchEffitacFormSubmitHandler = () => {
    toast.configure();
    this.setState({ effitracImportFormloader: true });
    this.setState((prevState) => ({
      importEffitracFetchData: {
        ...prevState.importEffitracFetchData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      },
    }));
    const data = this.state.importEffitracFetchData;
    axios
      .post("/arc/effitrac/customerdataexport", data)
      .then((res) => {
        this.setState({
          isEffitrac: true,
          effitracData: res.data.records.records,
          oldEffitracData: res.data.records.records,
          importEffitracFetchData: {},
          openImportEffitracDrawer: false,
          effitracImportFormloader: false,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          autoClose: 2000,
        });
        this.setState({ effitracImportFormloader: false });
      });
  };

  closeEffitracDrawer = () => {
    this.setState({
      openImportEffitracDrawer: false,
      importEffitracFetchData: {},
      effitracImportFormloader: false,
    });
  };

  closeEffitracInvShowHandler = () => {
    this.setState({
      effitracInvShow: false,
      targetId: null,
    });
  };

  // import to cws button onclick--
  importToCWS = (dataForFun) => {
    toast.configure();
    axios
      .post("/arc/effitrac/sellercustomer", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        // records: this.state.effitracData,
        records: dataForFun,
      })
      .then((res) => {
        this.refreshUploadHistoryTable();
        this.setState({
          showUploadHistroyTable: true,
        });
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.messge, {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  // back button after import effitrac data
  backHandler = () => {
    this.setState({ isEffitrac: false, showUploadHistroyTable: false });
  };

  establishConnection = (e) => {
    console.log("[business customer management] establishing connection with zoho");
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${
      this.state.url
    }/zohoTesting&access_type=offline&prompt=Consent`;

    // code && alert(code)
  };

  zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    if (value != "Invalid Date" && value != null) {
      
      var d = new Date(value);
      var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
      let dateValue = newd.toISOString().replace("Z", "");

      if (name === "created_from_date") {
        this.setState({
          zohoDate: {
            ...this.state.zohoDate,
            [name]: dateValue,
          },
        });
      } else {
        this.setState({
          zohoDate: {
            ...this.state.zohoDate,
            [name]: dateValue,
          },
        });
      }
    }
  };

  fetchingdataForZoho = () => {
    console.log("filter submit");
    toast.configure();
    clearInterval(variableForTimerInSalesCustomer);
    flagForZoho += 1;

    if (flagForZoho === 2) {
      console.log("ABOUT TO call zoho_import");
      // console.log(this.state.grantToken);
      // console.log(this.state.zohoDate);
      
      this.setState({ effitracImportFormloader: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          type_of_data: "seller_customer",
          code: this.state.grantToken,
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            this.setState({
              effitracImportFormloader: false,
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
              zohoDate: {},
              // effitracData: res.data.records,
              // oldEffitracData: res.data.records,
            });
          } else {
            this.setState({
              effitracImportFormloader: false,
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
              zohoDate: {},
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
            // this.setState({ loaderForTable: false });
          }
        })
        .catch((err) => {
          this.setState({
            buttonColor: "red",
            messageForConnection: "Establish Connection",
            grantToken: "",
            zohoDate: {},
            effitracImportFormloader: false,
          });
          // this.closeZohoDrawer();
          // this.setState({ loaderForTable: false });
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({
          showUploadData: true,
          uploadStatusFail: response.data.records,
        });

        let res = response.data.records.records;
        if (response.data.records.type === "json") {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === "Fail") {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),
              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // upload TAN
  uploadTanGetApiHandler = () => {
    console.log("\nINSIDE uploadTanGetApiHandler. Calling API to get previous log and count of TANs...");
    this.setState({ isLoaderforupdateTanButton: true });
    axios
      .get(`/karza_log/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        console.log("handling response of karza_log")
        // console.log(res.data)
        if (res.data) {
          this.setState({
            updateTanGetApiData: res.data,
            isLoaderforupdateTanButton: false,
          });

          this.setState({ isOpenUpdateTanModal: true });
        }
      });
  };

  uploadTanPostApiHandler = () => {
    console.log("\nINSIDE uploadTanPostApiHandler. Calling API to get Karza TANs...")
    toast.configure();
    axios
      .get(`/karza_tan/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        if (res.data && res.data.status === true) {
          toast.success(res.data.Message, {
            autoClose: 3000,
          });
          this.setState({ isOpenUpdateTanModal: false });
        } else {
          toast.error(res.data.Message, {
            autoClose: 3000,
          });
        }
      });
  };

  uploadTanCancelHandler = () => {
    this.setState({ isOpenUpdateTanModal: false });
  };

  errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: this.state.uploadStatusFail,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `CustomersUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <ZohoModel
          openZohoDrawer={this.state.openZohoDrawer}
          buttonColor={this.state.buttonColor}
          establishConnection={this.establishConnection}
          messageForConnection={this.state.messageForConnection}
          timerForZohoInSalesCustomer={this.state.timerForZohoInSalesCustomer}
          closeZohoDrawer={this.closeZohoDrawer}
          zohoDateChangeHandler={this.zohoDateChangeHandler}
          effitracImportFormloader={this.state.effitracImportFormloader}
          fetchingdataForZoho={this.fetchingdataForZoho}
          zohoDate={this.state.zohoDate}
          logApitName="seller_customer"
          nameOfTheModel="Business Customer Management"
          importToCWS={this.importToCWS}
          exportToExcel={this.export_to_excel}
          zohoTableHeader={this.state.effitracHeader}
          zohoTableColumn={this.state.effitracCol}
          drawerKeys={this.state.actionHeaderForZoho}
        />

        <BasicModal
          title={"Update Tan"}
          enableFooter={true}
          open={this.state.isOpenUpdateTanModal}
          modalBody={
            this.state.updateTanGetApiData.status === false ? (
              <p style={{ color: "red" }}>
                {this.state.updateTanGetApiData.message}
              </p>
            ) : (
              <p>{this.state.updateTanGetApiData.message}</p>
            )
          }
          modalSize={"md"}
          onClose={this.uploadTanCancelHandler}
          modalFooter={
            <>
              {
                this.state.updateTanGetApiData.status && 
                <button
                  className="button-primary"
                  type="button"
                  style={{ marginRight: "10px" }}
                  onClick={this.uploadTanPostApiHandler}
                  disabled={
                    this.state.updateTanGetApiData.status === false ? true : false
                  }
                >
                  ok
                </button>
              }
              <button
                className="button-outlined-secondary"
                type="button"
                onClick={this.uploadTanCancelHandler}
              >
                Close
              </button>
            </>
          }
        />

        {/* upload temp Button */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {" "}
            <ToggleSwitch
              onChange={() =>
                this.setState({
                  showUploadHistroyTable: !this.state.showUploadHistroyTable,
                })
              }
              value={this.state.showUploadHistroyTable}
              label="Upload Customer"
            />
          </div>

          <div>
            <button
              type="button"
              onClick={this.uploadTanGetApiHandler}
              className="button-primary"
              disabled={this.state.isLoaderforupdateTanButton ? true : false}
            >
              {this.state.isLoaderforupdateTanButton
                ? "updating..."
                : " Update TAN"}
            </button>
          </div>
        </div>

        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper
              boxTitle="Upload History"
              zohoEffitracEnable={
                localStorage.getItem("Client_ID") &&
                !this.state.isEffitrac &&
                true
              }
              zohoEffitracFn={this.handleZohoDrawer}
              enableImportToEffTallybutton={
                localStorage.getItem("Trigger_Effitrac/Tally_Button") &&
                localStorage.getItem("Trigger_Effitrac/Tally_Button") ===
                  "true" &&
                !this.state.isEffitrac &&
                true
              }
              importToEffiTallyFn={this.import_Effitrac_tally}
            >
              <div style={{ marginBottom: "10px" }}>
                {" "}
                <this.CollectionListActions />
              </div>

              <div className="responsive-table">
                <table className="custome-table">
                  <thead>
                    <tr>
                      <th>Last Upload Date & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === "In Progress" ? (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button-disabled"
                                color="disabled"
                              />
                            ) : item.status === "Uploaded" ? (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button-disabled"
                                color="disabled"
                              />
                            ) : (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button showIcon"
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: "red", textAlign: "center" }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: "none",
                          marginBottom: "0px",
                          padding: "0px",
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li style={{ fontWeight: "700" }}>Upload Details</li>
                        <li>
                          {this.state.error_records > 0 && (
                            <button
                              className="button-outlined-primary"
                              onClick={this.errorLogExportHandlerForUpload}
                            >
                              Export
                            </button>
                          )}

                          <button
                            className="close-icon-button"
                            onClick={this.closeUploadData}
                          >
                            <ClearIcon style={{ color: "#d04646" }} />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>

                        {/* datatable component */}
                        <div
                          style={{
                            height: "300",
                            margin: "20px 30px 0px 30px",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: "red" }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        {/* customer table */}
        {/* new change by bikash */}
        <BoxWrapper
          boxTitle="Customers Details"
          // export to excel
          exportToExcelEnable={true}
          exportToExcelFn={() =>
            this.export_to_excel(this.state.allArcCustomerData)
          }
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={
            this.state.isEffitrac
              ? this.handleSearchChangeForZohoEffitrack
              : this.handleSearchInputChange
          }
          // back button
          backButtonForZohoEnable={this.state.isEffitrac}
          backButton={this.backHandler}
          // import to cws button
          importToCwsEnableForZoho={this.state.isEffitrac}
          importToCwsForZoho={this.importToCWS}
        >
          {this.state.isEffitrac ? (
            <CustomTable
              headers={this.state.effitracHeader}
              columns={this.state.effitracCol}
              data={this.state.effitracData}
              actions={this.state.actions}
            />
          ) : (
            <>
              <div className="responsive-table">
                <table className="custome-table">
                  <tbody>
                    <tr>{this.arc_Customer_TableHeader()}</tr>
                    {this.arc_Customer__data()}
                  </tbody>
                </table>
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCount} //total row count
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </BoxWrapper>
        {/* end */}

        {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.Main_frame}>
              <div className={classes.form_title_bar}>
                <div class={classes.form_title_name}>
                  <ul
                    style={{
                      listStyle: "none",
                      marginBottom: "0px",
                      paddingBottom: "10px",
                      marginTop: "0px",
                    }}
                  >
                    <li style={{ display: "inline-block", fontSize: "15px" }}>
                      Customers Details
                    </li>

                    <li style={{ display: "inline-block", float: "right" }}>
                      

                      {this.state.isEffitrac && (
                        <Button size="small" onClick={this.importToCWS}>
                          Import to CWS
                        </Button>
                      )}

                      

                      <Button size="small" onClick={() => this.export_to_excel(this.state.allArcCustomerData)}>
                        Export to Excel
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={classes.form_body_part}>
                <div className={classes.searchField}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      {!this.state.isEffitrac && (
                        <Button
                          style={{ marginTop: '35px' }}
                          size="small"
                          variant="outlined"
                          onClick={this.addCustomer_drawerOpen}
                        >
                          Add Customer
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4}>
                      <ValidatorForm
                        ref="form"
                        onSubmit={this.onSubmitHandler}
                        onError={(errors) => console.log(errors)}
                      >
                        <InputField
                      
                          inputType="text"
                          inputPlacholder="Search...."
                          requiredField=" "
                          name="Search_by_customer_name"
                          value={this.state.searchInput ?? ""}
                          textOnchangeFunction={
                            this.state.isEffitrac
                              ? this.handleSearchChangeForZohoEffitrack
                              : this.handleSearchInputChange
                          }
               
                        />
                      </ValidatorForm>
                    </Grid>
                  </Grid>
                </div>

                {this.state.isEffitrac ? (
                  <CustomTable
                    headers={this.state.effitracHeader}
                    columns={this.state.effitracCol}
                    data={this.state.effitracData}
                    actions={this.state.actions}
                  />
                ) : (
                  <>
                    <div className={classes.tableResponisve}>
                      <table className={classes.tableCss}>
                        <tbody>
                          <tr>{this.arc_Customer_TableHeader()}</tr>
                          {this.arc_Customer__data()}
                        </tbody>
                      </table>
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                      component="div"
                      count={this.state.rowCount} 
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid> */}

        {/*......................Edited By Harsh.....................*/}
        {/* import Zoho drawer */}
        {/* <Drawer
          anchor="right"
          open={this.state.openZohoDrawer}
        >
          <div
            style={{ background: '#ddd', textAlign: 'center', padding: '5px' }}
          >
            Zoho
          </div>
          {this.state.buttonColor === "green" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  color:
                    this.state.timerForZohoInSalesCustomer < 30
                      ? "red"
                      : "green",
                }}
              >
                Connection will be lost In{"  "}
                {this.state.timerForZohoInSalesCustomer} Seconds
              </span>
            </div>
          )}

          <ValidatorForm
            ref="form"
            onSubmit={this.fetchingdataForZoho}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "10px" }}>
              <label>
                Created From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="created_from_date"
                value={this.state.zohoDate.created_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "created_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                name="modified_from_date"
                value={this.state.zohoDate.modified_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "modified_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

              {this.state.effitracImportFormloader ? (
                <>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </>
              ) : (
                <button
                  className="button-primary"
                  type="submit"
                  disabled={this.state.buttonColor === "red" ? true : false}
                >
                  Submit
                </button>
              )}

              <button
                className="button-outlined-secondary"
                onClick={this.closeZohoDrawer}
              >
                cancel
              </Button>
            </ValidatorForm>

          </div>
        </Drawer> */}
        {/* import tally/effitrac end */}

        {/* import tally/Effitrac drawer */}
        <RightDrawer
          drawerName="Import Effitrac/Tally"
          open={this.state.openImportEffitracDrawer}
          onClose={this.closeEffitracDrawer}
        >
          <div style={{ textAlign: "center" }}>
            Last Upload Date<br></br>
            {this.state.lastUploadDate} {this.state.lastUploadTime}
          </div>

          <ValidatorForm
            ref="form"
            onSubmit={this.fetchEffitacFormSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "20px" }}>
              <label>
                Created From Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="createdFromDate"
                value={this.state.importEffitracFetchData.createdFromDate ?? ""}
                onChange={(v) =>
                  this.importEffitracCreatedFromDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Created To Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                name="createdToDate"
                value={this.state.importEffitracFetchData.createdToDate ?? ""}
                onChange={(v) =>
                  this.importEffitracCreatedToDateChangeHandler(v)
                }
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified From Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={
                  this.state.importEffitracFetchData.modifiedFromDate ?? ""
                }
                onChange={(v) => this.importEffitracFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>
                Modified To Date<span style={{ color: "red" }}></span>:
              </label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.importEffitracFetchData.modifiedToDate ?? ""}
                onChange={(v) =>
                  this.importEffitracModifiedToDateChangeHandler(v)
                }
              />
            </div>
            {/* submit and back button */}
            <div className="mt-20">
              {this.state.effitracImportFormloader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}

              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeEffitracDrawer}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>
        {/* import tally/effitrac end */}

        {/*arc show  drawer */}
        <RightDrawer
          drawerName="Customer Details"
          open={this.state.showDrawer}
          onClose={this.showToggleDrawerClose}
        >
          <>
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact Name"
              value={this.state.showData.CustContactName ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact Email"
              value={this.state.showData.CustContactEmail ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact No"
              value={this.state.showData.CustContactNo ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Address"
              value={this.state.showData.CustAddress ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="State"
              value={this.state.showData.CustState ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Country"
              value={this.state.showData.CustCountry ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Account Manager Name"
              value={this.state.showData.CustAcctmgrName ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Account Manager Email"
              value={this.state.showData.CustAcctmgrEmail ?? ""}
            />
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Account Manager Email"
              value={this.state.showData.CustAcctmgrNo ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Credit Limit"
              value={this.state.showData.CustCreditLimit ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Credit Days"
              value={this.state.showData.CustCreditDays ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Registration Type"
              value={this.state.showData.CustRegistrationType ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="TDS %"
              value={this.state.showData.tds_percentage ?? ""}
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Create Date"
              value={this.state.showData.created_date ?? ""}
            />
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Modify Date"
              value={this.state.showData.modified_date ?? ""}
            />
          </>
          {/* )} */}
          <button
            className="button-outlined-secondary mt-20"
            onClick={this.showToggleDrawerClose}
          >
            Close
          </button>
        </RightDrawer>
        {/* arc show drawer end */}

        {/* arc add drawer  */}
        <Drawer
          anchor="right"
          open={this.state.add_DrawerOpen}
          onClose={this.add_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addCustomer_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                // inputProps={{ readOnly: true }}
                value={this.state.addCustomerData.customerCode ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="legal Name"
                name="legalName"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCustomerData.legalName ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Country"
                name="country"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCustomerData.country ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="State"
                name="state"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addCustomerData.state ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Address"
                name="address"
                value={this.state.addCustomerData.address ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Name"
                name="contactName"
                requiredField=""
                value={this.state.addCustomerData.contactName ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Number"
                name="contactNumber"
                requiredField=""
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={this.state.addCustomerData.contactNumber ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Email"
                name="email"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.addCustomerData.email ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                value={this.state.addCustomerData.gstin ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="PAN"
                name="pan"
                requiredField={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? "*"
                    : ""
                }
                validator={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? ["required", "matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]
                    : ["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]
                }
                errorMsg={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? ["This field is required", "Invalid PAN Number"]
                    : ["Invalid PAN Number"]
                }
                value={this.state.addCustomerData.pan ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TAN"
                name="tan"
                validator={["matchRegexp:[A-Z]{4}[0-9]{5}[A-Z]{1}"]}
                errorMsg={["Invalid TAN Number"]}
                value={this.state.addCustomerData.tan ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Account Manager Name"
                name="acctmgrName"
                value={this.state.addCustomerData.acctmgrName ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Account Manager Contact Number"
                name="acctmgrNo"
                requiredField=""
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={this.state.addCustomerData.acctmgrNo ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Account Manager Email"
                name="acctmgrEmail"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.addCustomerData.acctmgrEmail ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Credit Days"
                name="creditDays"
                validator={["matchRegexp:[0-9]"]}
                errorMsg={["Must be number"]}
                value={this.state.addCustomerData.creditDays ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              {/*      <InputField
                inputType="text"
                textLabel="Credit Limit"
                name="creditLimit"
                value={
                  this.state.addCustomerData.creditLimit
                    ? numberFormat(this.state.addCustomerData.creditLimit)
                    : numberFormat(0)
                }
                textOnchangeFunction={this.addCustomer_inputNumberHandler}
              /> */}
              <div style={{ marginTop: "20px" }}>
                <label>₹ Credit Limit:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addCustomerData: {
                        ...prevState.addCustomerData,
                        creditLimit: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <SelectField
                SelectLabel="GSTRegistration Type"
                choice_id="id"
                choice_name="GSTRegistrationType"
                name="gstrRegistrationType"
                value={this.state.addCustomerData.gstrRegistrationType ?? ""}
                choices={[
                  { id: "Regular", GSTRegistrationType: "Regular" },
                  { id: "Unregistered", GSTRegistrationType: "Unregistered" },
                  { id: "Consumer", GSTRegistrationType: "Consumer" },
                  { id: "Composition", GSTRegistrationType: "Composition" },
                  { id: "Unknown", GSTRegistrationType: "Unknown" },
                ]}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TDS %"
                name="tdsPercentage"
                requiredField=""
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.addCustomerData.tdsPercentage ?? ""}
                textOnchangeFunction={this.addCustomer_inputHandler}
              />

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
                disabled={this.state.buttonDisabled}
              >
                {this.state.buttonDisabled ? "Uploading..." : "Submit"}
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.add_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>
        {/* arc add drawer end */}

        {/* arc edit drawer  */}
        <Drawer
          anchor="right"
          open={this.state.edit_DrawerOpen}
          onClose={this.edit_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editCustomer_submitHamdler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editCustomerData.customerCode ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="legal Name"
                name="legalName"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editCustomerData.legalName ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Country"
                name="country"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editCustomerData.country ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="State"
                name="state"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editCustomerData.state ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Address"
                name="address"
                value={this.state.editCustomerData.address ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Name"
                name="contactName"
                value={this.state.editCustomerData.contactName ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Contact Number"
                name="contactNumber"
                requiredField=""
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={this.state.editCustomerData.contactNumber ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Email"
                name="email"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.editCustomerData.email ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editCustomerData.gstin ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputProps={{ readOnly: true }}
                inputType="text"
                textLabel="PAN"
                name="pan"
                requiredField={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? "*"
                    : ""
                }
                validator={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? ["required", "matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]
                    : ["matchRegexp:[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}"]
                }
                errorMsg={
                  (this.state.addCustomerData.country || "").toLowerCase() ===
                  "india"
                    ? ["This field is required", "Invalid PAN Number"]
                    : ["Invalid PAN Number"]
                }
                value={this.state.editCustomerData.pan ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TAN"
                name="tan"
                validator={["matchRegexp:[A-Z]{4}[0-9]{5}[A-Z]{1}"]}
                errorMsg={["Invalid TAN Number"]}
                value={this.state.editCustomerData.tan ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Account Manager Name"
                name="acctmgrName"
                value={this.state.editCustomerData.acctmgrName ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Account Manager Contact Number"
                name="acctmgrNo"
                requiredField=""
                validator={["matchRegexp:^[0-9]{10}$"]}
                errorMsg={["Invalid contact number"]}
                value={this.state.editCustomerData.acctmgrNo ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Account Manager Email"
                name="acctmgrEmail"
                validator={["isEmail"]}
                errorMsg={["Email is not valid"]}
                value={this.state.editCustomerData.acctmgrEmail ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="Credit Days"
                name="creditDays"
                validator={["matchRegexp:[0-9]"]}
                errorMsg={["Must be number"]}
                value={this.state.editCustomerData.creditDays ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ Credit Limit:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  value={
                    this.state.editCustomerData.creditLimit
                      ? this.state.editCustomerData.creditLimit
                      : ""
                  }
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editCustomerData: {
                        ...prevState.editCustomerData,
                        creditLimit: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>
              <SelectField
                SelectLabel="GST Registration Type"
                choice_id="id"
                choice_name="GSTRegistrationType"
                name="gstrRegistrationType"
                value={this.state.editCustomerData.gstrRegistrationType ?? ""}
                choices={[
                  { id: "Regular", GSTRegistrationType: "Regular" },
                  { id: "Unregistered", GSTRegistrationType: "Unregistered" },
                  { id: "Consumer", GSTRegistrationType: "Consumer" },
                  { id: "Composition", GSTRegistrationType: "Composition" },
                  { id: "Unknown", GSTRegistrationType: "Unknown" },
                ]}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              <InputField
                inputType="text"
                textLabel="TDS %"
                name="tdsPercentage"
                requiredField=""
                validator={[
                  "matchRegexp:^([0]|[1-9][0-9]?|100)$|^[0-9]{0,2}.[0-9]{1,2}$",
                  "maxFloat:100.00",
                ]}
                errorMsg={["invalid percentage.", "invalid percentage."]}
                value={this.state.editCustomerData.tdsPercentage ?? ""}
                textOnchangeFunction={this.editCustomer_inputHandler}
              />

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
                disabled={this.state.buttonDisabled}
              >
                {this.state.buttonDisabled ? "Uploading..." : "Submit"}
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.edit_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>
        {/* arc edit drawer end */}

        {/* effitrac/tally show drawer */}
        <RightDrawer
          drawerName="Effitrac/Tally Details"
          open={this.state.effitracInvShow}
          onClose={this.closeEffitracInvShowHandler}
        >
          <>
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact Name"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_contact_name
              }
            />
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact Email"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_contact_email
              }
            />
            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Contact No"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_contact_no
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Address"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_address
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="State"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_state
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Country"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_country
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Account Manager Name"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_acctmgr_name
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Account Manager Email"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_acctmgr_email
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Credit Limit"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_credit_limit
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Credit Days"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].cust_credit_days
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="Registration Type"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId]
                  .cust_registration_type
              }
            />

            <TextField
              className={classes.showTextField}
              inputProps={{ readOnly: true }}
              fullWidth
              label="TDS %"
              value={
                this.state.targetId != null &&
                this.state.effitracData[this.state.targetId].tds_percentage
              }
            />
          </>
          <button
            className="button-outlined-secondary mt-20"
            onClick={this.closeEffitracInvShowHandler}
          >
            Back
          </button>
        </RightDrawer>
        {/* effitrac/tally show drawer end*/}
      </React.Fragment>
    );
  }
}

export default BussinessCustomerMang;

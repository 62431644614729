import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../../../axios-order";
import "react-toastify/dist/ReactToastify.css";
import "./TDSRecon.css";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import InfiniteScroll from 'react-infinite-scroll-component';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import Form26AsPairing from "./26ASPairing";
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Tooltip from "@mui/material/Tooltip";
import { red } from '@mui/material/colors';
import ConfirmationDialog from './ConfirmationDailog'

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import EmailIcon from '@mui/icons-material/Email';
import LoadingButton from '@mui/lab/LoadingButton';

const initialPageSize = 1000;
export default function Form26asreport() {
  let history = useHistory();
  const [form_data, setFormData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [emptyView, setEmptyView] = useState(false)
  const tableRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("warning");

  const [responseMessage, setResponseMessage] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);

  const [emailButtonLoading, setEmailButtonLoading] = useState(false)

  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);

  const [searchInput, setSearchInput] = useState('');

  const [filteredData, setFilteredData] = useState([]);

  const [invoiceSelected, setInvoiceSelected] = useState(false)

  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setResponseMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchInput]);


  const fetchData = () => {
    setIsLoading(true);
    console.log('use effect calling FECTH DATA ')
    const entity_id = localStorage.getItem("business_entity_id")

    const financial_year = localStorage.getItem('financial_year_26_as')

    const payLoad = {
      'business_entity_id': entity_id,
      'financial_year': financial_year,
      'page_number': currentPage,
      'page_size': initialPageSize,
      'search_text': ''
    }

    axios
      .post('/form_26/get_data', payLoad)
      .then((res) => {
        if (res.data) {
          // setFormData((prevData) => [...prevData, ...res.data.records]);
          setFormData(res.data.records)
          setFilteredData(res.data.records)
          setCurrentPage((prevPage) => prevPage + 1);
          setIsLoading(false);
          if (res.data.records.length < initialPageSize) {
            setHasMore(false);
          }
        } else {
          setEmptyView(true)
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setEmptyView(true)
      });

  };

  const loadMore = () => {
    fetchData();
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcel = () => {
    const payloadData = {

      "business_entity_id": localStorage.getItem("business_entity_id"),
      "financial_year": localStorage.getItem('financial_year_26_as')
    }

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `TDS Receivables Reconciliation Report.xlsx`;

    axios.post('/form26asreport_for_table2', payloadData)
      .then((res) => {
        downloadBase64File(contentType, res?.data?.file, filename);
      }).catch((err) => {

      })
  };

  const navigatePairPage = (item, index) => {
    const pairing_data = {
      "business_entity_id": localStorage.getItem("business_entity_id"),
      "books_grouping_key": item.books_grouping_key,
      "books_grouping_type": item.books_grouping_type,
      "form_26as_grouping_key": item.form_26as_grouping_key,
      "grouping_key": item.form_26as_grouping_key,
      "form_26_grouping_type": item.form_26as_grouping_type ? item.form_26as_grouping_type : "",
      "financial_year": localStorage.getItem('financial_year_26_as')
    }
    localStorage.setItem("lastClickedIndex", index);

    history.push({
      pathname: `/26Pairing`,
      state: { pairingData: pairing_data }
    });
  }


  const handleBackFunction = () => {
    history.goBack();
  }

  const handleDialogConfirm = () => {
    const body = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      financial_year: localStorage.getItem('financial_year_26_as')
    }
    axios.post('/form26/unpair_manual_pairs', body)
      .then((res) => {
        if (res.data) {
          handleSnackbarOpen(res.data.message, 'success')
        }
      }).catch((err) => {

      })
  }

  useEffect(()=>{
    console.log(invoiceSelected)
  },[invoiceSelected])

  const onClickSendEmailNotification = () => {
    setEmailButtonLoading(true)
    const pairing_data = {
      "entity_name": localStorage.getItem("entity_name"),
      "business_entity_id": localStorage.getItem("business_entity_id"),
      "financial_year": localStorage.getItem('financial_year_26_as'),
      "selected_items": selectedItems,
      "invoice_details_slected" : invoiceSelected
    }
    axios.post('/form26/send_form_26as_report_email', pairing_data)
      .then((res) => {
        setEmailButtonLoading(false)
        setSelectedItems([])
        setSelectAllChecked(false)
        console.log(res.data)
        if (res.data.status) {
          handleSnackbarOpen(res.data.message, "success")
        } else {
          handleSnackbarOpen(res.data.message, "warning")
        }
      })
      .catch((err) => {
        setEmailButtonLoading(false)
        setSelectedItems([])
        setSelectAllChecked(false)
      })
    setInvoiceSelected(false)
  }

  const handleCheckboxChange = (item, isChecked) => {
    setSelectedItems(prevSelectedItems => {
      let newSelectedItems;
      if (isChecked) {
        newSelectedItems = [...prevSelectedItems, item];
      } else {
        newSelectedItems = prevSelectedItems.filter(selectedItem => selectedItem !== item);
      }
      const allItemsSelected = filteredData.filter(item => item["Difference"] > 10)
        .every(item => newSelectedItems.includes(item));
      setSelectAllChecked(allItemsSelected);
  
      return newSelectedItems;
    });
  };
  

  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    if (isChecked) {
      const selected = filteredData.filter(item => item["Difference"] > 10);
      setSelectedItems(selected);
    } else {
      setSelectedItems([]);
    }
  };
  

  const handleNoticationClick = () => {
    if (selectedItems.length === 0) {
      setEmailButtonLoading(false)
      handleSnackbarOpen('Please select records to send a notification.', 'warning')
      return ''
    } else {
      setNotificationDialogOpen(true)
    }
  }

  useEffect(() => {
    const lastClickedIndex = localStorage.getItem("lastClickedIndex");
    if (lastClickedIndex && lastClickedIndex !== null && tableRef &&tableRef.current) {
      setTimeout(() => {
        const row = tableRef.current.querySelectorAll("tr")[Number(lastClickedIndex) + 1]; // +2 to account for header rows
        if (row) {
          row.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 0);
    }
  }, [filteredData]);

  const handleSearchEvent = (event) => {
    localStorage.setItem("lastClickedIndex", null);
    const inputValue = event.target.value;
    setSearchInput(inputValue)

  }

  const filterData = () => {
    const filtered = form_data.filter(record => {
      const customerName = record['CUSTOMER NAME'] || '';
      const customerPan = record['CUSTOMER PAN'] || '';
      return customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
        customerPan.toLowerCase().includes(searchInput.toLowerCase());
    });
    setFilteredData(filtered);
  }





  const financial_year = localStorage.getItem('financial_year_26_as')

  return (
    <>
      <BoxWrapper
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={handleSearchEvent}
        exportToExcelEnable={true} exportToExcelFn={exportToExcel} boxTitle={`TDS detailed Summary  ( ${financial_year} )`}>
        <InfiniteScroll
          dataLength={filteredData.length}
          next={loadMore}
          hasMore={hasMore}
          hideScrollbar={true}
          scrollableTarget="scrollableDiv"
        >
          <div
            className="responsive-table"
            style={{ scrollbarWidth: "thin", scrollbarHeight: '25px', maxHeight: '54vh' }}
            id="scrollableDiv"
            
          >
            <table className="summary-tab-single-year-table-css" ref={tableRef}>

              <thead>
                <tr>
                  <th style={{ width: process.env.REACT_APP_MODE === "DEBUG" ? "45%" : "22%" }} colSpan={process.env.REACT_APP_MODE === "DEBUG" ? 7 : 3}>
                    Customer Data
                  </th>
                  <th colSpan={process.env.REACT_APP_MODE === "DEBUG" ? 11 : 9}>
                    TDS Summary
                  </th>
                  <th rowSpan={2} className="action-column" style={{ width: process.env.REACT_APP_MODE === "DEBUG" ? "4.94%" : "2.95%" }}>Action</th>
                </tr>
                <tr>
                  <th>
                    <label style={{ display: 'flex',marginLeft:'25%' ,cursor:'pointer'}}>
                    {filteredData.some(item => item["Difference"] > 10) && (
                      <input
                        type="checkbox"
                        checked={selectAllChecked}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: '#fff',
                          border: '2px solid #4CAF50',
                          borderRadius: '5px',
                          cursor: 'pointer'
                        }}
                      />
                    )}
                      <span style={{ marginLeft: '5px' }}>Notification</span>
                    </label>
                  </th>


                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ width: "7%", backgroundColor: '#808080' }}>Grouping Key</th>}
                  <th>Customer PAN</th>
                  <th>Customer Name</th>
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }} className="customer-name-26as-column">Customer Name 26AS</th>}
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }} className="books-tan-column">Books TAN</th>}
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ backgroundColor: '#808080' }} className="form26as-tan-column">26AS TAN</th>}
                  <th style={{ width: "5%" }}>Books TDS Opening Balance</th>
                  <th style={{ width: "5%" }}>TDS as per Books</th>
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ width: "7%", backgroundColor: '#808080' }}>Count of Books records</th>}
                  <th style={{ width: "5%" }}>26AS TDS Opening Balance</th>
                  <th style={{ width: "5%" }}>TDS as per 26AS</th>
                  {process.env.REACT_APP_MODE === "DEBUG" && <th style={{ width: "7%", backgroundColor: '#808080' }}>Count of 26AS records</th>}
                  <th style={{ width: "5%" }}>Difference</th>
                  <th style={{ width: "5%" }}>Carry Forward TDS - Books</th>
                  <th style={{ width: "5%" }}>Carry Forward TDS - 26AS</th>
                  <th style={{ width: "5%" }}>Previous Balance Availed in Current Year - Books</th>
                  <th style={{ width: "5%" }}>Previous Balance Availed in Current Year-26AS</th>
                </tr>

              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      style={{ color: "black", textAlign: 'center', fontWeight: "bold" }}
                      colspan={12}
                    >
                      Loading…Please wait
                    </td>
                  </tr>
                ) :

                  filteredData.length >= 1 ? (filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {(item["Difference"]) > 10 && <input type='checkbox'
                          style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                            border: '2px solid #4CAF50',
                            borderRadius: '5px',
                            cursor: 'pointer'
                          }}
                          checked={selectedItems.includes(item)}
                          onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                        />}
                      </td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["books_grouping_key"] ? item["books_grouping_key"] : item["form_26as_grouping_key"]}</td>}
                      <td align="center">{item["CUSTOMER PAN"]}</td>

                      <td>
                        {item["CUSTOMER NAME"] && item["CUSTOMER NAME"].split(", ").map((name, i, arr) => (
                          <React.Fragment key={i}>
                            {name}
                            {i !== arr.length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["customer_name_26AS"]}</td>}
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">
                        {item["books_tan"]}
                      </td>}
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["form26as_tan"]}</td>}
                      <td>{item["Books TDS Opening Balance"]}</td>
                      <td>{item["TDS as per Books"]}</td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["count_books_records"]}</td>}
                      <td>{item["26AS TDS Opening Balance"]}</td>
                      <td>{item["TDS as per 26AS"]}</td>
                      {process.env.REACT_APP_MODE === "DEBUG" && <td align="center">{item["count_26as_records"]}</td>}
                      <td>{(item["Difference"] || item["Difference"] == 0) ? item["Difference"].toFixed(2) : ''}</td>
                      <td>{item["Carry Forward TDS - Books"]}</td>
                      <td>{item["Carry Forward TDS - 26AS"]}</td>
                      <td>{item["Previous Balance Availed in Current Year - Books"]}</td>
                      <td>{item["Previous Balance Availed in Current Year - 26AS"]}</td>

                      <td className="action-column" style={{ backgroundColor: '#f2f2f2', border: '1px solid #ddd' }}>

                        <VisibilityOutlinedIcon onClick={() => navigatePairPage(item, index)} className="action-icon-button showIcon" />
                      </td>


                    </tr>
                  ))) : (
                    <tr>
                      <td style={{ color: "red" }} colspan={6}>
                        <img src="https://res.cloudinary.com/dyivs1j8u/image/upload/v1713335965/Screenshot_2024-04-17_120750-removebg-preview_tdhay3.png" />
                      </td>
                    </tr>
                  )}

              </tbody>

            </table>
          </div>
        </InfiniteScroll>
      </BoxWrapper>
      <LoadingButton
        color="primary"
        onClick={handleNoticationClick}
        loading={emailButtonLoading}
        loadingPosition="start"
        startIcon={<EmailIcon />}
        variant="contained"
        style={{ marginLeft: '63%', width: '120px', height: '30px', marginBottom: '2.5px' }}
      >
        <span>Send</span>
      </LoadingButton>
      <button
        onClick={() => setDialogOpen(true)}
        className="button-outlined-primary"
        style={{ marginLeft: '1%', width: '180px', marginTop: '20px' }}
      >
        Unpair all Manual Pairs
      </button>
      <button
        onClick={handleBackFunction}
        className="button-outlined-primary"
        style={{ marginLeft: '0%', width: '120px', marginTop: '20px' }}
      >
        Back
      </button>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleDialogConfirm}
        title={'Confirm Manual Pairs Unpairing'}
        text={`This will remove all manual pairs across all customers. Are you sure you want to proceed?`}
      />

      <ConfirmationDialog
        open={notificationDialogOpen}
        onClose={() => setNotificationDialogOpen(false)}
        onConfirm={onClickSendEmailNotification}
        title="Send Email Notification"
        text="Are you sure you want to send email notifications to the selected customers?"
        enableCheckBox={true}
        checkBoxLable={'Report with invoice details'}
        onChnageInvoiceCheckbox={() => setInvoiceSelected((prev) => !prev)}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000} // Adjust as needed
        onClose={handleSnackbarClose}
        style={{ maxWidth: '25%' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity} // Use the state variable to determine severity
        >
          {responseMessage}

        </MuiAlert>
      </Snackbar>
    </>
  );
}

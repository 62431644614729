import React, { Component } from "react";
import "./home.css";
import axios from "../../axios-order";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "../../components/ui/card";
import Grid from "@material-ui/core/Grid";
import Appbar from "../../components/appBar/appBar";
import Footer from "../../components/footer/footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import usersIcon from "../../asserts/images/team.png";
import pendingIcon from "../../asserts/images/waitingList.png";
import entityIcon from "../../asserts/images/office-building.png";
// import PanTable from "./PanTable";
import WelcomeImage from "../../asserts/images/homeImage.jpg";
import BoxWrapper from "../../components/ui/boxWrapper";

let trigger = 1;

const useStyles = makeStyles({
  list: {
    width: 1050,
    height: 1000,
  },
  fullList: {
    width: "auto",
  },
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      business_entity_data: [],
      adminOriginalData: [],
      adminData: [],
      champOriginalData: [],
      userOriginalData: [],
      userData: [],
      role: localStorage.getItem("role"),
      showDataloader: false,
      panWiseData: [],
      entityDetails:[]
    };
  }

  //---Search field---
  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;
    if (this.state.role === "ADMIN") {
      let searchAdminData = this.state.adminOriginalData.filter((value) => {
        const query = searchInput.toLowerCase();
        return (
          value.entity_name.toLowerCase().indexOf(query) >= 0
          
        );
      });
      this.setState({ adminData: searchAdminData });
    } else if (this.state.role === "CHAMP") {
      let searchAdminData = this.state.champOriginalData.filter((value) => {
        const query = searchInput.toLowerCase();
        return (
          value.entity_name.toLowerCase().indexOf(query) >= 0 ||
          value.gst.toLowerCase().indexOf(query) >= 0 ||
          String(value.service_id).toLowerCase().indexOf(query) >= 0
        );
      });
      this.setState({
        List_of_Business_entities_with_Services_assigned: searchAdminData,
      });
    } else {
      let searchAdminData = this.state.userOriginalData.filter((value) => {
        const query = searchInput.toLowerCase();
        return (
          value.entity_name.toLowerCase().indexOf(query) >= 0 ||
          value.gst.toLowerCase().indexOf(query) >= 0 ||
          String(value.service_id).toLowerCase().indexOf(query) >= 0
        );
      });
      this.setState({
        userData: searchAdminData,
      });
    }
  };

  // ---End---

  toSetTheValue = (data) => {
    data?.map( (v,i) => {
      this.setState(prevState => (
        {
          entityDetails:[
            ...prevState.entityDetails,
            {
              entity_name:v.entity_name , 
              business_entity_id:v.business_entity_id,
              date_incorporation:v.date_incorporation
          }]
        }
      ) )
    })
  }

  getResultApi = () => {
    if (this.state.role === "ADMIN") {
      axios
        .get("/get_admin_home_stats")
        .then((res) => {
          trigger = 1;
          this.setState({
            adminOriginalData:
              res.data.List_of_Business_entities_with_Services_assigned,
            adminData:
              res.data.List_of_Business_entities_with_Services_assigned,
            Total_number_of_active_business_users:
              res.data.Total_number_of_active_business_users,
            Total_number_of_active_Business_entities:
              res.data.Total_number_of_active_Business_entities,
            Total_number_of_pending_Business_entities:
              res.data.Total_number_of_pending_Business_entities,
            showDataloader: false,
          } , () => this.toSetTheValue(res.data.List_of_Business_entities_with_Services_assigned) );

          const entityListData = res.data.List_of_Business_entities_with_Services_assigned;

          localStorage.setItem('entity_list',JSON.stringify(entityListData))

        })
        .catch((error) => {
          trigger += 1;
          if (trigger < 4) {
            this.getResultApi();
          } else {
            trigger = 1;
            this.setState({ showDataloader: false });
            toast.error(error?.response?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        });
    } else if (this.state.role === "CHAMP") {
      axios
        .get("/get_champ_home_stats")
        .then((res) => {
          trigger = 1;
          this.setState({
            Total_number_of_active_Business_users:
              res.data.Total_number_of_active_Business_users,
            Total_number_of_active_Business_entities:
              res.data.Total_number_of_active_Business_entities,
            List_of_Business_entities_with_Services_assigned:
              res.data.List_of_Business_entities_with_Services_assigned,
            champOriginalData:
              res.data.List_of_Business_entities_with_Services_assigned,
            showDataloader: false,
          });
          const entityListData = res.data.List_of_Business_entities_with_Services_assigned;

          localStorage.setItem('entity_list',JSON.stringify(entityListData))
        })
        .catch((error) => {
          trigger += 1;
          if (trigger < 4) {
            this.getResultApi();
          } else {
            trigger = 1;
            this.setState({ showDataloader: false });
            toast.error(error?.response?.data?.message, {
              autoClose: 3000,
              progress: 0,
            });
          }
        });
    } else {
      axios
        .get("/get_user_home_stats")
        .then((res) => {
          trigger = 1;
          this.setState({
            userData: res.data.List_of_Business_entities_with_Services_assigned,
            userOriginalData:
              res.data.List_of_Business_entities_with_Services_assigned,
            showDataloader: false,
          });
          const entityListData = res.data.List_of_Business_entities_with_Services_assigned;

          localStorage.setItem('entity_list',JSON.stringify(entityListData))
        })
        .catch((error) => {
          trigger += 1;
          if (trigger < 4) {
            this.getResultApi();
          } else {
            trigger = 1;
            this.setState({ showDataloader: false });
            toast.error("No Data Found!!", {
              autoClose: 3000,
              progress: 0,
            });
          }
        });
    }
  };

  componentDidMount = () => {
    toast.configure();
    this.setState({ showDataloader: true });

    this.getResultApi();
    
    if (window.location.pathname === "/home") {
      localStorage.removeItem("PanNumber");
      localStorage.removeItem("business_entity_id");
      localStorage.removeItem("entity_name");
      localStorage.removeItem("gst");
      localStorage.setItem("TriggerPanDashBoard", "false");
      localStorage.removeItem("Client_ID");
      localStorage.removeItem("Trigger_Effitrac/Tally_Button");
      localStorage.setItem("UploadTallyStatus", "false");
      localStorage.setItem("urlForTally", "");
      localStorage.removeItem("EmailId");
    }
    // if (process.env.REACT_APP_ENV === "local") {
    //   axios
    //     .get("/get_panwise_home_status")
    //     .then((res) => {
    //       this.setState({ panWiseData: res.data.pan_wise_details });
    //       // console.log(res);
    //     })
    //     .catch((err) => console.log(err));
    // }
  };
  // ---All Data[Entity]
  getListEntity = () => {
    toast.configure();
    axios
      .get("/get_list_entities")
      .then((res) => {
        this.setState({ business_entity_data: res.data.records });
      })
      .catch((error) => {
        this.setState({ showDataloader: false });
        toast.error(error?.response?.data?.message, {
          autoClose: 3000,
          progress: 0,
        });
      });
  };
  //---End--
  //   ---Work funtion---
  workAction = (business_entity_id, entity_name, gst , contactEmailId) => {
    localStorage.setItem("business_entity_id", business_entity_id);
    localStorage.setItem("entity_name", entity_name);
    localStorage.setItem("gst", gst);
    localStorage.removeItem("PanNumber");
    localStorage.setItem("TriggerPanDashBoard", "false");
    localStorage.setItem("EmailId" , contactEmailId );

    this.props.history.replace(`/landingcws`);
  };
  // ---End---

  // handlePushFunction = (pan) => {
  //   // alert(pan);
  //   localStorage.setItem("TriggerPanDashBoard", "true");
  //   localStorage.setItem("PanNumber", pan);
  //   localStorage.removeItem("business_entity_id");
  //   localStorage.removeItem("entity_name");
  //   localStorage.removeItem("gst");

  //   this.props.history.replace(`/landingcws`);
  // };

  //---Assigned business entity table---
  entityTableHeader() {
    const tableHeader = ["Entity Name", "GSTIN", "service", "Action"];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  entityTableData() {
    if (this.state.role === "ADMIN") {
      if (this.state.adminData?.length >= 1) {
        return this.state.adminData.map((tableData, index) => {
          const { business_entity_id, entity_name, gst, service_id , contact_email_id } =
            tableData; //destructuring

          return (
            <tr key={index}>
              <td style={{ textTransform: "capitalize" }}>{entity_name}</td>

              <td>{gst}</td>

              <td>{`${service_id[0]}${service_id[1] ? "," : ""} ${
                service_id[1] ? service_id[1] : ""
              }${service_id[2] ? "," : ""}${
                service_id[2] ? service_id[2] : ""
              }`}</td>

              <td>
                <Button
                  size="small"
                  onClick={() =>
                    this.workAction(business_entity_id, entity_name, gst , contact_email_id)
                  }
                  style={{
                    color: "#ba4242",
                    fontFamily: "Dosis",
                    fontWeight: "550",
                  }}
                >
                  select
                </Button>
              </td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td style={{ color: "red" }} colspan={8}>
              No data found!
            </td>
          </tr>
        );
      }
    } else if (this.state.role === "CHAMP") {
      if (this.state.List_of_Business_entities_with_Services_assigned) {
        return this.state.List_of_Business_entities_with_Services_assigned.map(
          (tableData, index) => {
            const { business_entity_id, entity_name, gst, service_id } =
              tableData; //destructuring
            return (
              <tr key={index}>
                <td style={{ textTransform: "capitalize" }}>{entity_name}</td>

                <td>{gst}</td>

                <td>{`${service_id[0]}${service_id[1] ? "," : ""} ${
                  service_id[1] ? service_id[1] : ""
                }${service_id[2] ? "," : ""}${
                  service_id[2] ? service_id[2] : ""
                }`}</td>

                <td>
                  <Button
                    size="small"
                    onClick={() =>
                      this.workAction(business_entity_id, entity_name, gst)
                    }
                    variant="outlined"
                    style={{
                      color: "#ba4242",
                      fontFamily: "Dosis",
                      fontWeight: "550",
                    }}
                  >
                    select
                  </Button>
                </td>
              </tr>
            );
          }
        );
      } else {
        return (
          <tr>
            <td style={{ color: "red" }} colspan={8}>
              No data found!
            </td>
          </tr>
        );
      }
    } else {
      if (this.state.userData?.length > 1) {
        return this.state.userData.map((tableData, index) => {
          const { business_entity_id, entity_name, gst, service_id } =
            tableData; //destructuring
          return (
            <tr key={index}>
              <td style={{ textTransform: "capitalize" }}>{entity_name}</td>

              <td>{gst}</td>

              <td>{`${service_id[0]}${service_id[1] ? "," : ""} ${
                service_id[1] ? service_id[1] : ""
              }${service_id[2] ? "," : ""}${
                service_id[2] ? service_id[2] : ""
              }`}</td>

              <td>
                <Button
                  size="small"
                  onClick={() =>
                    this.workAction(business_entity_id, entity_name, gst)
                  }
                  variant="outlined"
                >
                  select
                </Button>
              </td>
            </tr>
          );
        });
      } else if (this.state.userData?.length === 1) {
        return this.workAction(
          this.state.userData[0].business_entity_id,
          this.state.userData[0].entity_name,
          this.state.userData[0].gst
        );
      } else {
        return (
          <tr>
            <td style={{ color: "red" }} colspan={8}>
              No data found!
            </td>
          </tr>
        );
      }
    }
  }
  //   ---End---
  pendingRequestHandler = () => {
    this.props.history.push({
      pathname: `/pendingRequest`,
      //   data: `/${business_entity_id}`,
    });
  };

  businessEntityMgmtHandler = () => {
    this.props.history.push({
      pathname: `/businessEntityMgmt`,
      //   data: `/${business_entity_id}`,
    });
  };

  activeUserHandler = () => {
    this.props.history.push({
      pathname: `/userManagement`,
      //   data: `/${business_entity_id}`,
    });
  };

  //  ---Card data---
  cardData = () => {
    let role = localStorage.getItem("role");
    if (role === "ADMIN") {
      return (
        <div className="CardSection">
          <Grid container spacing={3}>
            <Grid item xs>
              <Card
                card_title={
                  this.state.Total_number_of_active_Business_entities
                    ? this.state.Total_number_of_active_Business_entities
                    : 0
                }
                card_content="Active Business Entities"
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                onClickCardHandler={this.businessEntityMgmtHandler}
                iconForCard={entityIcon}
              />
            </Grid>
            <Grid item xs>
              <Card
                card_title={
                  this.state.Total_number_of_pending_Business_entities
                    ? this.state.Total_number_of_pending_Business_entities
                    : 0
                }
                card_content="Pending Business Entities "
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                onClickCardHandler={this.pendingRequestHandler}
                iconForCard={pendingIcon}
              />
            </Grid>
            <Grid item xs>
              <Card
                card_title={
                  this.state.Total_number_of_active_business_users
                    ? this.state.Total_number_of_active_business_users
                    : 0
                }
                card_content="Active Business Users"
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                onClickCardHandler={this.activeUserHandler}
                iconForCard={usersIcon}
              />
            </Grid>
            {/* <Grid item xs>
              <Card
                card_title="864"
                card_content="Total number of emapanelled Buyers"
              />
            </Grid> */}
          </Grid>
        </div>
      );
    } else if (role === "CHAMP") {
      return (
        <div className="CardSection">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card
                card_title={
                  this.state.Total_number_of_active_Business_entities
                    ? this.state.Total_number_of_active_Business_entities
                    : 0
                }
                card_content="Total number of active Business entities"
                backgroundColor="linear-gradient(to right,#3b3b6b, #fff)"
                iconForCard={entityIcon}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  TdsPayableButtonHandler = () => {
    this.props.history.push("/enviromentTdsPayable");
  };

  creditEvalutionButtonHandler = () => {
    this.props.history.push("/enviromentCreditEvalution");
  };

  render() {
    return (
      <>
        <div className="home">
          <div className="left">
            {/* <img
              src={WelcomeImage}
              width="100%"
              height="100%"
              arc="welcome_image"
            /> */}
          </div>

          <div className="right">
            <Appbar data={this.state.entityDetails} />

            <div style={{ padding: "20px", marginTop: "60px", width: "100%" }}>
              {this.state.showDataloader === false ? (
                <>
                  <div style={{ marginTop: "10px" }}>{this.cardData()}</div>
                  {/* {process.env.REACT_APP_ENV === "local" &&
              this.state.panWiseData &&
              this.state.panWiseData.length >= 1 && (
                <div className={classes.businessEntityTableForPan}>
                  <div className={classes.businessEntityTable_header}>
                    PAN Wise entities
                  </div>

                  <PanTable
                    panData={this.state.panWiseData}
                    handleClickFunction={this.handlePushFunction}
                  />
                </div>
              )} */}

                  <BoxWrapper
                    boxTitle="Business Entities"
                    // search input
                    enableSearchInput={true}
                    searchInputPlaceholder="Search..."
                    searchInputOnchange={this.handleSearchInputChange}
                  >
                    <div className="responsive-table">
                      <table className="custome-table">
                        <thead>
                          <tr>{this.entityTableHeader()}</tr>
                        </thead>
                        <tbody>{this.entityTableData()}</tbody>
                      </table>
                    </div>
                  </BoxWrapper>
                  {this.state.role === "ADMIN" && (
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="button-secondary"
                        onClick={this.TdsPayableButtonHandler}
                      >
                        TDS PAYABLE
                      </button>
                      <button
                        className="button-primary"
                        style={{ marginRight: "0px" }}
                        onClick={this.creditEvalutionButtonHandler}
                      >
                        CREDIT EVALUATION
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="fullPageloader">
                    <div>
                      {" "}
                      <CircularProgress size={40} thickness={3.5} />
                      <br></br>
                      Loading.Please wait...
                    </div>
                  </div>
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(Home);

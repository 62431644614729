import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import { SelectValidator } from 'react-material-ui-form-validator';
// import { ListItemSecondaryAction } from '@material-ui/core';

const BasicTextFields = (props) => {
    return (
        <div style={{marginTop:"20px"}}>
            <label>{props.SelectLabel}<span style={{ color: 'red' }}>{props.requiredField}</span>:</label>
            <SelectValidator validators={props.validator} inputProps={props.inputProps ? props.inputProps : {}}
                errorMessages={props.errorMsg} variant="outlined"  size="small" fullWidth="true"  name={props.name} value={props.value}    style={{background:"#fff",borderRadius:"5px"}} onChange={props.textOnchangeFunction}>
                {props.choices ? props.choices.map((items, i) => {
                    return (<MenuItem value={items[props.choice_id]} selected={items[props.choice_id]===1?true:false}  >{items[props.choice_name]}</MenuItem>)
                }) : ""}
            </SelectValidator>
        </div>
    );
};

export default BasicTextFields;
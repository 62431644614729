import React, { Component } from "react";
import classes from "./ARCPaymentAdvice.module.css";
import axios from "../../../../axios-order";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "../../../../components/ui/Cards";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import DataTables from "material-ui-datatables";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import SellerPaymentAdvice from "../../../../asserts/templates/seller_payment_advice.xlsx";
import NumberFormat from "react-number-format";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import Drawer from "@material-ui/core/Drawer";
import ClearIcon from "@material-ui/icons/Clear";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/ui/inputField";
import CircularProgress from "@material-ui/core/CircularProgress";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import Modal from "react-bootstrap/Modal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CustomTable from "../../../../components/ui/Table/Table";
import { TablePagination } from "@material-ui/core";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../components/ui/RightDrawer";

import ZohoModel from "../../../../components/ZohoModel/ZohoModel";

let flagForPayment = 1;
let variableForTimerInSalesPayment = null;

// indian rupeess system---
const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

const dateFormat = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "/" + old_date[1] + "/" + old_date[0];
  }
};

const dateFormatWithDash = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "-" + old_date[1] + "-" + old_date[0];
  }
};

class PaymentAdvice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      effitracCol: [
        {
          key: "ERP customerCode",
          render: (index, row) => {
            return row["erp_cust_code"];
          },
        },

        {
          key: "gstin",
          render: (index, row) => {
            return row["cust_gstin"];
          },
        },

        {
          key: "invoiceNo",
          render: (index, row) => {
            return row["invoice_no"];
          },
        },
        {
          key: "invoiceDate",
          render: (index, row) => {
            return row["Invoice_date"];
          },
        },
        {
          key: "paymentDate",
          render: (index, row) => {
            return row["payment_date"];
          },
        },
        {
          key: "transactionRefNo",
          render: (index, row) => {
            return row["transaction_Ref_number"];
          },
        },

        {
          key: "amountPaid",
          render: (index, row) => {
            return numberFormat(row["amount_paid"]);
          },
        },

        // {
        //   key: "tdsAmount",
        //   render: (index, row) => {
        //     return numberFormat(row["tds_amount"]);
        //   },
        // },

        // {
        //   key: 'fetrack',
        //   render: (index, row) => {
        //     return row['fetrack'];
        //   },
        // },
      ],
      effitracData: null,
      oldEffitracData: null,
      effitracHeader: [
        { label: "Customer Code" },
        { label: "GSTIN" },
        { label: "Invoice No" },
        { label: "Invoice Date" },
        { label: "Payment Date" },
        { label: "Transaction Ref No" },
        { label: "Amount Paid" },
        // { label: 'Fetrack' },
      ],

      read_only: "",
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        // { key: 'seller_code', label: 'Seller Code', },
        {
          label: "GSTIN",
          render: (name, all) => {
            return all["record"]["GSTIN"];
          },
        },
        {
          label: "Invoice Number",
          render: (name, all) => {
            return all["record"]["InvoiceNo"];
          },
        },
        { key: "row", label: "Row #" },
        {
          label: "Errors",
          render: (name, all) => {
            return Object.keys(all["errors_in"]).map((i, v) => {
              return (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: "red" }}>
                    {all["errors_in"][i].join(",")}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,

      allArcPaymentAdviceData: [],
      OriginalAllArcPaymentAdviceData: [],
      showUploadData: false,
      showDrawer: false,
      showPaymentDetailsData: {},
      loading: false,

      add_DrawerOpen: false,
      addPaymentAdviceData: {},

      edit_DrawerOpen: false,
      editPaymentAdviceData: {},

      // add paymentAdvice modal
      invoiceApiDataForPaymentAdviceModal: [],
      invoiceApiSearchDataForPaymentAdviceModal: [],
      paymentAdviceModalOpen: false,
      paymentAdviceModalBackDrop: false,

      // collection data--for transaction Ref No
      allArcCollectionData: [],
      OriginalAllArcCollectionData: [],
      collectionRefNoModalOpen: false,

      //............Edited by Harsh..............
      openZohoDrawer: false,
      zohoFetchDate: {},
      messageForConnection: "Establish Connection",
      buttonColor: "red",
      grantToken: "",
      url: "",
      zohoDate: {},

      // import effitrac / tally
      openImportEffitracDrawer: false,
      effitracFetchFromData: {},

      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: "",

      // pagination for select Invoice
      pageForInvoice: 0,
      rowsPerPageForInvoice: 25,
      rowCountForInvoice: 0,
      paymentAdviceModalSearchInput: "",

      // pagination for select Collection
      pageForCollection: 0,
      rowsPerPageForCollection: 25,
      rowCountForCollection: 0,
      paymentAdviceModalSearchInputCollection: "",

      uploadHistoryTableData: [],
      showUploadHistroyTable: false,

      flagForZohoInSalesPayment: false,
      timerForZohoInSalesPayment: 60,
      loadingPaymentAdvice: true,
      // variableForTimerInSalesPayment:null ,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.paymentAdviceGetAPi(event, this.state.searchInput);
    });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.paymentAdviceGetAPi(event, this.state.searchInput);
    });
  };
  // table serach
  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.paymentAdviceGetAPi(event, this.state.searchInput);
      }
    );
  };

  //select Invoice
  handleChangePageForInvoice = (event, newPage) => {
    this.setState({ pageForInvoice: newPage }, () => {
      this.getSellerInvoiceDataApi(this.state.paymentAdviceModalSearchInput);
    });
  };
  handleChangeRowsPerPageForInvoice = (event) => {
    this.setState(
      { rowsPerPageForInvoice: +event.target.value, pageForInvoice: 0 },
      () => {
        this.getSellerInvoiceDataApi(this.state.paymentAdviceModalSearchInput);
      }
    );
  };
  // table serach
  arcPaymentAdviceModalSearch = (event) => {
    this.setState(
      {
        paymentAdviceModalSearchInput: event.target.value,
        pageForInvoice: 0,
        rowsPerPageForInvoice: 25,
      },
      () => {
        this.getSellerInvoiceDataApi(this.state.paymentAdviceModalSearchInput);
      }
    );
  };

  //select Collection
  handleChangePageForCollection = (event, newPage) => {
    this.setState({ pageForCollection: newPage }, () => {
      this.getAllSellerCollectionData(
        this.state.paymentAdviceModalSearchInputCollection
      );
    });
  };
  handleChangeRowsPerPageForCollection = (event) => {
    this.setState(
      { rowsPerPageForCollection: +event.target.value, pageForCollection: 0 },
      () => {
        this.getAllSellerCollectionData(
          this.state.paymentAdviceModalSearchInputCollection
        );
      }
    );
  };
  // table serach
  arcPaymentAdviceModalCollectionSearch = (event) => {
    this.setState(
      {
        paymentAdviceModalSearchInputCollection: event.target.value,
        pageForCollection: 0,
        rowsPerPageForCollection: 25,
      },
      () => {
        this.getAllSellerCollectionData(
          this.state.paymentAdviceModalSearchInputCollection
        );
      }
    );
  };

  // payment advice get api
  paymentAdviceGetAPi = (event, serachIn) => {
    axios
      .post(`/arc/sellerpaymentAdvice`, {
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page,
        search_text: serachIn,
        seller_code: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          allArcPaymentAdviceData: res.data.records,
          OriginalAllArcPaymentAdviceData: res.data.records,
          rowCount: res?.data?.row_count,
          loadingPaymentAdvice: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller invoice get api function
  getSellerInvoiceDataApi = (serachIn) => {
    axios
      .post(`/arc/sellerInvoice`, {
        number_of_rows_per_page: this.state.rowsPerPageForInvoice,
        page_number: this.state.pageForInvoice,
        search_text: serachIn,
        seller_code: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          invoiceApiDataForPaymentAdviceModal: res.data.records,
          invoiceApiSearchDataForPaymentAdviceModal: res.data.records,
          rowCountForInvoice: res?.data?.row_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller collection get api function
  getAllSellerCollectionData = (serachIn) => {
    axios
      .post(`/arc/sellerCollection`, {
        number_of_rows_per_page: this.state.rowsPerPageForCollection,
        page_number: this.state.pageForCollection,
        search_text: serachIn,
        seller_code: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          allArcCollectionData: res.data.records,
          OriginalAllArcCollectionData: res.data.records,
          rowCountForCollection: res?.data?.row_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSellerPaymentAdviceLastUploadDate = () => {
    const customer = "SellerPaymentAdvice";
    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/${customer}`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = dateFormatWithDash(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDate: Date,
          lastUploadTime: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApiForClientID = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        res.data.record
          .filter((v) => v.source_system === "ZOHO")
          .map((value) =>
            localStorage.setItem("Client_ID", value.connection_info.client_id)
          );
        res?.data?.record.filter((v) => {
          if (v.source_system === "Effitrac" || v.source_system === "Tally") {
            localStorage.setItem("Trigger_Effitrac/Tally_Button", "true");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${8}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && this.setState({ grantToken: code });

    let drawer = domain.get("drawer");
    drawer &&
      this.setState({
        openZohoDrawer: true,
        messageForConnection: "Connection Established",
        buttonColor: "green",
      });

    let url = window.location;
    this.setState({ url: url.origin });
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);

    this.getApiForClientID();
    this.paymentAdviceGetAPi();
    this.getSellerInvoiceDataApi();
    this.getAllSellerCollectionData();
    this.getSellerPaymentAdviceLastUploadDate();
    this.getHistoryTableUploadData();
  }

  componentDidUpdate() {
    if (
      this.state.buttonColor === "green" &&
      !this.state.flagForZohoInSalesPayment
    ) {
      this.setState({ flagForZohoInSalesPayment: true });
      variableForTimerInSalesPayment = setInterval(() => {
        this.setState((prevstate) => ({
          timerForZohoInSalesPayment: prevstate.timerForZohoInSalesPayment - 1,
        }));
      }, 1000);
    }

    if (this.state.timerForZohoInSalesPayment === 0) {
      clearInterval(variableForTimerInSalesPayment);
      this.setState({
        flagForZohoInSalesPayment: false,
        timerForZohoInSalesPayment: 10,
        buttonColor: "red",
      });
    }
  }

  // arc collection table header[customer table data in modal]---
  collectionModal_TableHeader() {
    const tableHeader = [
      "customer Name",
      "gstin",
      "collection ref no",
      "select",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc collection table body[customer table data in modal]---
  collectionModal_tableBody() {
    const addTransaction = (collection_ref_no) => {
      this.setState((prevState) => ({
        ...prevState,
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          transactionRefNo: collection_ref_no,
        },
        collectionRefNoModalOpen: false,
        collectionRefNoModalBackDrop: false,
      }));
    };

    if (this.state.allArcCollectionData.length >= 1) {
      return this.state.allArcCollectionData.map((tableData, index) => {
        const { cust_gstin, cust_name, collection_ref_no } = tableData; //destructuring
        return (
          <tr key={index}>
            <td> {cust_name}</td>
            <td>{cust_gstin}</td>
            <td>{collection_ref_no}</td>
            <td>
              <AddCircleOutlineIcon
                onClick={() => addTransaction(collection_ref_no)}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found! Please add collection for selected GSTIN
          </td>
        </tr>
      );
    }
  }

  // arc paymentAdvice table header[customer table data in modal]---
  paymentAdviceModal_TableHeader() {
    const tableHeader = ["gstin", "invoice number", "Invoice date", "select"];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // arc paymentAdvice table body[customer table data in modal]---
  paymentAdviceModal_tableBody() {
    const addPaymentAdvice = (
      invoice_no,
      invoice_date,
      cust_gstin,
      cust_code
    ) => {
      let searchOutput = this.state.OriginalAllArcCollectionData.filter(
        (value) => {
          const query = cust_gstin.toLowerCase();
          return String(value.cust_gstin).toLowerCase().indexOf(query) >= 0;
        }
      );
      this.setState((prevState) => ({
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          gstin: cust_gstin,
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          customerCode: cust_code,
        },
        allArcCollectionData: searchOutput,
        paymentAdviceModalOpen: false,
        paymentAdviceModalBackDrop: false,
        paymentAdviceModalSearchInput: "",
      }));

      this.getSellerInvoiceDataApi();
    };

    if (this.state.invoiceApiDataForPaymentAdviceModal.length >= 1) {
      return this.state.invoiceApiDataForPaymentAdviceModal.map(
        (tableData, index) => {
          const { invoice_no, invoice_date, cust_gstin, cust_code } = tableData; //destructuring
          return (
            <tr key={index}>
              <td>{cust_gstin}</td>
              <td> {invoice_no}</td>
              <td>{invoice_date}</td>
              <td>
                <AddCircleOutlineIcon
                  onClick={() =>
                    addPaymentAdvice(
                      invoice_no,
                      invoice_date,
                      cust_gstin,
                      cust_code
                    )
                  }
                />
              </td>
            </tr>
          );
        }
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ------------------------------------------ARC invoice modal[ customer table] end---------------------------

  //-----------------Payment advice table search----------------
  handleSearchInputChangeForZohoEffitrack = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  //excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = (dataFromFun) => {
    let data = {};
    if (this.state.openZohoDrawer) {
      data = dataFromFun
    } else {
      data = {business_entity_id: localStorage.getItem("business_entity_id")}
    }
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Seller Payment Advice.xlsx`;

    axios
      .post("/seller_payment_advice_extract/download", {
        data
        // business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;

    let searchAdminData = this.state.oldEffitracData.filter((value) => {
      const query = searchInput.trim().toLowerCase();
      return (
        String(value.cust_gstin).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_no).toLowerCase().indexOf(query) >= 0 ||
        String(value.cust_code).toLowerCase().indexOf(query) >= 0 ||
        String(value.transacation_ref_no).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoice_no).indexOf(query) >= 0 ||
        String(value.invoice_date).indexOf(query) >= 0 ||
        String(value.payment_date).indexOf(query) >= 0 ||
        String(numberFormat(value.amount_paid)).toLowerCase().indexOf(query) >=
        0 ||
        String(value.amount_paid).toLowerCase().indexOf(query) >= 0 ||
        String(value.tds_amount).toLowerCase().indexOf(query) >= 0 ||
        String(numberFormat(value.tds_amount)).toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ effitracData: searchAdminData });
  };

  // ------------------------Payment advice file uplpad-----------------------
  fileUploader = (event, serachIn) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    let dis = this;
    const current_date = new Date();
    const pad = "00";
    const yy = current_date.getFullYear().toString();
    const mm = (current_date.getMonth() + 1).toString();
    const dd = current_date.getDate().toString();

    var currentDateForApiCall = `${yy}-${(pad + mm).slice(-2)}-${(
      pad + dd
    ).slice(-2)}`;

    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`arc/excelupload/sellerpayment`, {
          file: file_value,
          business_entity_id: parseInt(
            localStorage.getItem("business_entity_id")
          ),
        })
        .then((data) => {
          let res = data.data;
          dis.getHistoryTableUploadData();
          dis.setState({ loading: false });

          if (res.summary.invalid_records === 0) {
            axios
              .get(
                `ageing/generateAging/${currentDateForApiCall}/${localStorage.getItem(
                  "business_entity_id"
                )}`
              )
              .then((res) => {
                if (typeof res.data !== "undefined") {
                } else {
                }
              });
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    };
  };

  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.getAllSellerCollectionData();
    this.paymentAdviceGetAPi();
  };

  CollectionListActions = () => (
    <div>
      <a
        href={SellerPaymentAdvice}
        download={"seller_payment_advice.xlsx"}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: "20px" }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: "20px" }} />
        UPLOAD TEMPLATE{" "}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>

      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: "right" }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: "20px" }} />
        Refresh
      </Button>
    </div>
  );

  // ------------------------Payment advice table-----------------------
  arc_paymentAdvice_TableHeader() {
    const tableHeader = [
      "ERP Customer Code",
      "Customer Name",
      "Customer GSTIN",
      "Amount Paid",
      "Payment Date",
      "Show",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  arc_PaymentAdvice__data() {
    const showToggleDrawer = (
      cust_code,
      cust_name,
      cust_gstin,
      create_date,
      created_date,
      cust_pan,
      modify_date,
      modified_date,
      transacation_ref_no
    ) => {
      this.setState({
        showPaymentDetailsData: {
          cust_code: cust_code,
          cust_name: cust_name,
          cust_gstin: cust_gstin,
          create_date: create_date,
          created_date: created_date,
          cust_pan: cust_pan,
          modify_date: modify_date,
          modified_date: modified_date,
          transacation_ref_no: transacation_ref_no
        },
        showDrawer: true,
      });
    };

    const editPaymentAdvice = (
      cust_code,
      cust_gstin,
      invoice_no,
      invoice_date,
      amount_paid,
      payment_date,
      transacation_ref_no,
      tds_amount
    ) => {
      let invoiceDate = dateFormat(invoice_date);
      // let paymentDate = dateFormat(invoice_date);
      let paymentDate = "";
      this.setState({
        editPaymentAdviceData: {
          customerCode: cust_code,
          gstin: cust_gstin,
          invoiceNo: invoice_no,
          invoiceDate: invoice_date,
          amountPaid: amount_paid,
          paymentDate: payment_date,
          transactionRefNo: transacation_ref_no,
          tdsAmount: tds_amount,
        },
        edit_DrawerOpen: true,
        paymentDateDisplay: paymentDate,
        invoiceDateDisplay: invoiceDate,
      });
    };

    if (this.state.allArcPaymentAdviceData.length >= 1) {
      return this.state.allArcPaymentAdviceData.map((tableData, index) => {
        const {
          cust_code,
          cust_name,
          cust_gstin,
          create_date,
          created_date,
          modify_date,
          modified_date,
          cust_pan,
          payment_date,
          transacation_ref_no,
          amount_paid
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td> {cust_code}</td>
            <td>{cust_name}</td>
            <td>{cust_gstin}</td>
            <td>{numberFormat(amount_paid)}</td>
            <td>{payment_date}</td>
            <td>
              <RemoveRedEyeOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() =>
                  showToggleDrawer(
                    cust_code,
                    cust_name,
                    cust_gstin,
                    create_date,
                    created_date,
                    cust_pan,
                    modify_date,
                    modified_date,
                    transacation_ref_no
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else if (this.state.loadingPaymentAdvice) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={8}
          >
            Loading... Please wait
          </td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  // add Payment Advice [add button] drawer open handeler---
  addPaymentAdvice_drawerOpen = () => {
    this.setState({ add_DrawerOpen: true });
  };

  // invoice date handler
  handleInvoiceDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          invoiceDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        invoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // Payment date handler
  handlePaymentDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          paymentDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        paymentDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // add Payment Advice input field handler[number format]
  addPaymentAdvice_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      addPaymentAdviceData: {
        ...prevState.addPaymentAdviceData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add Payment Advicey inputfield handler---
  addPaymentAdvice_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      addPaymentAdviceData: {
        ...prevState.addPaymentAdviceData,
        [name]: String(value),
      },
    }));
  };

  // add Payment Advice add submit handler
  addPaymentAdvice_submitHandler = () => {
    toast.configure();

    axios
      .post(`/arc/ui/sellerpaymentadvice`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.addPaymentAdviceData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.paymentAdviceGetAPi();
          this.setState({
            add_DrawerOpen: false,
            addPaymentAdviceData: {},
            invoiceDateDisplay: "",
            paymentDateDisplay: "",
          });
          toast.success("Payment advice added successfully", {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add Payment Advice drawer close handler---
  addPaymentAdvice_DrawerClose = () => {
    this.setState({ add_DrawerOpen: false, addPaymentAdviceData: {} });
  };

  // edit Payment Advice [edit button] drawer open handeler---

  editPaymentAdvice_drawerOpen = () => {
    this.setState({ edit_DrawerOpen: true });
  };

  // invoice date handler
  editInvoiceDateChangeHandler = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editPaymentAdviceData: {
          ...prevState.editPaymentAdviceData,
          invoiceDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        invoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // Payment date handler
  editPaymentDateChangeHandler = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();

      this.setState((prevState) => ({
        editPaymentAdviceData: {
          ...prevState.editPaymentAdviceData,
          paymentDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
        },
        paymentDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // edit Payment Advice input field handler[number format]
  editPaymentAdvice_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      editPaymentAdviceData: {
        ...prevState.editPaymentAdviceData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit Payment Advicey inputfield handler---
  editPaymentAdvice_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editPaymentAdviceData: {
        ...prevState.editPaymentAdviceData,
        [name]: String(value),
      },
    }));
  };

  // edit Payment Advice edit submit handler
  editPaymentAdvice_submitHandler = () => {
    toast.configure();
    axios
      .post(`/arc/ui/sellerpaymentadvice`, {
        entityGst: localStorage.getItem("gst"),
        records: [this.state.editPaymentAdviceData],
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.paymentAdviceGetAPi();
          this.setState({
            edit_DrawerOpen: false,
          });
          toast.success("Payment updated successfully", { autoClose: 2000 });
        }
      })
      .catch((error) => {
        this.setState({
          edit_DrawerOpen: false,
        });
      });
  };

  // edit Payment Advice drawer close handler---
  editPaymentAdvice_DrawerClose = () => {
    this.setState({ edit_DrawerOpen: false, editPaymentAdviceData: {} });
  };

  // ------------------------Payment advice edit end-------------------------
  closeUploadData = () => {
    this.setState({ showUploadData: false });
    this.paymentAdviceGetAPi();
  };

  showToggleDrawerClose = () => {
    this.setState({ showDrawer: false });
  };

  // add payment modal
  addPaymentAdviceModalOpen = () => {
    this.setState({
      paymentAdviceModalOpen: true,
      paymentAdviceModalBackDrop: true,
    });
  };

  addPaymentAdviceModalClose = () => {
    this.getSellerInvoiceDataApi();
    this.setState({
      paymentAdviceModalOpen: false,
      paymentAdviceModalBackDrop: false,
      paymentAdviceModalSearchInput: "",
    });
  };

  // collection ref no modal
  addCollectonRefNoModalOpen = () => {
    this.setState({
      collectionRefNoModalOpen: true,
      paymentAdviceModalBackDrop: true,
    });
  };

  addCollectonRefNoModalClose = () => {
    this.setState({
      collectionRefNoModalOpen: false,
      paymentAdviceModalBackDrop: false,
    });
  };

  // import effitrac / tally form section
  importEffitracTallyOpenDrawer = () => {
    this.setState({ openImportEffitracDrawer: true });
  };

  importFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        invoiceFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        invoiceToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  importModifiedFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  fetchEffitacFormSubmitHandler = () => {
    toast.configure();
    this.setState({ effitracFormSubmitLoader: true });
    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      },
    }));
    const data = this.state.effitracFetchFromData;
    axios
      .post("/arc/effitrac/paymentreport", data)
      .then((res) => {
        this.setState({
          isEffitrac: true,
          effitracData: res.data.records.records,
          oldEffitracData: res.data.records.records,
          importEffitracFetchData: {},
          openImportEffitracDrawer: false,
          effitracFormSubmitLoader: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.setState({ effitracFormSubmitLoader: false });
      });
  };

  closeEffitracTallyOpenDrawer = () => {
    this.setState({ openImportEffitracDrawer: false });
  };

  // import to cws button onclick--
  importToCWS = (dataFromFun) => {
    toast.configure();
    axios
      .post("/arc/effitrac/sellerpayment", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        records: dataFromFun.map((row, index) => {
          let newRow = { ...row };
          newRow["invoiceDate"] = dateFormatWithDash(newRow["invoiceDate"]);
          newRow["paymentDate"] = dateFormatWithDash(newRow["paymentDate"]);
          return newRow;
        }),
      })
      .then((res) => {
        this.setState({ showUploadHistroyTable: true });
        this.refreshUploadHistoryTable();
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });

        //Edited by harsh Json Formate had been change Status Code: 204 NO CONTENT
        // res.data.record.details.map((row, index) => {
        //   if ("status" in row && row["status"] === "Fail") {
        //     row["record"]["InvoiceNo"] =
        //       "invoiceNo" in row["record"] ? row["record"]["invoiceNo"] : "";
        //     row["record"]["GSTIN"] =
        //       "gstin" in row["record"] ? row["record"]["gstin"] : "";
        //     return row;
        //   }
        //   return false;
        // });
        // var error_records = [];
        // let promises = res.data.record.details.map((v, i) => {
        //   if (v.status === "Fail") {
        //     error_records.push(v);
        //   }
        //   return true;
        // });
        // Promise.all(promises).then(() => {
        //   dis.setState({
        //     total_records: parseInt(res.data.record.summary.total_records),

        //     successful_records: parseInt(res.data.record.summary.valid_records),
        //     error_records: parseInt(res.data.record.summary.invalid_records),
        //     sellerCustomerData: error_records,
        //     error: null,
        //     showUploadData: true,
        //     loading: false,
        //   });
        // });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.messge, {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  // back button after import effitrac data
  backHandler = () => {
    this.setState({ isEffitrac: false, showUploadHistroyTable: false });
  };

  handleZohoDrawer = () => {
    flagForPayment = 1;
    this.setState({ openZohoDrawer: true });
  };

  closeZohoDrawer = () => {
    clearInterval(variableForTimerInSalesPayment);
    this.setState({
      zohoDate: {},
      openZohoDrawer: false,
      zohoFetchDate: {},
      buttonColor: "red",
      messageForConnection: "Establish Connection",
      grantToken: "",
    });
  };

  zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    var d = new Date(value);
    var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    let dateValue = newd.toISOString().replace("Z", "");

    if (name === "created_from_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else if (name === "created_to_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else if (name === "modified_from_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    }
  };

  fetchingdataForZoho = () => {
    clearInterval(variableForTimerInSalesPayment);
    flagForPayment += 1;
    toast.configure();

    if (flagForPayment === 2) {
      this.setState({ effitracImportFormloader: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: this.state.grantToken,
          type_of_data: "seller_payment_advice",
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            this.setState({
              effitracImportFormloader: false,
              zohoDate: {},
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
            });
          } else {
            this.setState({
              effitracImportFormloader: false,
              zohoDate: {},
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          this.setState({
            effitracImportFormloader: false,
            zohoDate: {},
            buttonColor: "red",
            messageForConnection: "Establish Connection",
            grantToken: "",
          });
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };

  establishConnection = (e) => {
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${this.state.url
      }/zohoTesting&access_type=offline&prompt=Consent`;
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        this.setState({
          showUploadData: true,
          uploadStatusFail: response.data.records,
        });
        let res = response.data.records.records;
        if (response.data.records.type === "json") {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === "Fail") {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),

              sellerCustomerData: error_records,
              error: null,
              loading: false,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: this.state.uploadStatusFail,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `PaymentAdviceUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    // const { push, ...props } = this.props;
    return (
      <React.Fragment>
        <ZohoModel
          openZohoDrawer={this.state.openZohoDrawer}
          buttonColor={this.state.buttonColor}
          establishConnection={this.establishConnection}
          messageForConnection={this.state.messageForConnection}
          timerForZohoInSalesCustomer={this.state.timerForZohoInSalesPayment}
          closeZohoDrawer={this.closeZohoDrawer}
          zohoDateChangeHandler={this.zohoDateChangeHandler}
          effitracImportFormloader={this.state.effitracImportFormloader}
          fetchingdataForZoho={this.fetchingdataForZoho}
          zohoDate={this.state.zohoDate}
          logApitName="seller_payment_advice"
          nameOfTheModel="Payment Advice Data"
          importToCWS={this.importToCWS}
          exportToExcel={this.export_to_excel}
          zohoTableHeader={this.state.effitracHeader}
          zohoTableColumn={this.state.effitracCol}
          drawerKeys={[]}
        />

        {/*......................Edited By Harsh.....................*/}
        {/* import Zoho drawer */}
        {/* <Drawer
          anchor="right"
          open={this.state.openZohoDrawer}
          // onClose={this.closeEffitracDrawer}
        >
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              className="button-outlined-primary"
              disabled={this.state.buttonColor === "green" ? true : false}
              onClick={this.establishConnection}
            >
              {this.state.messageForConnection}
            </button>
          </div>
          {this.state.buttonColor === "green" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  color:
                    this.state.timerForZohoInSalesPayment < 30
                      ? "red"
                      : "green",
                }}
              >
                Connection will be lost In{"  "}
                {this.state.timerForZohoInSalesPayment} Seconds
              </span>
            </div>
          )}

          <ValidatorForm
            ref="form"
            onSubmit={this.fetchingdataForZoho}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "10px" }}>
              <label>
                Create From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="created_from_date"
                value={this.state.zohoDate.created_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "created_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

              <div style={{ marginTop: '20px' }}>
                <label>
                  Modified From Date<span style={{ color: 'red' }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="modified_from_date"
                  value={this.state.zohoDate.modified_from_date ?? ''}
                  onChange={(v) =>
                    this.zohoDateChangeHandler({
                      name: 'modified_from_date',
                      value: v,
                    })
                  }
                  requiredField="*"
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
              </div>
              {this.state.effitracImportFormloader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}

              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeZohoDrawer}
              >
                cancel
              </Button>
            </ValidatorForm>
          </div>
        </Drawer> */}
        {/* import tally/effitrac end */}

        {/* --------import effitrac/tally payment advice section */}
        {/* import debit effitrac/tally */}
        <RightDrawer
          drawerName="Import Effitrac/Tally"
          open={this.state.openImportEffitracDrawer}
          onClose={this.closeEffitracTallyOpenDrawer}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.fetchEffitacFormSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ textAlign: "center" }}>
              Last Upload Date<br></br>
              {this.state.lastUploadDate} {this.state.lastUploadTime}
            </div>
            <div style={{ marginTop: "20px" }}>
              <label>From Date:</label>
              <ValidatedDatePicker
                fullWidth
                name="fromDate"
                value={this.state.effitracFetchFromData.invoiceFromDate ?? ""}
                onChange={(v) => this.importFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>To Date:</label>
              <ValidatedDatePicker
                name="toDate"
                value={this.state.effitracFetchFromData.invoiceToDate ?? ""}
                onChange={(v) => this.importToDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified From Date:</label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={this.state.effitracFetchFromData.modifiedFromDate ?? ""}
                onChange={(v) => this.importModifiedFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified To Date:</label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.effitracFetchFromData.modifiedToDate ?? ""}
                onChange={(v) => this.importModifiedToDateChangeHandler(v)}
              />
            </div>

            {/* submit and back button */}
            <div className="mt-20">
              {this.state.effitracFormSubmitLoader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeEffitracTallyOpenDrawer}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* -----------------add payment advicemodal--------------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.paymentAdviceModalOpen}
          onHide={this.addPaymentAdviceModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.paymentAdviceModalSearchInput ?? ""}
                      textOnchangeFunction={this.arcPaymentAdviceModalSearch}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.paymentAdviceModal_TableHeader()}</tr>
                  {this.paymentAdviceModal_tableBody()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={this.state.rowCountForInvoice} //total row count
              rowsPerPage={this.state.rowsPerPageForInvoice}
              page={this.state.pageForInvoice}
              onPageChange={this.handleChangePageForInvoice}
              onRowsPerPageChange={this.handleChangeRowsPerPageForInvoice}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.addPaymentAdviceModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        {/* add payment advice modal end  */}

        {/* -----------------add collection modal--------------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.collectionRefNoModalOpen}
          onHide={this.addCollectonRefNoModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Any Collection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={
                        this.state.paymentAdviceModalSearchInputCollection ?? ""
                      }
                      textOnchangeFunction={
                        this.arcPaymentAdviceModalCollectionSearch
                      }
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.collectionModal_TableHeader()}</tr>
                  {this.collectionModal_tableBody()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={this.state.rowCountForCollection} //total row count
              // count={this.state.allArcCollectionData.length}
              rowsPerPage={this.state.rowsPerPageForCollection}
              page={this.state.pageForCollection}
              onPageChange={this.handleChangePageForCollection}
              onRowsPerPageChange={this.handleChangeRowsPerPageForCollection}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.addCollectonRefNoModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* upload temp Button */}

        <div>
          <ToggleSwitch
            onChange={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
            value={this.state.showUploadHistroyTable}
            label="Upload Payment Advice"
          />
        </div>

        {/* File upload history table  */}
        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper
              boxTitle="Upload History"
              zohoEffitracEnable={
                localStorage.getItem("Client_ID") &&
                !this.state.isEffitrac &&
                true
              }
              zohoEffitracFn={this.handleZohoDrawer}
              enableImportToEffTallybutton={
                localStorage.getItem("Trigger_Effitrac/Tally_Button") &&
                localStorage.getItem("Trigger_Effitrac/Tally_Button") ===
                "true" &&
                !this.state.isEffitrac &&
                true
              }
              importToEffiTallyFn={this.importEffitracTallyOpenDrawer}
            >
              <div style={{ marginBottom: "10px" }}>
                {" "}
                <this.CollectionListActions />
              </div>

              <div className="responsive-table">
                <table className="custome-table">
                  <thead>
                    <tr>
                      <th>Last Upload Date & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === "In Progress" ? (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button-disabled "
                                color="disabled"
                              />
                            ) : item.status === "Uploaded" ? (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button-disabled "
                                color="disabled"
                              />
                            ) : (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button showIcon"
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: "red", textAlign: "center" }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: "none",
                          marginBottom: "0px",
                          padding: "0px",
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li style={{ fontWeight: "700" }}>Upload Details</li>
                        <li>
                          {this.state.error_records > 0 && (
                            <button
                              className="button-outlined-primary"
                              onClick={this.errorLogExportHandlerForUpload}
                            >
                              Export
                            </button>
                          )}

                          <button
                            className="close-icon-button"
                            onClick={this.closeUploadData}
                          >
                            <ClearIcon style={{ color: "#d04646" }} />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: "300",
                            margin: "20px 30px 0px 30px",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                  // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: "red" }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        {/* table  */}

        <BoxWrapper
          boxTitle="Payment Advice Details"
          // export to excel
          exportToExcelEnable={true}
          exportToExcelFn={() =>
            this.export_to_excel(this.state.allArcPaymentAdviceData)
          }
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={
            this.state.isEffitrac
              ? this.handleSearchInputChangeForZohoEffitrack
              : this.handleSearchInputChange
          }
        >
          {this.state.isEffitrac ? (
            <CustomTable
              headers={this.state.effitracHeader}
              columns={this.state.effitracCol}
              data={this.state.effitracData}
              actions={this.state.actions}
            />
          ) : (
            <>
              <div className="responsive-table">
                <table className="custome-table">
                  <tbody>
                    <tr>{this.arc_paymentAdvice_TableHeader()}</tr>
                    {this.arc_PaymentAdvice__data()}
                  </tbody>
                </table>
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCount} //total row count
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </BoxWrapper>

        {/* show data */}
        <RightDrawer
          drawerName="Payment Advice Details"
          open={this.state.showDrawer}
          onClose={this.showToggleDrawerClose}
        >
          {Object.keys(this.state.showPaymentDetailsData).length && (
            <>
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Customer Name"
                value={this.state.showPaymentDetailsData.cust_name ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="transacation Ref No"
                value={
                  this.state.showPaymentDetailsData.transacation_ref_no ?? ""
                }
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Customer Pan"
                value={
                  this.state.showPaymentDetailsData.cust_pan ??
                  ""
                }
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Customer Gstin"
                value={this.state.showPaymentDetailsData.cust_gstin ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="ERP Customer Code"
                value={this.state.showPaymentDetailsData.cust_code ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Create Date"
                value={this.state.showPaymentDetailsData.created_date ?? ""}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Modify Date"
                value={this.state.showPaymentDetailsData.modified_date ?? ""}
              />
            </>
          )}
          <button
            className="button-outlined-secondary mt-20"
            type="button"
            onClick={this.showToggleDrawerClose}
          >
            close
          </button>
        </RightDrawer>
        {/* table end */}
        {/* add payment */}
        <Drawer
          anchor="right"
          open={this.state.add_DrawerOpen}
          variant={
            this.state.paymentAdviceModalBackDrop === true ? "persistent" : ""
          }
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addPaymentAdvice_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              {/* modal open button */}
              <Button
                style={{ marginTop: "45px" }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.addPaymentAdviceModalOpen}
              >
                select invoice*
              </Button>
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                // requiredField="*"
                // validator={["required"]}
                // errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.customerCode ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.gstin ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.invoiceNo ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.invoiceDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  ₹ Amount Paid<span style={{ color: "red" }}>*</span>:
                </label>{" "}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.addPaymentAdviceData.amountPaid
                      ? this.state.addPaymentAdviceData.amountPaid
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addPaymentAdviceData: {
                        ...prevState.addPaymentAdviceData,
                        amountPaid: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>
                  Payment Date<span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="paymentDate"
                  value={this.state.paymentDateDisplay ?? ""}
                  onChange={(v) => this.handlePaymentDateChange(v)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>

              {/* modal open button */}
              <Button
                style={{ marginTop: "45px" }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.addCollectonRefNoModalOpen}
              >
                select collection*
              </Button>

              <InputField
                inputType="text"
                textLabel="Transaction Ref No"
                name="transactionRefNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.transactionRefNo ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ TDS Amount</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.addPaymentAdviceData.tdsAmount
                      ? this.state.addPaymentAdviceData.tdsAmount
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addPaymentAdviceData: {
                        ...prevState.addPaymentAdviceData,
                        tdsAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.addPaymentAdvice_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* add payment end */}
        {/* edit payment */}
        <Drawer
          anchor="right"
          open={this.state.edit_DrawerOpen}
          onClose={this.editPaymentAdvice_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.editPaymentAdvice_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <InputField
                inputType="text"
                textLabel="Customer Code"
                name="customerCode"
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editPaymentAdviceData.customerCode ?? ""}
              />
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="gstin"
                requiredField="*"
                validator={["required"]}
                inputProps={{
                  readOnly: true,
                }}
                errorMsg={["This field is required", "Invalid GST Number"]}
                value={this.state.editPaymentAdviceData.gstin ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Invoice No"
                name="invoiceNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editPaymentAdviceData.invoiceNo ?? ""}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editPaymentAdviceData.invoiceDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ Amount Paid</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editPaymentAdviceData.amountPaid
                      ? this.state.editPaymentAdviceData.amountPaid
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editPaymentAdviceData: {
                        ...prevState.editPaymentAdviceData,
                        amountPaid: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              <InputField
                inputType="text"
                textLabel=" Payment Date"
                name="paymentDate"
                requiredField="*"
                validators={["required"]}
                errorMessages={["Date is required"]}
                value={this.state.editPaymentAdviceData.paymentDate ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Transaction Ref No"
                name="transactionRefNo"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                inputProps={{
                  readOnly: true,
                }}
                value={this.state.editPaymentAdviceData.transactionRefNo ?? ""}
                textOnchangeFunction={this.editPaymentAdvice_inputHandler}
              />

              <div style={{ marginTop: "20px" }}>
                <label>₹ TDS Amount</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.editPaymentAdviceData.tdsAmount
                      ? this.state.editPaymentAdviceData.tdsAmount
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editPaymentAdviceData: {
                        ...prevState.editPaymentAdviceData,
                        tdsAmount: parseFloat(value),
                      },
                    }));
                  }}
                />
              </div>

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.editPaymentAdvice_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* edit payment end */}
      </React.Fragment>
    );
  }
}

export default PaymentAdvice;

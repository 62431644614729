import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, Drawer, TextField } from "@material-ui/core";
import BoxWrapper from "../../../../../components/ui/boxWrapper";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "../../../../../axios-order";
import AddInvoiceForm from "./addInvoice";
import ShowDetails from "./showInvoice";
import EditInvoiceForm from "./editInvoice";
import ZohoShow from "./ZohoShow";
import Card from "../../../../../components/ui/Cards";
import CircularProgress from "@material-ui/core/CircularProgress";
import VendorInvoice from "../../../../../asserts/templates/vendor_bill.xlsx";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "./invoiceMgmt.css";
import ValidatedDatePicker from "../../../../../components/ui/DatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { TablePagination } from "@material-ui/core";
import RefreshIcon from "@mui/icons-material/Refresh";

import ToggleSwitch from "../../../../../components/ui/ToggleSwitch/ToggleSwitch";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../../components/ui/RightDrawer";
import ZohoModel from "../../../../../components/ZohoModel/ZohoModel";

let flagForInvoiceMgn = 1;

function InvoiceMgmt(props) {
  const [openAddFormDrawer, setOpenAddFormDrawer] = useState(false);
  const [openShowDrawer, setOpenShowDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [uploadSection, setUploadSection] = useState(false);

  const [uploadResData, setUploadResData] = useState();
  const [uploadErrorMsg, setUploadErrorMsg] = useState();
  const onCickOperation = true;

  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState({});
  const [showData, setShowData] = useState({});

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [tableSearch, setTableSearch] = useState("");

  const [stateForPage, setStateForPage] = useState(false);
  const [stateForRowPerPage, setStateForRowPerPage] = useState(false);
  const [stateTableSearch, setStateTableSearch] = useState(false);

  const [loading, setLoading] = useState(true);

  const Header = [
    { label: "Vendor Code+" },
    { label: "Invoice Number" },
    { label: "Invoice Date" },
    { label: "Booking Date" },
  ];

  const Column = [
    {
      key: "erpVendorCode",
      render: (index, row) => {
        return row["erpVendorCode"];
      },
    },
    {
      key: "invoiceNumber",
      render: (index, row) => {
        return row["invoiceNumber"];
      },
    },
    {
      key: "invoiceDate",
      render: (index, row) => {
        return row["invoiceDate"];
      },
    },
    {
      key: "bookingDate",
      render: (index, row) => {
        return row["bookingDate"];
      },
    },
  ];

  const actionHeaderForZoho = [
    { id: "erpVendorCode", name: "ERP Vendor Code+" },
    { id: "vendorPan", name: "PAN" },
    { id: "GSTTaxRate", name: "GST Tax %" },
    { id: "eInvoice", name: "E-Invoice" },
    { id: "dueDate", name: "Due Date" },
    { id: "hsnSacCode", name: "HSN/SAC Code" },
    { id: "taxableValue", name: "Taxable Value" },
    { id: "nonTaxableValue", name: "Non Taxable Value" },
    { id: "sgst", name: "SGST" },
    { id: "cgst", name: "CGST" },
    { id: "igst", name: "IGST" },
    { id: "cess", name: "CESS" },
    { id: "taxAmount", name: "Tax Amount" },
    { id: "tcsSection", name: "TCS Section" },
    { id: "tcsPayable", name: "TCS Payable" },
    { id: "totalAmount", name: "Total Amount" },
    { id: "tdsSection", name: "TDS Section" },
    { id: "tdsPayable", name: "TDS Payables" },
    { id: "expenseType", name: "Expense Type" },
    { id: "taxCreditType", name: "Type of tax credit" },
    { id: "itcEligibility", name: "ITC Eligibility" },
    { id: "rcmApplicability", name: "RCM Applicability" },
    { id: "poNumber", name: "PO Number" },
    { id: "poDate", name: "PO Date" },
    { id: "dispute", name: "Dispute" },
    { id: "disputeValue", name: "Dispute Value" },
    { id: "boeNumber", name: "Bill of Entry Number" },
    { id: "boeDate", name: "Bill of Entry Date" },
    { id: "referenceDate", name: "Reference Date" },
    { id: "portCode", name: "Port Code" },
    { id: "comments", name: "Comments" },
    { id: "fetrack", name: "Fetrack" },
  ];

  //...............Zoho...........................
  const [zohoTrigger, setZohoTrigger] = useState({
    messageForConnection: "Establish Connection",
    buttonColor: "red",
    zohoShowButton: false,
  });
  const [url, setUrl] = useState("");
  const [grandCode, setGrandCode] = useState("");
  const [zohoDate, setZohoDate] = useState({});
  const [effitracFormSubmitLoader, setEffitracFormSubmitLoader] =
    useState(false);
  const [triggerZohoBackImportCwsButton, setTriggerZohoBackImportCwsButton] =
    useState({
      // zohoEffitrack: true,
      // importToCws: false,
      backButton: false,
    });
  const [tableForZoho, setTableForZoho] = useState([]);
  const [tableForZohoSearch, setTableForZohoSearch] = useState([]);
  const [showForZoho, setShowForZoho] = useState({});
  const [showForZohoButton, setShowForZohoButton] = useState(false);
  const [uploadHistoryTableData, setUploadHistoryTableData] = useState([]);
  const [showUploadHistoryTable, setShowUploadHistoryTable] = useState(false);
  const [timers, setTimers] = useState(60);
  let timerforZoho = useRef();

  const getInvoiceDetails = (serachIn, name) => {
    const finalData = {
      user_id: localStorage.getItem("user_id"),
      number_of_rows_per_page: rowsPerPage,
      page_number: page,
      search_text: serachIn,
      business_entity_id: localStorage.getItem("business_entity_id"),
    };

    axios
      .post(`/get_vendor_invoice_details`, finalData)
      .then((res) => {
        setTableData(res.data.record);
        setRowCount(res?.data?.row_count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // upload history table data api fn call
  const getUploadHistoryDataApi = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${12}`
      )
      .then((res) => {
        setUploadHistoryTableData(res.data.record);
      })
      .catch((error) => {
        console.log(error);
        // alert("Unable to complete your request, Please retry!");
      });
  };

  // table pagination fns
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStateForPage(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setStateForRowPerPage(true);
  };

  // table serach
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  // const SearchInputHandler = debounce((e) => getInvoiceDetails(e.target.value));

  const SearchInputHandler = (e) => {
    let query = e.target.value;
    setTableSearch(query);
    setRowsPerPage(25);
    setPage(0);
    setStateTableSearch(true);
  };

  useEffect(() => {
    // if (stateTableSearch) {
    //   getInvoiceDetails(tableSearch);
    // }
    // setStateTableSearch(false);
    let timer = setTimeout(() => {
      getInvoiceDetails(tableSearch);
    }, 300);

    return () => clearTimeout(timer);
  }, [tableSearch]);

  useEffect(() => {
    if (stateForRowPerPage) {
      getInvoiceDetails(tableSearch);
    }
    setStateForRowPerPage(false);
  }, [rowsPerPage]);

  useEffect(() => {
    if (stateForPage) {
      getInvoiceDetails(tableSearch);
    }
    setStateForPage(false);
  }, [page]);

  useEffect(() => {
    if (zohoTrigger.buttonColor === "green") {
      timerforZoho.current = setInterval(() => {
        setTimers((prevState) => prevState - 1);
      }, 1000);
    }
  }, [zohoTrigger.buttonColor]);

  useEffect(() => {
    if (timers === 0) {
      clearInterval(timerforZoho.current);
      setTimers(60);
      setZohoTrigger({
        ...zohoTrigger,
        buttonColor: "red",
      });
    }
  }, [timers]);

  useEffect(() => {
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && setGrandCode(code);

    let drawer = domain.get("drawer");
    drawer &&
      localStorage.getItem("DrawerForInvoice") === "true" &&
      setZohoTrigger({
        ...zohoTrigger,
        zohoShowButton: true,
        messageForConnection: "Connection Established",
        buttonColor: "green",
      });

    let url = window.location;
    // console.log(url.origin);
    setUrl(url.origin);
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);

    getInvoiceDetails();
    getUploadHistoryDataApi();
  }, []);

  // serach handler
  const searchHandlerFroZoho = (e) => {
    const value = e.target.value;
    let serachDataForZoho = tableForZohoSearch.filter((v) => {
      let valuess = value.trim();
      return (
        String(v.erpVendorCode).toLowerCase().indexOf(valuess.toLowerCase()) >=
          0 ||
        String(v.invoiceNumber).toLowerCase().indexOf(valuess.toLowerCase()) >=
          0 ||
        String(moment(v.invoiceDate).format("DD-MM-YYYY"))
          .toLowerCase()
          .indexOf(valuess.toLowerCase()) >= 0 ||
        String(moment(v.bookingDate).format("DD-MM-YYYY"))
          .toLowerCase()
          .indexOf(valuess.toLowerCase()) >= 0
      );
    });
    setTableForZoho(serachDataForZoho);
  };

  // file upload
  const fileUploader = (event) => {
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    let name = event.target.name;

    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`/vendor/vendorinvoice`, {
          file: file_value,
          entityGst: localStorage.getItem("gst"),
        })
        .then((res) => {
          getUploadHistoryDataApi();
        });
    };
  };

  // upload error response handler
  const closeUploadBoxWapper = () => {
    setUploadSection(false);
    getInvoiceDetails();
  };

  // add handlers
  const addFormHandler = () => {
    setOpenAddFormDrawer(true);
  };

  const closeAddFormHandler = () => {
    setOpenAddFormDrawer(false);
  };

  // edit handlers
  const editDataHandler = (item) => {
    delete item.vendorCode;
    setEditData(item);
    setOpenEditDrawer(true);
  };

  const closeEditFormHandler = () => {
    setOpenEditDrawer(false);
  };

  // show handler
  const showDetailsHandler = (item) => {
    setShowData(item);
    setOpenShowDrawer(true);
  };

  const closeShowDrawer = () => {
    setOpenShowDrawer(false);
  };

  //excel download
  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcel = (data) => {
    let Datas = {};
    if (zohoTrigger.zohoShowButton) {
      Datas =  {
        zoho_data:data,
        business_entity_id: localStorage.getItem("business_entity_id")
      };
    } else {
      Datas = {
        business_entity_id: localStorage.getItem("business_entity_id")
      }
    }
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `Vendor_Bill.xlsx`;
    axios
      .post("/vendor/vendorinvoicedownload", Datas)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    var d = new Date(value);
    var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    let dateValue = newd.toISOString().replace("Z", "");

    if (name === "created_from_date") {
      setZohoDate({
        ...zohoDate,
        [name]: dateValue,
      });
    } else {
      setZohoDate({
        ...zohoDate,
        [name]: dateValue,
      });
    }
  };

  const handleZohoFun = () => {
    flagForInvoiceMgn = 1;
    setZohoTrigger({
      ...zohoTrigger,
      zohoShowButton: true,
    });
    localStorage.setItem("DrawerForInvoice", "true");
  };

  const closeZohoDrawer = () => {
    clearInterval(timerforZoho.current);
    setZohoTrigger({
      ...zohoTrigger,
      messageForConnection: "Establish Connection",
      buttonColor: "red",
      zohoShowButton: false,
    });
    setZohoDate({});
    setEffitracFormSubmitLoader(false);
    localStorage.setItem("DrawerForInvoice", "false");
  };
  const establishConnection = (e) => {
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${url}/zohoTesting&access_type=offline&prompt=Consent`;
  };

  const fetchingdataForZoho = () => {
    clearInterval(timerforZoho.current);
    flagForInvoiceMgn += 1;
    toast.configure();

    if (flagForInvoiceMgn === 2) {
      setEffitracFormSubmitLoader(true);
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: grandCode,
          type_of_data: "purchase_invoice",
          redirect_uri: `${url}/zohoTesting`,
          date: zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            // setTableForZoho(res.data.records);
            // setTableForZohoSearch(res.data.records);
            // setTriggerZohoBackImportCwsButton({
            //   // zohoEffitrack: false,
            //   // importToCws: true,
            //   backButton: true,
            // });
            setZohoDate({});

            setZohoTrigger({
              ...zohoTrigger,
              messageForConnection: "Establish Connection",
              buttonColor: "red",
              zohoShowButton: true,
            });
            // closeZohoDrawer();
            setEffitracFormSubmitLoader(false);
          } else {
            setZohoDate({});
            setZohoTrigger({
              ...zohoTrigger,
              messageForConnection: "Establish Connection",
              buttonColor: "red",
              zohoShowButton: true,
            });
            setEffitracFormSubmitLoader(false);
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          setZohoDate({});
          setZohoTrigger({
            ...zohoTrigger,
            messageForConnection: "Establish Connection",
            buttonColor: "red",
            zohoShowButton: true,
          });
          setEffitracFormSubmitLoader(false);
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };

  const handleImportToCWS = (dataFromZoho) => {
    toast.configure();
    axios
      .post(`/arc/effitrac/vendorinvoice`, {
        records: dataFromZoho,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        refreshUploadHistoryTable();
        setShowUploadHistoryTable(true);
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });

        //Edited by harsh has Json Formate had been change Status Code: 204 NO CONTENT
        // setUploadSection(true); // Box Trigger
        // setLoading(false)
        // if (res.data.status === false) {
        //   setUploadResData(res.data);
        //   setUploadErrorMsg();
        // }
        // if (res.data.message) {
        //   setUploadErrorMsg(res?.data?.message);
        // } else {
        //   setUploadErrorMsg();
        //   // getInvoiceDetails()
        //   setUploadResData(res.data);
        // }
      });
  };

  // const handleBackButton = () => {
  //   setShowUploadHistoryTable(false);
  //   setTriggerZohoBackImportCwsButton({
  //     zohoEffitrack: true,
  //     importToCws: false,
  //     backButton: false,
  //   });
  // };

  const handlerShowForZoho = (item) => {
    setShowForZoho(item);
    setShowForZohoButton(true);
  };

  // const closeShowDrawerForZoho = () => {
  //   setShowForZohoButton(false);
  // };

  const uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((res) => {
        setUploadSection(true);
        var jsonData = res.data.records;

        if (res.data.records.type === "message") {
          setUploadResData();
          setUploadErrorMsg(res.data.records.message);
        } else {
          setUploadErrorMsg();
          getInvoiceDetails();
          setUploadResData(jsonData);
        }
      })
      .catch((error) => {});
  };

  // refresh Upload History Table using refresh button
  const refreshUploadHistoryTable = () => {
    getUploadHistoryDataApi();
    getInvoiceDetails();
  };

  const errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: uploadResData,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendorBillUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div>
        <ToggleSwitch
          onChange={() => setShowUploadHistoryTable(!showUploadHistoryTable)}
          value={showUploadHistoryTable}
          label={`Upload Bill`}
        />
      </div>

      {showUploadHistoryTable && (
        <BoxWrapper
          boxTitle="Upload History"
          zohoEffitracEnable={localStorage.getItem("Client_ID") && true}
          zohoEffitracFn={handleZohoFun}
        >
          <a
            href={VendorInvoice}
            download={"vendor_bill.xlsx"}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <Button
              component="label"
              size="small"
              color="primary"
              style={{ marginBottom: "10px" }}
            >
              <GetAppIcon style={{ fontSize: "20px" }} />
              DOWNLOAD TEMPLATE
            </Button>
          </a>

          <Button
            component="label"
            size="small"
            color="primary"
            style={{ marginBottom: "10px" }}
          >
            <PublishIcon style={{ fontSize: "20px" }} />
            UPLOAD TEMPLATE
            <input
              accept=".xlsx"
              name="file"
              type="file"
              value=""
              hidden
              onChange={fileUploader}
            />
          </Button>

          <Button
            component="label"
            size="small"
            color="primary"
            style={{ float: "right", marginBottom: "10px" }}
            onClick={refreshUploadHistoryTable}
          >
            <RefreshIcon style={{ fontSize: "20px" }} />
            Refresh
          </Button>

          <div className="responsive-table">
            <table className="custome-table">
              <thead>
                <tr>
                  <th>Last Upload Date & Time</th>
                  <th>Status</th>
                  <th>Upload Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadHistoryTableData.length > 0 &&
                  uploadHistoryTableData.map((item, i) => {
                    return (
                      <tr>
                        <td>{item.upload_date}</td>
                        <td>{item.status}</td>
                        <td>{item.upload_type}</td>
                        <td>
                          {item.status === "In Progress" ? (
                            <RemoveRedEyeOutlinedIcon className="action-icon-button" />
                          ) : item.status === "Uploaded" ? (
                            <RemoveRedEyeOutlinedIcon
                              className="action-icon-button"
                              color="disabled"
                            />
                          ) : (
                            <RemoveRedEyeOutlinedIcon
                              className="action-icon-button showIcon"
                              onClick={() => uploadReport(item.id)}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                {uploadHistoryTableData.length === 0 && (
                  <tr>
                    <td
                      style={{ color: "red", textAlign: "center" }}
                      colspan={4}
                    >
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </BoxWrapper>
      )}

      {uploadSection && (
        <BoxWrapper
          boxTitle="Upload Details"
          cutIcon={uploadSection}
          closeBoxWapper={closeUploadBoxWapper}
          enableAddButton={
            uploadResData && uploadResData.records.summary.invalid_records > 0
              ? true
              : null
          }
          addButtonLabel="EXPORT"
          addButtonHandlerFn={errorLogExportHandlerForUpload}
        >
          {uploadErrorMsg && (
            <div style={{ marginBottom: "20px", color: "red" }}>
              {uploadErrorMsg}
            </div>
          )}

          {uploadResData && (
            <>
              <div>
                <Grid container spacing={3}>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#6f9eaf"
                      cardTitle="Total Records"
                      cardBody={uploadResData.records.summary.total_records}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#007965"
                      cardTitle="Successful Records"
                      cardBody={uploadResData.records.summary.valid_records}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <Card
                      backgroundcolor="#eb596e"
                      cardTitle="Error Records"
                      cardBody={uploadResData.records.summary.invalid_records}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="upload-details-table-section">
                <div className="tableResponisve">
                  <table className="tableCss">
                    <thead>
                      <tr>
                        <th>GSTIN</th>
                        <th>Row #</th>

                        <th>Status</th>
                        <th>Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadResData.status === false &&
                        uploadResData.records.details.map((item, i, arr) => {
                          if (item.status === "Fail") {
                            return (
                              <tr>
                                <td>{item.record.vendorGstin}</td>
                                <td>{item.row}</td>
                                <td>{item.status}</td>
                                <td style={{ color: "red" }}>
                                  {Object.keys(item.errors_in).map(
                                    (v, i, ar) => {
                                      if (ar.length > 1) {
                                        return (
                                          <div>
                                            <span style={{ color: "black" }}>
                                              {v}
                                            </span>{" "}
                                            : {item.errors_in[v].join(",")}
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            <span style={{ color: "black" }}>
                                              {v}
                                            </span>{" "}
                                            : {item.errors_in[v].join(",")}
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </BoxWrapper>
      )}

      {/* Invoice details table */}
      <BoxWrapper
        boxTitle="Bill Details"
        exportToExcelEnable={true}
        exportToExcelFn={() => exportToExcel(tableData)}
        enableSearchInput={true}
        searchInputPlaceholder="Search..."
        searchInputOnchange={
          triggerZohoBackImportCwsButton.backButton
            ? searchHandlerFroZoho
            : SearchInputHandler
        }
      >
        <div style={{ marginBottom: "20px" }}>
          {/* add and search handler */}

          {/* {!triggerZohoBackImportCwsButton.backButton && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={addFormHandler}
                >
                  Add Bill
                </Button>
              )} */}

          {/* invoice details table */}

          <>
            <div className="responsive-table">
              <table className="custome-table">
                <thead>
                  <tr>
                    <th>Vendor Name</th>
                    <th>Bill Number</th>
                    <th>Bill Date</th>
                    <th>Gstin</th>
                    {/* <th>Total Amount</th> */}
                    {/* <th>Action</th> */}
                    <th>Show</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, i, arr) => {
                    return (
                      <tr>
                        <td>{item.vendorName}</td>
                        <td>{item.invoiceNumber}</td>
                        <td>{item.invoiceDate}</td>
                        <td>{item.vendorGstin}</td>
                        {/* <td>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => editDataHandler(item)}
                            >
                              Edit
                            </Button>
                          </td> */}
                        <td>
                          {" "}
                          <RemoveRedEyeOutlinedIcon
                            className="action-icon-button showIcon"
                            onClick={() => showDetailsHandler(item)}
                          />{" "}
                        </td>
                      </tr>
                    );
                  })}

                  {tableData.length === 0 && loading === false && (
                    <tr>
                      <td
                        colSpan={8}
                        style={{ color: "red", textAlign: "center" }}
                      >
                        No Data Found!
                      </td>
                    </tr>
                  )}

                  {loading && (
                    <tr>
                      <td
                        colSpan={8}
                        style={{
                          color: "black",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Loading...Please wait
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
              component="div"
              count={rowCount} //total row count
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        </div>
      </BoxWrapper>

      {/* add Form drawer */}
      <Drawer
        anchor="right"
        open={openAddFormDrawer}
        onClose={() => setOpenAddFormDrawer(false)}
        variant={onCickOperation === true ? "persistent" : ""}
      >
        <div className="Drawer-title">Add Invoice</div>
        <div className="drawer-body">
          <AddInvoiceForm
            closeAddFormDrawer={closeAddFormHandler}
            updateTableAfterSubmit={getInvoiceDetails}
            getInvoiceDetail={getInvoiceDetails}
          />
        </div>
      </Drawer>

      {/* edit drawer */}
      <Drawer
        anchor="right"
        open={openEditDrawer}
        onClose={() => setOpenEditDrawer(false)}
      >
        <div className="Drawer-title">Edit Invoice</div>
        <div className="drawer-body">
          <EditInvoiceForm
            editData={editData}
            closeEditFormDrawer={closeEditFormHandler}
            getInvoiceDetail={getInvoiceDetails}
          />
        </div>
      </Drawer>

      {/* show drawer */}
      <RightDrawer
        drawerName="Bill Details"
        open={openShowDrawer}
        onClose={() => setOpenShowDrawer(false)}
      >
        <ShowDetails showData={showData} closeDrawer={closeShowDrawer} />
      </RightDrawer>

      {/*....Zoho Show..........*/}

      <ZohoModel
        openZohoDrawer={zohoTrigger.zohoShowButton}
        buttonColor={zohoTrigger.buttonColor}
        establishConnection={establishConnection}
        messageForConnection={zohoTrigger.messageForConnection}
        timerForZohoInSalesCustomer={timers}
        closeZohoDrawer={closeZohoDrawer}
        zohoDateChangeHandler={zohoDateChangeHandler}
        effitracImportFormloader={effitracFormSubmitLoader}
        fetchingdataForZoho={fetchingdataForZoho}
        zohoDate={zohoDate}
        logApitName="purchase_invoice"
        nameOfTheModel="Bill Management"
        importToCWS={handleImportToCWS}
        exportToExcel={exportToExcel}
        zohoTableHeader={Header}
        zohoTableColumn={Column}
        drawerKeys={actionHeaderForZoho}
      />
    </div>
  );
}

export default InvoiceMgmt;

import React, { Component } from "react";
import classes from "./VendorpaymentAdvice.module.css";
import axios from "../../../../axios-order";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "../../../../components/ui/Cards";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import DataTables from "material-ui-datatables";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import VendorPaymentAdvice from "../../../../asserts/templates/vendor_payment_advice.xlsx";
import SelectField from "../../../../components/ui/select";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import Drawer from "@material-ui/core/Drawer";
import ClearIcon from "@material-ui/icons/Clear";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../../../components/ui/inputField";
import CircularProgress from "@material-ui/core/CircularProgress";
import ValidatedDatePicker from "../../../../components/ui/DatePicker";
import Modal from "react-bootstrap/Modal";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CustomTable from "../../../../components/ui/Table/Table";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TablePagination } from "@material-ui/core";

import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";

import ToggleSwitch from "../../../../components/ui/ToggleSwitch/ToggleSwitch";
import BoxWrapper from "../../../../components/ui/boxWrapper";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import RightDrawer from "../../../../components/ui/RightDrawer";
import ZohoModel from "../../../../components/ZohoModel/ZohoModel";

let flagForVendorPayment = 1;
let variableForTimer = null;

const numberFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumSignificantDigits: 10,
  }).format(value);
// ---End---

const dateFormatWithDash = (value) => {
  if (value) {
    let old_date = String(value).split("-");
    return old_date[2] + "-" + old_date[1] + "-" + old_date[0];
  }
};

class VendorpaymentAdvice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      effitracCol: [
        {
          key: "Vendor Code+",
          render: (index, row) => {
            return row["erpVendorCode"];
          },
        },

        {
          key: "gstin",
          render: (index, row) => {
            return row["vendorGstin"];
          },
        },

        {
          key: "invoiceNo",
          render: (index, row) => {
            return row["invoiceNo"];
          },
        },
        {
          key: "invoiceDate",
          render: (index, row) => {
            return row["invoiceDate"]
              // ? dateFormatWithDash(row["invoiceDate"])
              // : "";
          },
        },

        {
          key: "paymentDate",
          render: (index, row) => {
            return row["paymentDate"]
              // ? dateFormatWithDash(row["paymentDate"])
              // : "";
          },
        },
        {
          key: "transactionRefNo",
          render: (index, row) => {
            return row["transactionRefNumber"];
          },
        },

        {
          key: "amountPaid",
          render: (index, row) => {
            return numberFormat(row["amountPaid"]);
          },
        },

        // {
        //   key: "tdsAmount",
        //   render: (index, row) => {
        //     return numberFormat(row["tdsAmount"]);
        //   },
        // },
        {
          key: "adjustmentMethod",
          render: (index, row) => {
            return row["adjustmentMethod"];
          },
        },
      ],
      effitracData: null,
      oldEffitracData: null,
      effitracHeader: [
        { label: "ERP Vendor Code" },
        { label: "GSTIN" },
        { label: "Bill Number" },
        { label: "Bill Date" },
        { label: "Payment Date" },
        { label: "Transaction Ref No" },
        { label: "Amount Paid" },
        // { label: "TDS Amount" },
        { label: "Adjustment Method" },
      ],
      paymentDateDisplay: "",
      invoiceDateDisplay: "",
      read_only: "",
      next: {},
      prev: {},
      file_value: null,
      total_records: 0,
      successful_records: 0,
      error_records: 0,
      error_rows: {},
      sellerCustomerCol: [
        // { key: 'seller_code', label: 'Seller Code', },
        {
          label: "GSTIN",
          render: (name, all) => {
            return all["record"]["vendorGstin"];
          },
        },
        {
          label: "Invoice Number",
          render: (name, all) => {
            return all["record"]["invoiceNumber"];
          },
        },
        { key: "row", label: "Row #" },
        {
          label: "Errors",
          render: (name, all) => {
            return Object.keys(all["errors_in"]).map((i, v) => {
              return (
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <span>{i}</span>&nbsp;<span>:</span>&nbsp;
                  <span style={{ color: "red" }}>
                    {all["errors_in"][i].join(",")}
                  </span>
                </div>
              );
            });
          },
        },
        // {label:"Status",key:"status"}
      ],
      sellerCustomerData: [],
      error: null,
      allArcPaymentAdviceData: [],
      OriginalAllArcPaymentAdviceData: [],
      showUploadData: false,
      showDrawer: false,
      showPaymentDetailsData: {},
      loading: false,
      invicedateForAddDisplay: "",
      add_DrawerOpen: false,
      addPaymentAdviceData: {
        vendorGstin: "",
        invoiceNumber: "",
        invoiceDate: "",
        amountPaid: null,
        paymentDate: "",
        transactionRefNumber: "",
        tdsAmount: null,
        bankAccountNumber: "",
        typeOfTransactions: "",
        erpVendorCode: "",
      },

      edit_DrawerOpen: false,
      editPaymentAdviceData: {},

      //..............Edited by Harsh............
      openZohoDrawer: false,
      messageForConnection: "Establish Connection",
      buttonColor: "red",
      grantToken: "",
      url: "",
      zohoDate: {},

      // add paymentAdvice modal
      invoiceApiDataForPaymentAdviceModal: [],
      invoiceApiSearchDataForPaymentAdviceModal: [],
      paymentAdviceModalOpen: false,
      paymentAdviceModalBackDrop: false,

      // collection data--for transaction Ref No
      allArcCollectionData: [],
      OriginalAllArcCollectionData: [],

      // import effitrac / tally
      openImportEffitracDrawer: false,
      effitracFetchFromData: {},

      // pagination state
      page: 0,
      rowsPerPage: 25,
      rowCount: 0,
      searchInput: "",

      // Select Add Payment Advice (select Bill*)
      pageAddPayment: 0,
      rowsPerPageAddPayment: 25,
      rowCountAddPayment: 0,

      uploadHistoryTableData: [],
      showUploadHistroyTable: false,
      flagForZoho: false,
      timer: 60,
      loading: true,
      // variableForTimer: null,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.vendorPaymentGetApi(this.state.searchInput);
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 }, () => {
      this.vendorPaymentGetApi(this.state.searchInput);
    });
  };
  // table serach
  handleSearchInputChange = (event) => {
    this.setState(
      { searchInput: event.target.value, page: 0, rowsPerPage: 25 },
      () => {
        this.vendorPaymentGetApi(this.state.searchInput);
      }
    );
  };

  //Select Add Payment Advice (select Bill*)
  handleChangePageForPaymentAdvice = (event, newPage) => {
    this.setState({ pageAddPayment: newPage }, () => {
      this.getSellerInvoiceDataApi(this.state.paymentAdviceModalSearchInput);
    });
  };

  handleChangeRowsPerPageForPaymentAdvice = (event) => {
    this.setState(
      { rowsPerPageAddPayment: +event.target.value, pageAddPayment: 0 },
      () => {
        this.getSellerInvoiceDataApi(this.state.paymentAdviceModalSearchInput);
      }
    );
  };

  // table serach
  arcPaymentAdviceModalSearch = (event) => {
    this.setState(
      {
        paymentAdviceModalSearchInput: event.target.value,
        pageAddPayment: 0,
        rowsPerPageAddPayment: 25,
      },
      () => {
        this.getSellerInvoiceDataApi(this.state.paymentAdviceModalSearchInput);
      }
    );
  };

  // Vendor payment advice get api
  vendorPaymentGetApi = (serachIn) => {
    axios
      .post(`/get_vendor_payment_details`, {
        number_of_rows_per_page: this.state.rowsPerPage,
        page_number: this.state.page,
        search_text: serachIn,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          allArcPaymentAdviceData: res.data.record,
          OriginalAllArcPaymentAdviceData: res.data.record,
          rowCount: res?.data?.row_count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller invoice get api function
  getSellerInvoiceDataApi = (serachIn) => {
    axios
      .post(`/get_vendor_invoice_details`, {
        number_of_rows_per_page: this.state.rowsPerPageAddPayment,
        page_number: this.state.pageAddPayment,
        search_text: serachIn,
        business_entity_id: localStorage.getItem("business_entity_id"),
      })
      .then((res) => {
        this.setState({
          invoiceApiDataForPaymentAdviceModal: res.data.record,
          invoiceApiSearchDataForPaymentAdviceModal: res.data.record,
          rowCountAddPayment: res?.data?.row_count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // seller collection get api function
  getAllSellerCollectionData = () => {
    axios
      .get(
        `/arc/sellerCollection/${localStorage.getItem("business_entity_id")}`
      )
      .then((res) => {
        this.setState({
          allArcCollectionData: res.data.records,
          OriginalAllArcCollectionData: res.data.records,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSellerPaymentAdviceLastUploadDate = () => {
    const customer = "SellerPaymentAdvice";
    axios
      .get(
        `/get_last_update_date/${localStorage.getItem(
          "business_entity_id"
        )}/${customer}`
      )
      .then((res) => {
        const d = res.data.last_updated_date.split("T");
        const Date = dateFormatWithDash(d[0]);
        const Time = d[1].split("Z");
        this.setState({
          lastUploadDate: Date,
          lastUploadTime: Time,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getApiForClientID = () => {
    axios
      .get(`/connection_info/${localStorage.getItem("business_entity_id")}`)
      .then((res) => {
        res.data.record
          .filter((v) => v.source_system === "ZOHO")
          .map((value) =>
            localStorage.setItem("Client_ID", value.connection_info.client_id)
          );
        res?.data?.record.filter((v) => {
          if (v.source_system === "Effitrac" || v.source_system === "Tally") {
            localStorage.setItem("Trigger_Effitrac/Tally_Button", "true");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // getting history table data of upload
  getHistoryTableUploadData = () => {
    axios
      .get(
        `/arc/get_data_upload_history/${localStorage.getItem(
          "business_entity_id"
        )}/${15}`
      )
      .then((res) => {
        this.setState({ uploadHistoryTableData: res.data.record });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    let domain = new URL(window.location).searchParams;
    let code = domain.get("code");
    code && this.setState({ grantToken: code });

    let drawer = domain.get("drawer");
    drawer &&
      this.setState({
        openZohoDrawer: true,
        messageForConnection: "Connection Established",
        buttonColor: "green",
      });

    let url = window.location;
    this.setState({ url: url.origin });
    localStorage.setItem("urlOrigin", url.origin);
    localStorage.setItem("pathName", url.pathname);

    this.getApiForClientID();
    this.vendorPaymentGetApi(); // Table
    this.getSellerInvoiceDataApi(); // Add Payment Advice -- Select Invoice
    this.getAllSellerCollectionData();
    this.getSellerPaymentAdviceLastUploadDate(); // Update Date and Time
    this.getHistoryTableUploadData();
  }

  componentDidUpdate() {
    if (this.state.buttonColor === "green" && !this.state.flagForZoho) {
      this.setState({ flagForZoho: true });
      variableForTimer = setInterval(() => {
        this.setState((prevstate) => ({ timer: prevstate.timer - 1 }));
      }, 1000);
    }
    if (this.state.timer === 0) {
      clearInterval(variableForTimer);
      this.setState({ flagForZoho: false, timer: 60, buttonColor: "red" });
    }
  }

  // --------------------------------------------ARC transaction  modal[ customer table]-------------------------------

  //Indian Rupee Formate
  indianRupeesFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumSignificantDigits: 10,
    }).format(value);

  // arc paymentAdvice table header[customer table data in modal]---
  paymentAdviceModal_TableHeader() {
    const tableHeader = [
      "Vendor Name",
      "Vendor GSTIN",
      "Invoice Number",
      "select",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  paymentAdviceModal_tableBody() {
    const addPaymentAdvice = (dataForSelectInvoice) => {
      let invoiceDateForAdd = dataForSelectInvoice.invoiceDate;
      let invoiceDateDisplayForAdd = invoiceDateForAdd
        .split("-")
        .reverse()
        .join("-");
      const invoiceDateConvertingIntoStringForAdd =
        invoiceDateDisplayForAdd.toString();
      this.setState((prevState) => ({
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          vendorGstin: dataForSelectInvoice.vendorGstin,
          invoiceNumber: dataForSelectInvoice.invoiceNumber,
          invoiceDate: invoiceDateConvertingIntoStringForAdd,
          erpVendorCode: dataForSelectInvoice.erpVendorCode,
          amountPaid: null,
          paymentDate: "",
          transactionRefNumber: "",
          tdsAmount: null,
          bankAccountNumber: "",
          typeOfTransactions: "",
        },
        invicedateForAddDisplay: dataForSelectInvoice.invoiceDate,
        paymentAdviceModalOpen: false,
        paymentAdviceModalBackDrop: false,
        paymentAdviceModalSearchInput: "",
      }));

      this.getSellerInvoiceDataApi();
    };

    if (
      this.state.invoiceApiDataForPaymentAdviceModal &&
      this.state.invoiceApiDataForPaymentAdviceModal.length >= 1
    ) {
      return this.state.invoiceApiDataForPaymentAdviceModal.map(
        (tableData, index) => {
          return (
            <tr key={index}>
              <td>{tableData.vendorName}</td>
              <td> {tableData.vendorGstin}</td>
              <td>{tableData.invoiceNumber}</td>
              <td>
                <AddCircleOutlineIcon
                  onClick={() => addPaymentAdvice(tableData)}
                />
              </td>
            </tr>
          );
        }
      );
    } else {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }

  //-----------------Payment advice table search----------------
  handleSearchInputChangeForZohoAndEffitrack = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch(event);
    });
  };

  //excel download
  downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  export_to_excel = (datafromfunction) => {
    let data = {};
    if (this.state.openZohoDrawer) {
      data = {
        zoho_data:datafromfunction,
        business_entity_id:localStorage.getItem("business_entity_id")
      }
    } else {
     data = {
      business_entity_id:localStorage.getItem("business_entity_id")
     }    
    }
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendor_payment_advice.xlsx`;

    axios
      .post("/vendor/vendorpaymentadvicedownload", data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          toast.error(err?.response?.data?.detail, { autoClose: 2000 });
        } else {
          toast.error("Server Error Try After Some Time", { autoClose: 2000 });
        }
      });
  };

  globalSearch = (event) => {
    let { searchInput } = this.state;

    let searchAdminData = this.state.oldEffitracData.filter((value) => {
      const query = searchInput.trim().toLowerCase();
      return (
        String(value.erpVendorCode).toLowerCase().indexOf(query) >= 0 ||
        String(value.vendorGstin).toLowerCase().indexOf(query) >= 0 ||
        String(value.invoiceNumber).toLowerCase().indexOf(query) >= 0 ||
        String(value.transactionRefNumber).toLowerCase().indexOf(query) >= 0 ||
        String(moment(value.paymentDate).format("DD-MM-YYYY"))
          .toLowerCase()
          .indexOf(query) >= 0 ||
        String(moment(value.paymentDate).format("DD/MM/YYYY"))
          .toLowerCase()
          .indexOf(query) >= 0 ||
        String(value.bankAccountNumber).toLowerCase().indexOf(query) >= 0 ||
        String(value.typeOfTransactions).toLowerCase().indexOf(query) >= 0 ||
        String(moment(value.invoiceDate).format("DD-MM-YYYY"))
          .toLowerCase()
          .indexOf(query) >= 0 ||
        String(moment(value.invoiceDate).format("DD/MM/YYYY"))
          .toLowerCase()
          .indexOf(query) >= 0 ||
        String(this.indianRupeesFormat(value.amountPaid))
          .toLowerCase()
          .indexOf(query) >= 0 ||
        String(value.amountPaid).toLowerCase().indexOf(query) >= 0 ||
        String(this.indianRupeesFormat(value.tdsAmount))
          .toLowerCase()
          .indexOf(query) >= 0 ||
        String(value.tdsAmount).toLowerCase().indexOf(query) >= 0
      );
    });
    this.setState({ effitracData: searchAdminData });
  };

  // ------------------------Payment advice file uplpad-----------------------
  fileUploader = (event, serachIn) => {
    this.setState({ loading: true });
    const file_data = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    let dis = this;

    reader.onload = function () {
      let file_value = reader.result;
      axios
        .post(`/vendor/vendorpaymentadvice`, {
          file: file_value,
          entityGst: localStorage.getItem("gst"),
        })
        .then((data) => {
          dis.getHistoryTableUploadData();
          dis.setState({ loading: false });
          // axios
          //   .get(`/get_vendor_payment_details`, {
          //     number_of_rows_per_page: this.state.rowsPerPage,
          //     page_number: this.state.page,
          //     search_text: serachIn,
          //     business_entity_id: localStorage.getItem('business_entity_id'),
          //   })
          //   .then((res) => {
          //     dis.setState({
          //       allArcCustomerDataPurchase: res.data.record,
          //       OriginalDataAllArcCustomerDataPurchase: res.data.record,
          //       rowCount: res.data.row_count,
          //     });
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
        })
        .catch((error) => {
          toast.error("Unable To Upload Template Try After Some Time", {
            autoClose: 2000,
          });
          // dis.setState({
          //   error: error,
          //   total_records: 0,
          // });
        });
    };
  };
  // refresh Upload History Table using refresh button
  refreshUploadHistoryTable = () => {
    this.getHistoryTableUploadData();
    this.vendorPaymentGetApi();
  };

  CollectionListActions = () => (
    <div>
      <a
        href={VendorPaymentAdvice}
        download={"vendor_payment_advice.xlsx"}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <Button component="label" size="small" color="primary">
          <GetAppIcon style={{ fontSize: "20px" }} />
          DOWNLOAD TEMPLATE
        </Button>
      </a>

      <Button component="label" size="small" color="primary">
        <PublishIcon style={{ fontSize: "20px" }} />
        UPLOAD TEMPLATE{" "}
        <input
          accept=".xlsx"
          name="file"
          type="file"
          value=""
          hidden
          onChange={this.fileUploader}
        />
      </Button>
      <Button
        component="label"
        size="small"
        color="primary"
        style={{ float: "right" }}
        onClick={this.refreshUploadHistoryTable}
      >
        <RefreshIcon style={{ fontSize: "20px" }} />
        Refresh
      </Button>
    </div>
  );
  // ----------------Payment advice file upload response end------------

  // ------------------------Payment advice table-----------------------
  arc_paymentAdvice_TableHeader() {
    const tableHeader = [
      "Vendor Name",
      "ERP Vendor Code",
      "Vendor gstin",
      "Bill Number",
      "Amount Paid ",
      // 'Action',
      "Show",
    ];
    return tableHeader.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  // table body
  arc_PaymentAdvice__data() {
    const showToggleDrawer = (
      amountPaid,
      bankAccountNumber,
      createDate,
      created_date,
      erpVendorCode,
      invoiceNo,
      modifyDate,
      modified_date,
      orgCode,
      paymentDate,
      tdsAmount,
      transactionRefNumber,
      typeOfTransactions,
      vendorCode,
      vendorGstin,
      vendorName,
      vendorPan,
      voucherId,
      invoiceDate,
      adjustmentMethod
    ) => {
      this.setState({
        showPaymentDetailsData: {
          showTransactionRefNo: transactionRefNumber,
          showTransactionType: typeOfTransactions,
          showPaymentDate: paymentDate,
          showAmountPaid: amountPaid,
          showTdsAmount: tdsAmount,
          showInvoiceDate: invoiceDate,
          showModifyDate: modifyDate,
          showCreateDate: createDate,
          created_date:created_date,
          modified_date: modified_date,
          showbankAccountNumber: bankAccountNumber,
          showvendorPan:vendorPan,
          showvoucherId: voucherId,
          showadjustmentMethod:adjustmentMethod
        },
        showDrawer: true,
      });
    };

    const editPaymentAdvice = (
      amountPaid,
      bankAccountNumber,
      createDate,
      created_date,
      erpVendorCode,
      invoiceDate,
      invoiceNumber,
      modifyDate,
      modified_date,
      orgCode,
      paymentDate,
      tdsAmount,
      transactionRefNumber,
      typeOfTransactions,
      vendorCode,
      vendorGstin,
      vendorName
    ) => {
      let invoice = invoiceDate;
      let invoiceDateDisplay = invoice.split("-").reverse().join("-");
      const invoiceDateConvertingIntoString = invoiceDateDisplay.toString();
      let PaymentDate = paymentDate;
      let PaymentDateReverse = PaymentDate.split("-").reverse().join("-");
      const PaymentDateConvertingIntostring = PaymentDateReverse.toString();

      this.setState({
        editPaymentAdviceData: {
          amountPaid: amountPaid,
          transactionRefNumber: transactionRefNumber,
          tdsAmount: tdsAmount,
          bankAccountNumber: bankAccountNumber,
          typeOfTransactions: typeOfTransactions,
          vendorGstin: vendorGstin,
          invoiceNumber: invoiceNumber,
          invoiceDate: invoiceDateConvertingIntoString,
          paymentDate: PaymentDateConvertingIntostring,
          erpVendorCode: erpVendorCode,
        },
        edit_DrawerOpen: true,
        paymentDateDisplay: paymentDate,
        invoiceDateDisplay: invoiceDate,
      });
    };

    if (
      this.state.allArcPaymentAdviceData &&
      this.state.allArcPaymentAdviceData.length >= 1
    ) {
      return this.state.allArcPaymentAdviceData.map((tableData, index) => {
        const {
          amountPaid,
          bankAccountNumber,
          createDate,
          created_date,
          erpVendorCode,
          invoiceNo,
          modifyDate,
          modified_date,
          orgCode,
          paymentDate,
          tdsAmount,
          transactionRefNumber,
          typeOfTransactions,
          vendorCode,
          vendorGstin,
          vendorName,
          vendorPan,
          voucherId,
          invoiceDate,
          adjustmentMethod,
        } = tableData; //destructuring
        return (
          <tr key={index}>
            <td>{vendorName}</td>
            <td>{erpVendorCode}</td>
            <td>{vendorGstin}</td>
            <td>{invoiceNo}</td>
            <td>{this.indianRupeesFormat(amountPaid)}</td>
            <td>
              <RemoveRedEyeOutlinedIcon
                className="action-icon-button showIcon"
                onClick={() =>
                  showToggleDrawer(
                    amountPaid,
                    bankAccountNumber,
                    createDate,
                    created_date,
                    erpVendorCode,
                    invoiceNo,
                    modifyDate,
                    modified_date,
                    orgCode,
                    paymentDate,
                    tdsAmount,
                    transactionRefNumber,
                    typeOfTransactions,
                    vendorCode,
                    vendorGstin,
                    vendorName,
                    vendorPan,
                    voucherId,
                    invoiceDate,
                    adjustmentMethod,
                  )
                }
              />
            </td>
          </tr>
        );
      });
    } else if (this.state.loading) {
      return (
        <tr>
          <td
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
            colspan={8}
          >
            Loading...Please wait
          </td>
        </tr>
      );
    }
    {
      return (
        <tr>
          <td style={{ color: "red" }} colspan={8}>
            No data found!
          </td>
        </tr>
      );
    }
  }
  // ------------------------Payment advice table end-----------------------
  // ------------------------Payment advice add-----------------------------
  // add Payment Advice [add button] drawer open handeler---

  addPaymentAdvice_drawerOpen = () => {
    this.setState({ add_DrawerOpen: true });
  };

  // invoice date handler
  handleInvoiceDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      addPaymentAdviceData: {
        ...prevState.addPaymentAdviceData,
        invoiceDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
      },
      invoiceDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
        -2
      )}`,
    }));
  };

  // Payment date handler
  handlePaymentDateChange = (v) => {
    if (v != "Invalid Date" && v != null) {
      const pad = "00";
      const yy = v.getFullYear().toString();
      const mm = (v.getMonth() + 1).toString();
      const dd = v.getDate().toString();
      this.setState((prevState) => ({
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          paymentDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
        },
        paymentDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      }));
    }
  };

  // add Payment Advice input field handler[number format]
  addPaymentAdvice_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      addPaymentAdviceData: {
        ...prevState.addPaymentAdviceData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // add Payment Advicey inputfield handler---
  addPaymentAdvice_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "paymentDate") {
      let changingDateOrder = event.target.value;
      let arrayDate = changingDateOrder.split("-").reverse().join("-");
      let arraytoString = toString(arrayDate);
      this.setState((prevState) => ({
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          [name]: arraytoString,
        },
      }));
    } else {
      this.setState((prevState) => ({
        addPaymentAdviceData: {
          ...prevState.addPaymentAdviceData,
          [name]: String(value),
        },
      }));
    }
  };

  // add Payment Advice add submit handler
  addPaymentAdvice_submitHandler = () => {
    toast.configure();

    axios
      .post(`/vendor/vendorpaymentadvice`, {
        entityGst: localStorage.getItem("gst"),
        record: this.state.addPaymentAdviceData,
      })
      .then((res) => {
        if (res.data.status === false) {
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
        } else {
          this.vendorPaymentGetApi();
          this.setState({
            add_DrawerOpen: false,
            addPaymentAdviceData: {
              vendorGstin: "",
              invoiceNumber: "",
              invoiceDate: "",
              amountPaid: null,
              paymentDate: "",
              transactionRefNumber: "",
              tdsAmount: null,
              bankAccountNumber: "",
              typeOfTransactions: "",
              erpVendorCode: "",
            },
            invoiceDateDisplay: "",
            paymentDateDisplay: "",
          });
          toast.success("Vendor payment advice added successfully", {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // add Payment Advice drawer close handler---
  addPaymentAdvice_DrawerClose = () => {
    this.setState({
      add_DrawerOpen: false,
      addPaymentAdviceData: {
        vendorGstin: "",
        invoiceNumber: "",
        invoiceDate: "",
        amountPaid: null,
        paymentDate: "",
        transactionRefNumber: "",
        tdsAmount: null,
        bankAccountNumber: "",
        typeOfTransactions: "",
        erpVendorCode: "",
      },
    });
  };

  // edit Payment Advice [edit button] drawer open handeler---

  editPaymentAdvice_drawerOpen = () => {
    this.setState({ edit_DrawerOpen: true });
  };
  editPaymentDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      editPaymentAdviceData: {
        ...prevState.editPaymentAdviceData,
        paymentDate: `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`,
      },
      paymentDateDisplay: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
        -2
      )}`,
    }));
  };

  // edit Payment Advice input field handler[number format]
  editPaymentAdvice_inputNumberHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var numb = value.match(/\d/g);
    if (numb && numb.length > 10) return false;
    numb = numb ? numb.join("") : 0;

    this.setState((prevState) => ({
      editPaymentAdviceData: {
        ...prevState.editPaymentAdviceData,
        [name]: parseFloat(numb),
      },
    }));
  };

  // edit Payment Advicey inputfield handler---
  editPaymentAdvice_inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      editPaymentAdviceData: {
        ...prevState.editPaymentAdviceData,
        [name]: String(value),
      },
    }));
  };

  // edit Payment Advice edit submit handler
  editPaymentAdvice_submitHandler = () => {
    axios
      .post(`/vendor/vendorpaymentadvice`, {
        entityGst: localStorage.getItem("gst"),
        record: this.state.editPaymentAdviceData,
      })
      .then((res) => {
        toast.configure();
        if (res.data.status === false) {
          toast.error(res.data.message, { autoClose: 3000 });
          let error = "";
          Object.keys(res.data.record.details[0].errors_in).map((v, i) => {
            return (error +=
              String(v) + " " + res.data.record.details[0].errors_in[v]);
          });
          toast.error(error, {
            autoClose: 2000,
          });
          // toast.error(res.data.record.details[0].errors_in.invalid_data[0], {
          //   autoClose: 2000,
          // });
        } else {
          this.vendorPaymentGetApi();
          this.setState({
            edit_DrawerOpen: false,
          });
          toast.success("Vendor payment advice updated successfully", {
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        this.setState({
          edit_DrawerOpen: false,
        });
      });
  };

  // edit Payment Advice drawer close handler---
  editPaymentAdvice_DrawerClose = () => {
    this.setState({ edit_DrawerOpen: false, editPaymentAdviceData: {} });
  };

  // ------------------------Payment advice edit end-------------------------
  closeUploadData = () => {
    this.setState({ showUploadData: false });
    this.vendorPaymentGetApi(); // Table
  };

  showToggleDrawerClose = () => {
    this.setState({ showDrawer: false });
  };

  // add payment modal
  addPaymentAdviceModalOpen = () => {
    this.setState({
      paymentAdviceModalOpen: true,
      paymentAdviceModalBackDrop: true,
    });
  };

  addPaymentAdviceModalClose = () => {
    this.getSellerInvoiceDataApi();
    this.setState({
      paymentAdviceModalOpen: false,
      paymentAdviceModalBackDrop: false,
      paymentAdviceModalSearchInput: "",
    });
  };

  // import effitrac / tally form section
  importEffitracTallyOpenDrawer = () => {
    this.setState({ openImportEffitracDrawer: true });
  };

  importFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        vendorPaymentFromDate: `${yy}-${(pad + mm).slice(-2)}-${(
          pad + dd
        ).slice(-2)}`,
      },
    }));
  };

  importToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        vendorPaymentToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importModifiedFromDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        modifiedFromDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
          -2
        )}`,
      },
    }));
  };

  importModifiedToDateChangeHandler = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();

    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        modifiedToDate: `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`,
      },
    }));
  };

  fetchEffitacFormSubmitHandler = () => {
    toast.configure();
    this.setState({ effitracFormSubmitLoader: true });
    this.setState((prevState) => ({
      effitracFetchFromData: {
        ...prevState.effitracFetchFromData,
        business_entity_id: localStorage.getItem("business_entity_id"),
      },
    }));
    const data = this.state.effitracFetchFromData;
    axios
      .post("/arc/effitrac/paymentreport", data)
      .then((res) => {
        this.setState({
          isEffitrac: true,
          effitracData: res.data.records.records,
          oldEffitracData: res.data.records.records,
          importEffitracFetchData: {},
          openImportEffitracDrawer: false,
          effitracFormSubmitLoader: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          autoClose: 2000,
        });
        this.setState({ effitracFormSubmitLoader: false });
      });
  };

  closeEffitracTallyOpenDrawer = () => {
    this.setState({ openImportEffitracDrawer: false });
  };

  // import to cws button onclick--
  importToCWS = (dataForfun) => {
    toast.configure();

    axios
      .post("/arc/effitrac/vendorpayments", {
        business_entity_id: localStorage.getItem("business_entity_id"),
        records: dataForfun,
      })
      .then((res) => {
        this.setState({
          showUploadHistroyTable: true,
        });
        this.refreshUploadHistoryTable();
        toast.success("Uploading Files Into Database", {
          autoClose: 5000,
          progress: 0,
        });

        // Edited by harsh Json formate changed Status Code: 204 NO CONTENT

        // res.data.record.details.map((row, index) => {
        //   if ("status" in row && row["status"] === "Fail") {
        //     row["record"]["InvoiceNo"] =
        //       "invoiceNo" in row["record"] ? row["record"]["invoiceNo"] : "";
        //     row["record"]["GSTIN"] =
        //       "gstin" in row["record"] ? row["record"]["gstin"] : "";
        //     return row;
        //   }
        //   return false;
        // });
        // var error_records = [];
        // let promises = res.data.record.details.map((v, i) => {
        //   if (v.status == "Fail") {
        //     error_records.push(v);
        //   }
        //   return true;
        // });
        // Promise.all(promises).then(() => {
        //   dis.setState({
        //     total_records: parseInt(res.data.record.summary.total_records),

        //     successful_records: parseInt(res.data.record.summary.valid_records),
        //     error_records: parseInt(res.data.record.summary.invalid_records),
        //     sellerCustomerData: error_records,
        //     error: null,
        //     showUploadData: true,
        //     loading: false,
        //   });
        // });
      })
      .catch((error) => {
        toast.error("Server Error Try After Some Time", {
          autoClose: 5000,
          progress: 0,
        });
      });
  };

  // back button after import effitrac data
  backHandler = () => {
    this.setState({ isEffitrac: false, showUploadHistroyTable: false });
  };

  //...............Edited By Harsh Handle Drawer.........
  handleZohoDrawer = () => {
    flagForVendorPayment = 1;
    this.setState({ openZohoDrawer: true });
  };

  closeZohoDrawer = () => {
    clearInterval(variableForTimer);
    this.setState({
      zohoDate: {},
      openZohoDrawer: false,
      // effitracImportFormloader: false,
      effitracFormSubmitLoader: false,
      buttonColor: "red",
      messageForConnection: "Establish Connection",
      grantToken: "",
    });
  };

  establishConnection = (e) => {
    e.preventDefault();
    window.location.href = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.contacts.READ,ZohoBooks.invoices.READ,ZohoBooks.customerpayments.READ,ZohoBooks.creditnotes.READ,ZohoBooks.expenses.READ,ZohoBooks.bills.READ,ZohoBooks.debitnotes.READ,ZohoBooks.vendorpayments.READ&client_id=${localStorage.getItem(
      "Client_ID"
    )}&state=testing&response_type=code&redirect_uri=${
      this.state.url
    }/zohoTesting&access_type=offline&prompt=Consent`;
  };

  zohoDateChangeHandler = (item) => {
    const { name, value } = item;

    var d = new Date(value);
    var newd = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
    let dateValue = newd.toISOString().replace("Z", "");

    if (name === "created_from_date") {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    } else {
      this.setState({
        zohoDate: {
          ...this.state.zohoDate,
          [name]: dateValue,
        },
      });
    }
  };

  fetchingdataForZoho = () => {
    clearInterval(variableForTimer);
    flagForVendorPayment += 1;
    toast.configure();

    if (flagForVendorPayment === 2) {
      this.setState({ effitracFormSubmitLoader: true });
      axios
        .post("/arc/zoho_import", {
          business_entity_id: localStorage.getItem("business_entity_id"),
          code: this.state.grantToken,
          type_of_data: "purchase_payment_advice",
          redirect_uri: `${this.state.url}/zohoTesting`,
          date: this.state.zohoDate,
        })
        .then((res) => {
          if (res.data.status === true) {
            this.setState({
              zohoDate: {},
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
              effitracFormSubmitLoader: false,
            });
          } else {
            this.setState({
              zohoDate: {},
              buttonColor: "red",
              messageForConnection: "Establish Connection",
              grantToken: "",
              effitracFormSubmitLoader: false,
            });
            toast.error(res.data.message, {
              autoClose: 5000,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          this.setState({
            zohoDate: {},
            buttonColor: "red",
            messageForConnection: "Establish Connection",
            grantToken: "",
            effitracFormSubmitLoader: false,
          });
          toast.error(err?.response?.data?.message, {
            autoClose: 5000,
            progress: 0,
          });
        });
    }
  };

  // upload history table show report fn
  uploadReport = (id) => {
    axios
      .get(`/arc/get_data_upload_json/${id}`)
      .then((response) => {
        let res = response.data.records.records;
        if (response.data.records.type === "json") {
          var error_records = [];
          let promises = res.details.map((v, i) => {
            if (v.status === "Fail") {
              error_records.push(v);
            }
            return true;
          });

          Promise.all(promises).then(() => {
            this.setState({
              total_records: parseInt(res.summary.total_records),
              successful_records: parseInt(res.summary.valid_records),
              error_records: parseInt(res.summary.invalid_records),
              showUploadData: true,
              sellerCustomerData: error_records,
              error: null,
              loading: false,
              uploadStatusFail: response.data.records,
            });
            return true;
          });
        } else {
          this.setState({
            error: response.data.records.message,
            total_records: 0,
            loading: false,
            showUploadData: true,
          });
        }
      })
      .catch((error) => {});
  };

  errorLogExportHandlerForUpload = () => {
    let data = {
      business_entity_id: localStorage.getItem("business_entity_id"),
      records: this.state.uploadStatusFail,
    };
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const filename = `vendorPaymentAdviceUploadErrorLog.xlsx`;

    axios
      .post(`/uploads_errors_extract/download`, data)
      .then((res) => {
        this.downloadBase64File(contentType, res.data.file, filename);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    // const { push, ...props } = this.props;
    return (
      <React.Fragment>
        <ZohoModel
          openZohoDrawer={this.state.openZohoDrawer}
          buttonColor={this.state.buttonColor}
          establishConnection={this.establishConnection}
          messageForConnection={this.state.messageForConnection}
          timerForZohoInSalesCustomer={this.state.timer}
          closeZohoDrawer={this.closeZohoDrawer}
          zohoDateChangeHandler={this.zohoDateChangeHandler}
          effitracImportFormloader={this.state.effitracFormSubmitLoader}
          fetchingdataForZoho={this.fetchingdataForZoho}
          zohoDate={this.state.zohoDate}
          logApitName="purchase_payment_advice"
          nameOfTheModel="Vendor Payment Advice"
          importToCWS={this.importToCWS}
          exportToExcel={this.export_to_excel}
          zohoTableHeader={this.state.effitracHeader}
          zohoTableColumn={this.state.effitracCol}
          drawerKeys={[]}
        />

        {/* <Drawer
          anchor="right"
          open={this.state.openZohoDrawer}
          onClose={this.closeZohoDrawer}
        >
          <div style={{ textAlign: "center", marignTop: "10px" }}>
            <button
              className="button-outlined-primary"
              disabled={this.state.buttonColor === "green" ? true : false}
              onClick={this.establishConnection}
            >
              {this.state.messageForConnection}
            </button>
          </div>
          {this.state.buttonColor === "green" && (
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              <span style={{ color: this.state.timer < 30 ? "red" : "green" }}>
                Connection will be lost In{"  "}
                {this.state.timer} Seconds
              </span>
            </div>
          )}

          <ValidatorForm
            ref="form"
            onSubmit={this.fetchingdataForZoho}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ marginTop: "10px" }}>
              <label>
                Created From Date<span style={{ color: "red" }}>*</span>:
              </label>
              <ValidatedDatePicker
                fullWidth
                name="created_from_date"
                value={this.state.zohoDate.created_from_date ?? ""}
                onChange={(v) =>
                  this.zohoDateChangeHandler({
                    name: "created_from_date",
                    value: v,
                  })
                }
                requiredField="*"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </div>

            
              {this.state.effitracFormSubmitLoader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button
                  className="button-primary"
                  type="submit"
                  disabled={this.state.buttonColor === "red" ? true : false}
                >
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeZohoDrawer}
              >
                cancel
              </Button>
            </ValidatorForm>
         
          </div>
        </Drawer> */}

        {/* --------import effitrac/tally payment advice section */}
        {/* import debit effitrac/tally */}
        <RightDrawer
          drawerName="Import Effitrac/Tally"
          open={this.state.openImportEffitracDrawer}
          onClose={this.closeEffitracTallyOpenDrawer}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.fetchEffitacFormSubmitHandler}
            onError={(errors) => console.log(errors)}
          >
            <div style={{ textAlign: "center" }}>
              Last Upload Date<br></br>
              {this.state.lastUploadDate} {this.state.lastUploadTime}
            </div>
            <div style={{ marginTop: "20px" }}>
              <label>From Date:</label>
              <ValidatedDatePicker
                fullWidth
                name="fromDate"
                value={
                  this.state.effitracFetchFromData.vendorPaymentFromDate ?? ""
                }
                onChange={(v) => this.importFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>To Date:</label>
              <ValidatedDatePicker
                name="toDate"
                value={
                  this.state.effitracFetchFromData.vendorPaymentToDate ?? ""
                }
                onChange={(v) => this.importToDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified From Date:</label>
              <ValidatedDatePicker
                name="modifiedFromDate"
                value={this.state.effitracFetchFromData.modifiedFromDate ?? ""}
                onChange={(v) => this.importModifiedFromDateChangeHandler(v)}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <label>Modified To Date:</label>
              <ValidatedDatePicker
                name="modifiedToDate"
                value={this.state.effitracFetchFromData.modifiedToDate ?? ""}
                onChange={(v) => this.importModifiedToDateChangeHandler(v)}
              />
            </div>

            {/* submit and back button */}

            <div className="mt-20">
              {this.state.effitracFormSubmitLoader ? (
                <div>
                  <CircularProgress size={22} />
                  <span
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "10px",
                    }}
                  >
                    Uploading...
                  </span>
                </div>
              ) : (
                <button className="button-primary" type="submit">
                  Submit
                </button>
              )}
              <button
                type="button"
                className="button-outlined-secondary"
                onClick={this.closeEffitracTallyOpenDrawer}
              >
                cancel
              </button>
            </div>
          </ValidatorForm>
        </RightDrawer>

        {/* -----------------Vendor add payment Model --------------------  */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.paymentAdviceModalOpen}
          onHide={this.addPaymentAdviceModalClose}
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={classes.searchField}>
              <Grid container spacing={3}>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.onSubmitHandler}
                    onError={(errors) => console.log(errors)}
                  >
                    <InputField
                      // textLabel="Search"
                      inputPlacholder="Search...."
                      inputType="text"
                      requiredField=" "
                      name="Search_by_customer_name"
                      value={this.state.paymentAdviceModalSearchInput ?? ""}
                      textOnchangeFunction={this.arcPaymentAdviceModalSearch}
                    />
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
            <div className={classes.modalTableResponisve}>
              <table className={classes.tableCss}>
                <tbody>
                  <tr>{this.paymentAdviceModal_TableHeader()}</tr>
                  {this.paymentAdviceModal_tableBody()}
                </tbody>
              </table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={this.state.rowCountAddPayment} //total row count
              rowsPerPage={this.state.rowsPerPageAddPayment}
              page={this.state.pageAddPayment}
              onPageChange={this.handleChangePageForPaymentAdvice}
              onRowsPerPageChange={this.handleChangeRowsPerPageForPaymentAdvice}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="small"
              variant="outlined"
              onClick={this.addPaymentAdviceModalClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* upload temp Button */}
        <div>
          <ToggleSwitch
            onChange={() =>
              this.setState({
                showUploadHistroyTable: !this.state.showUploadHistroyTable,
              })
            }
            value={this.state.showUploadHistroyTable}
            label="Upload Payment Advice"
          />
        </div>

        {/* File upload history table  */}
        {this.state.showUploadHistroyTable && (
          <div>
            <BoxWrapper
              boxTitle="Upload History"
              zohoEffitracEnable={
                localStorage.getItem("Client_ID") &&
                !this.state.isEffitrac &&
                true
              }
              zohoEffitracFn={this.handleZohoDrawer}
              // enableImportToEffTallybutton={localStorage.getItem("Trigger_Effitrac/Tally_Button") && localStorage.getItem("Trigger_Effitrac/Tally_Button") === "true" && !this.state.isEffitrac }
              // importToEffiTallyFn={this.importEffitracTallyOpenDrawer}
            >
              <this.CollectionListActions />
              <div className="responsive-table">
                <table className="custome-table">
                  <thead>
                    <tr>
                      <th>Last Upload Date & Time</th>
                      <th>Status</th>
                      <th>Upload Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.uploadHistoryTableData.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.upload_date}</td>
                          <td>{item.status}</td>
                          <td>{item.upload_type}</td>
                          <td>
                            {item.status === "In Progress" ? (
                              <RemoveRedEyeOutlinedIcon color="disabled" />
                            ) : item.status === "Uploaded" ? (
                              <RemoveRedEyeOutlinedIcon color="disabled" />
                            ) : (
                              <RemoveRedEyeOutlinedIcon
                                className="action-icon-button showIcon"
                                onClick={() => this.uploadReport(item.id)}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.uploadHistoryTableData.length === 0 && (
                      <tr>
                        <td
                          colspan={4}
                          style={{ color: "red", textAlign: "center" }}
                        >
                          No Data Found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </BoxWrapper>
          </div>
        )}

        {this.state.showUploadData ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className={classes.Main_frame}>
                  <div className={classes.form_title_bar}>
                    <div class="form_title_name">
                      <ul
                        style={{
                          listStyle: "none",
                          marginBottom: "0px",
                          padding: "0px",
                          marginTop: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <li style={{ fontWeight: "700" }}>Upload Details</li>
                        <li>
                          {this.state.error_records > 0 && (
                            <button
                              className="button-outlined-primary"
                              onClick={this.errorLogExportHandlerForUpload}
                            >
                              Export
                            </button>
                          )}

                          <button
                            className="close-icon-button"
                            onClick={this.closeUploadData}
                          >
                            <ClearIcon style={{ color: "#d04646" }} />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={classes.form_body_part}>
                    {this.state.total_records > 0 && (
                      <div>
                        {/* card component */}
                        <div
                          style={{ marginLeft: "30px", marginRight: "30px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#6f9eaf"
                                cardTitle="Total Records"
                                cardBody={this.state.total_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#007965"
                                cardTitle="Successful Records"
                                cardBody={this.state.successful_records}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Card
                                backgroundcolor="#eb596e"
                                cardTitle="Error Records"
                                cardBody={this.state.error_records}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {/* datatable component */}
                        <div
                          style={{
                            height: "300",
                            margin: "20px 30px 0px 30px",
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div>
                                <MuiThemeProvider>
                                  <DataTables
                                    columns={this.state.sellerCustomerCol}
                                    data={this.state.sellerCustomerData}
                                    selectable={false}
                                    showRowHover={true}
                                    page={1}
                                    count={this.state.total_records}
                                    // showCheckboxes={true}
                                  />
                                </MuiThemeProvider>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    )}
                    {this.state.error && (
                      <div style={{ color: "red" }}>
                        <ErrorIcon />
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          ""
        )}

        {/* table  */}

        <BoxWrapper
          boxTitle="Payment Advice Details" // export to excel
          exportToExcelEnable={true}
          exportToExcelFn={() =>
            this.export_to_excel(this.state.allArcPaymentAdviceData)
          }
          // back button
          backButtonForZohoEnable={this.state.isEffitrac}
          backButton={this.backHandler}
          // import to cws button
          importToCwsEnableForZoho={this.state.isEffitrac}
          importToCwsForZoho={this.importToCWS}
          // search input
          enableSearchInput={true}
          searchInputPlaceholder="Search..."
          searchInputOnchange={
            this.state.isEffitrac
              ? this.handleSearchInputChangeForZohoAndEffitrack
              : this.handleSearchInputChange
          }
        >
          {this.state.isEffitrac ? (
            <CustomTable
              headers={this.state.effitracHeader}
              columns={this.state.effitracCol}
              data={this.state.effitracData}
              actions={this.state.actions}
            />
          ) : (
            <>
              <div className="responsive-table">
                <table className="custome-table">
                  <tbody>
                    <tr>{this.arc_paymentAdvice_TableHeader()}</tr>
                    {this.arc_PaymentAdvice__data()}
                  </tbody>
                </table>
              </div>
              <TablePagination
                rowsPerPageOptions={[25, 100, 500, 1000, 3000]}
                component="div"
                count={this.state.rowCount} //total row count
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </BoxWrapper>

        {/* show data */}
        <RightDrawer
          drawerName="Payment Advice Details"
          open={this.state.showDrawer}
          onClose={this.showToggleDrawerClose}
        >
          {Object.keys(this.state.showPaymentDetailsData).length && (
            <>
             <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="VENDOR PAN"
                value={this.state.showPaymentDetailsData.showvendorPan}
              />
               <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Voucher ID"
                value={this.state.showPaymentDetailsData.showvoucherId}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Transaction Reference Number"
                value={this.state.showPaymentDetailsData.showTransactionRefNo}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Transaction Type"
                value={this.state.showPaymentDetailsData.showTransactionType}
              />
            

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Payment Date"
                value={this.state.showPaymentDetailsData.showPaymentDate}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Amount Paid"
                value={this.indianRupeesFormat(
                  this.state.showPaymentDetailsData.showAmountPaid
                )}
              />
              {/* <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="TDS Amount"
                value={this.indianRupeesFormat(
                  this.state.showPaymentDetailsData.showTdsAmount
                )}
              /> */}
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Bill Date"
                value={this.state.showPaymentDetailsData.showInvoiceDate}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Method of Adjustment"
                value={this.state.showPaymentDetailsData.showadjustmentMethod}
              />

              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Modify Date"
                value={this.state.showPaymentDetailsData.modified_date}
              />
              <TextField
                className={classes.showTextField}
                inputProps={{ readOnly: true }}
                fullWidth
                label="Create Date"
                value={this.state.showPaymentDetailsData.created_date}
              />
            </>
          )}
          <button
            className="button-outlined-secondary mt-20"
            onClick={this.showToggleDrawerClose}
          >
            close
          </button>
        </RightDrawer>
        {/* table end */}

        {/* add payment */}
        <Drawer
          anchor="right"
          open={this.state.add_DrawerOpen}
          variant={
            this.state.paymentAdviceModalBackDrop === true ? "persistent" : ""
          }
        >
          <div className={classes.DrawerBody}>
            {/* Drawer body */}
            <ValidatorForm
              ref="form"
              onSubmit={this.addPaymentAdvice_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              {/* modal open button */}
              <Button
                style={{ marginTop: "45px" }}
                size="small"
                fullWidth
                variant="outlined"
                onClick={this.addPaymentAdviceModalOpen}
              >
                select bill*
              </Button>
              <InputField
                inputType="text"
                textLabel="GSTIN"
                name="vendorGstin"
                value={this.state.addPaymentAdviceData.vendorGstin ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />
              <InputField
                inputType="text"
                textLabel="Vendor Code+"
                name="erpVendorCode"
                value={this.state.addPaymentAdviceData.erpVendorCode ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />
              <InputField
                inputType="text"
                textLabel="Invoice Number"
                name="invoiceNumber"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.invoiceNumber ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.invicedateForAddDisplay ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              <div style={{ marginTop: "20px" }}>
                <label>
                  ₹ Amount Paid<span style={{ color: "red" }}>*</span>:
                </label>{" "}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.addPaymentAdviceData.amountPaid
                      ? this.state.addPaymentAdviceData.amountPaid
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addPaymentAdviceData: {
                        ...prevState.addPaymentAdviceData,
                        amountPaid: parseFloat(value),
                      },
                    }));
                  }}
                />{" "}
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>₹ TDS Amount:</label> <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  value={
                    this.state.addPaymentAdviceData.tdsAmount
                      ? this.state.addPaymentAdviceData.tdsAmount
                      : ""
                  }
                  thousandsGroupStyle="lakh"
                  prefix="₹"
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      addPaymentAdviceData: {
                        ...prevState.addPaymentAdviceData,
                        tdsAmount: parseFloat(value),
                      },
                    }));
                  }}
                />{" "}
              </div>

              <div style={{ marginTop: "20px" }}>
                <label>
                  Payment Date<span style={{ color: "red" }}>*</span>:
                </label>
                <ValidatedDatePicker
                  name="paymentDate"
                  value={this.state.addPaymentAdviceData.paymentDate ?? ""}
                  onChange={(e) => this.handlePaymentDateChange(e)}
                  validators={["required"]}
                  errorMessages={["Date is required"]}
                />
              </div>
              <InputField
                inputType="text"
                textLabel="Transaction Reference Number"
                name="transactionRefNumber"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={
                  this.state.addPaymentAdviceData.transactionRefNumber ?? ""
                }
                // inputProps={{
                //   readOnly: true,
                // }}
                textOnchangeFunction={this.addPaymentAdvice_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Bank Account Number"
                name="bankAccountNumber"
                // requiredField="*"
                // validator={["required"]}
                // errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.bankAccountNumber ?? ""}
                textOnchangeFunction={this.addPaymentAdvice_inputHandler}
              />
              <SelectField
                SelectLabel="Transaction Type"
                choice_id="id"
                choice_name="typeOfTransactionss"
                name="typeOfTransactions"
                // requiredField="*"
                // validator={["required"]}
                // errorMsg={["This field is required"]}
                value={this.state.addPaymentAdviceData.typeOfTransactions ?? ""}
                choices={[
                  { id: "Bank", typeOfTransactionss: "Bank" },
                  { id: "Cash", typeOfTransactionss: "Cash" },
                ]}
                textOnchangeFunction={this.addPaymentAdvice_inputHandler}
              />

              {/* modal open button */}
              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.addPaymentAdvice_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* add payment end */}

        {/*.................................Edit Payment ...........................*/}

        <Drawer
          anchor="right"
          open={this.state.edit_DrawerOpen}
          onClose={this.editPaymentAdvice_DrawerClose}
        >
          <div className={classes.DrawerBody}>
            <ValidatorForm
              ref="form"
              onSubmit={this.editPaymentAdvice_submitHandler}
              onError={(errors) => console.log(errors)}
            >
              <div style={{ marginTop: "20px" }}>
                <label>
                  ₹ Amount Paid<span style={{ color: "red" }}>*</span>:
                </label>{" "}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  value={
                    this.state.editPaymentAdviceData.amountPaid
                      ? this.state.editPaymentAdviceData.amountPaid
                      : ""
                  }
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editPaymentAdviceData: {
                        ...prevState.editPaymentAdviceData,
                        amountPaid: parseFloat(value),
                      },
                    }));
                  }}
                />{" "}
              </div>
              <InputField
                inputType="text"
                textLabel="Transaction Reference Number"
                name="transactionRefNumber"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={
                  this.state.editPaymentAdviceData.transactionRefNumber ?? ""
                }
                textOnchangeFunction={this.editPaymentAdvice_inputHandler}
              />
              <div style={{ marginTop: "20px" }}>
                <label>
                  ₹ TDS Amount<span style={{ color: "red" }}>*</span>:
                </label>{" "}
                <br />
                <NumberFormat
                  className={classes.numberFormate}
                  style={{ paddingLeft: "10.5px" }}
                  thousandsGroupStyle="lakh"
                  value={
                    this.state.editPaymentAdviceData.tdsAmount
                      ? this.state.editPaymentAdviceData.tdsAmount
                      : ""
                  }
                  prefix="₹"
                  required
                  decimalSeparator="."
                  displayType="number"
                  type="text"
                  decimalScale={2}
                  thousandSeparator={true}
                  allowNegative={true}
                  onValueChange={(values) => {
                    const { value } = values;
                    this.setState((prevState) => ({
                      editPaymentAdviceData: {
                        ...prevState.editPaymentAdviceData,
                        tdsAmount: parseFloat(value),
                      },
                    }));
                  }}
                />{" "}
              </div>

              <InputField
                inputType="text"
                textLabel="Bank Account Number"
                name="bankAccountNumber"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.editPaymentAdviceData.bankAccountNumber ?? ""}
                textOnchangeFunction={this.editPaymentAdvice_inputHandler}
              />
              <SelectField
                SelectLabel="Transaction Type"
                choice_id="id"
                choice_name="typeOfTransactions"
                name="typeOfTransactions"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={
                  this.state.editPaymentAdviceData.typeOfTransactions ?? ""
                }
                choices={[
                  { id: "Bank", typeOfTransactions: "Bank" },
                  { id: "Cash", typeOfTransactions: "Cash" },
                ]}
                textOnchangeFunction={this.editPaymentAdvice_inputHandler}
              />
              <InputField
                inputType="text"
                textLabel="Invoice Date"
                name="invoiceDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.invoiceDateDisplay ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />
              <InputField
                inputType="text"
                textLabel="payment Date"
                name="paymentDate"
                requiredField="*"
                validator={["required"]}
                errorMsg={["This field is required"]}
                value={this.state.paymentDateDisplay ?? ""}
                inputProps={{
                  readOnly: true,
                }}
              />

              {/* submit and back button */}
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                size="small"
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  float: "right",
                  marginTop: "20px",
                }}
                variant="contained"
                size="small"
                onClick={this.editPaymentAdvice_DrawerClose}
              >
                cancel
              </Button>
            </ValidatorForm>
            {/* Drawer body end */}
          </div>
        </Drawer>

        {/* edit payment end */}
      </React.Fragment>
    );
  }
}

export default VendorpaymentAdvice;
